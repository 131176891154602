import { Alert, Button, Modal, Tabs } from "flowbite-react";
import axios from "../config/axiosInstance";
import { useEffect, useRef, useState } from "react";
import ReactSignatureCanvas from "react-signature-canvas";

const MyDocumentsPage = () => {
  const sigCanvas = useRef(null);
  const [openModal,setOpenModal] = useState(true);

  const clear = () => sigCanvas.current.clear();

  // Salvar a assinatura como imagem
  const save = () => {
    const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    console.log("Assinatura salva:", dataURL);
    alert("Assinatura salva! Verifique o console para o URL da imagem.");
  };

  return (
    <div>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <h2>Assine aqui:</h2>
        <ReactSignatureCanvas
          ref={sigCanvas}
          penColor="black"
          canvasProps={{
            width: 500,
            height: 150,
            className: "sigCanvas bg-white",
            style: { borderBottom: "1px solid #000" },
          }}
        />
        <div style={{ marginTop: "10px" }}>
          <button onClick={clear} style={{ marginRight: "10px" }}>
            Limpar
          </button>
          <button onClick={save}>Salvar</button>
        </div>
      </div>

      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>Terms of Service</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              With less than a month to go before the European Union enacts new
              consumer privacy laws for its citizens, companies around the world
              are updating their terms of service agreements to comply.
            </p>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              The European Union’s General Data Protection Regulation (G.D.P.R.)
              goes into effect on May 25 and is meant to ensure a common set of
              data rights in the European Union. It requires organizations to
              notify users as soon as possible of high-risk data breaches that
              could personally affect them.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setOpenModal(false)}>I accept</Button>
          <Button color="gray" onClick={() => setOpenModal(false)}>
            Decline
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MyDocumentsPage;
