import { Alert, Button, Card, Table } from "flowbite-react";
import React, { useRef, useState } from "react";
import { IoAlertCircleOutline } from "react-icons/io5";
import { formatToReal, isValidCNPJ } from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import moment from "moment";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import TitleStripe from "../common/TitleStripe";
import CardQueryDescription from "../common/CardQueryDescription";
import QueryInput from "../inputs/QueryInput";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FiAlertOctagon } from "react-icons/fi";
import QueryLoading from "../common/QueryLoading";
import FooterQuery from "./FooterQuery";

const RestritivaPJQuery = ({ query, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    data
  );

  const fetchInfo = async (raw) => {
    return await axios(
      `${QUERIES.GET_RESTRITIVA_PJ}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCNPJ(unmaskedValue)) {
      setError("CNPJ inválido!");
      isValid = false;
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(
          dados.data["ReportPJResponseEx"].Response.Records.ReportPJOutput[0]
        );
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setData(null);
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Negativações",
          "Ações judiciais",
          "Protestos",
          "Cheques sem fundos",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 mt-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CNPJ"
            />
            {!errorQuery && data && (
              <Button
                className={`w-fit shadow-lg secondary ${
                  data && !isLoading ? "block" : "hidden"
                }`}
                isProcessing={pdfURL === ""}
                disabled={pdfURL === ""}
                onClick={() => handleClickDownloadPDF()}
              >
                Salvar como PDF
              </Button>
            )}
          </div>
          {error && <small className="text-red-600">{error}</small>}
        </form>
      </CardQueryDescription>
      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <div
          className={`overflow-x-scroll ${
            data || isLoading ? "block" : "hidden"
          }`}
          ref={componentRef}
        >
          {!isLoading ? (
            <Card className="relative overflow-hidden card-pdf p-5">
              <HeaderQuery title={query?.name} />

              <TitleStripe>Quadro de avisos</TitleStripe>
              <div className="container-alerts four-columns">
                {!data?.Negative?.Apontamentos?.Apontamento?.length > 0 ? (
                  <Alert
                    color="success"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <IoMdCheckmarkCircleOutline
                      fontSize={30}
                      className="mx-auto mb-1"
                    />
                    <div className="text-center">Negativações</div>
                    <div className="text-center font-semibold">Nada consta</div>
                  </Alert>
                ) : (
                  <Alert
                    color="failure"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <FiAlertOctagon fontSize={30} className="mx-auto mb-1" />
                    <div className="text-center">Negativações</div>
                    <div className="text-center font-semibold">
                      {data?.Negative?.Apontamentos?.Apontamento?.length}{" "}
                      ocorrências
                    </div>
                  </Alert>
                )}

                {!data?.Negative?.CcfApontamentos?.CcfApontamento?.length >
                0 ? (
                  <Alert
                    color="success"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <IoMdCheckmarkCircleOutline
                      fontSize={30}
                      className="mx-auto mb-1"
                    />
                    <div className="text-center">Cheques sem fundo</div>
                    <div className="text-center font-semibold">Nada consta</div>
                  </Alert>
                ) : (
                  <Alert
                    color="failure"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <FiAlertOctagon fontSize={30} className="mx-auto mb-1" />
                    <div className="text-center">Cheques sem fundo</div>
                    <div className="text-center font-semibold">
                      {data?.Negative?.CcfApontamentos?.CcfApontamento?.length}{" "}
                      ocorrências
                    </div>
                  </Alert>
                )}

                {!data?.Negative?.LawSuitApontamentos?.LawSuitApontamento
                  ?.length > 0 ? (
                  <Alert
                    color="success"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <IoMdCheckmarkCircleOutline
                      fontSize={30}
                      className="mx-auto mb-1"
                    />
                    <div className="text-center">Ações judiciais</div>
                    <div className="text-center font-semibold">Nada consta</div>
                  </Alert>
                ) : (
                  <Alert
                    color="failure"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <FiAlertOctagon fontSize={30} className="mx-auto mb-1" />
                    <div className="text-center">Ações judiciais</div>
                    <div className="text-center font-semibold">
                      {
                        data?.Negative?.LawSuitApontamentos?.LawSuitApontamento
                          ?.length
                      }{" "}
                      ocorrências
                    </div>
                  </Alert>
                )}

                {!data?.Protests?.Protest.length > 0 ? (
                  <Alert
                    color="success"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <IoMdCheckmarkCircleOutline
                      fontSize={30}
                      className="mx-auto mb-1"
                    />
                    <div className="text-center">Protestos</div>
                    <div className="text-center font-semibold">Nada consta</div>
                  </Alert>
                ) : (
                  <Alert
                    color="failure"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <FiAlertOctagon fontSize={30} className="mx-auto mb-1" />
                    <div className="text-center">Protestos</div>
                    <div className="text-center font-semibold">
                      {!data?.Protests?.Protest[0]?.erro_descricao ? (
                        <> {data?.Protests?.Protest.length} ocorrências</>
                      ) : (
                        "Erro na consulta"
                      )}
                    </div>
                  </Alert>
                )}
              </div>
              <div>
                <TitleStripe className="mb-5">Negativações</TitleStripe>
                {data?.Negative?.Apontamentos?.Apontamento?.length > 0 ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="text-center bg-red-300">
                            Data
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Empresa
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Contrato
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Cidade:
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Valor:
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {data?.Negative?.Apontamentos?.Apontamento?.map(
                            (apontamento, index) => (
                              <Table.Row
                                key={index}
                                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              >
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {apontamento.DateOccurred.Day}/
                                  {apontamento.DateOccurred.Month}/
                                  {apontamento.DateOccurred.Year}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {apontamento.CompanyName}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {apontamento.ContractNumber}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {apontamento.Address?.City}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {apontamento.Amount
                                    ? formatToReal(apontamento.Amount)
                                    : "-"}
                                </Table.Cell>
                              </Table.Row>
                            )
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Card className={`bg-green-200`}>
                    <div className="flex gap-2 items-center text-gray-600 font-semibold">
                      <div>
                        <IoAlertCircleOutline fontSize={20} />
                      </div>
                      <div>Nada consta</div>
                    </div>
                  </Card>
                )}
              </div>
              <div>
                <TitleStripe className="mb-5">Cheques sem fundos</TitleStripe>
                {data?.Negative?.CcfApontamentos?.CcfApontamento?.length > 0 ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="text-center bg-red-300">
                            Data
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Banco
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Documento
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {data?.Negative?.CcfApontamentos?.CcfApontamento?.map(
                            (ccf, index) => (
                              <Table.Row
                                key={index}
                                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              >
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {ccf.DateLastBounce?.Day}/
                                  {ccf.DateLastBounce?.Month}/
                                  {ccf.DateLastBounce?.Year}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {ccf.ReportingNameBank}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {ccf.CpfCnpj}
                                </Table.Cell>
                              </Table.Row>
                            )
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Card className={`bg-green-200`}>
                    <div className="flex gap-2 items-center text-gray-600 font-semibold">
                      <div>
                        <IoAlertCircleOutline fontSize={20} />
                      </div>
                      <div>Nada consta</div>
                    </div>
                  </Card>
                )}
              </div>

              <div>
                <TitleStripe className="mb-5">Ações judiciais</TitleStripe>
                {data?.Negative?.LawSuitApontamentos?.LawSuitApontamento
                  ?.length > 0 ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="text-center bg-red-300">
                            Data
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Fórum
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Tipo
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Cidade:
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Valor:
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {data?.Negative?.LawSuitApontamentos?.LawSuitApontamento?.map(
                            (acao, index) => (
                              <Table.Row
                                key={index}
                                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              >
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {acao.DateProcessed?.Day}/
                                  {acao.DateProcessed?.Month}/
                                  {acao.DateProcessed?.Year}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {acao.Forum || "-"}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {acao.ProcessType}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {acao.City}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {acao.AmountLawsuit
                                    ? formatToReal(acao.AmountLawsuit)
                                    : "-"}
                                </Table.Cell>
                              </Table.Row>
                            )
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Card className={`bg-green-200`}>
                    <div className="flex gap-2 items-center text-gray-600 font-semibold">
                      <div>
                        <IoAlertCircleOutline fontSize={20} />
                      </div>
                      <div>Nada consta</div>
                    </div>
                  </Card>
                )}
              </div>

              <div>
                {!data?.Protests?.Protest[0]?.erro_descricao && (
                  <>
                    {" "}
                    <TitleStripe className="mb-5">
                      Histórico de protestos
                    </TitleStripe>
                    {data?.Protests?.Protest?.length > 0 ? (
                      <>
                        <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                          <Table striped>
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-red-300">
                                Data
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Situação
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-red-300">
                                Valor
                              </Table.HeadCell>
                            </Table.Head>

                            <Table.Body className="divide-y">
                              {data?.Protests?.Protest?.map(
                                (protest, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                      {moment(protest.data_consulta).format(
                                        "DD/MM/yyyy"
                                      )}
                                    </Table.Cell>
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                      {protest?.situacao || "-"}
                                    </Table.Cell>
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                      R${" "}
                                      {protest?.valor_protestados_total || "-"}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </Table.Body>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <Card className={`bg-green-200`}>
                        <div className="flex gap-2 items-center text-gray-600 font-semibold">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Nada consta</div>
                        </div>
                      </Card>
                    )}
                  </>
                )}
              </div>

              <div>
                <TitleStripe className="mb-5">
                  Histórico de consultas
                </TitleStripe>
                {data?.Inquiries?.InquiryDetails?.InquiryDetail?.length > 0 ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-gray-300">
                            Segmento
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Quantidade
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Data da consulta
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {data?.Inquiries?.InquiryDetails?.InquiryDetail?.map(
                            (inquiry, index) => (
                              <Table.Row
                                key={index}
                                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              >
                                <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                  {inquiry.Segment}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {inquiry.InquiryCount}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {inquiry.DateInquiry?.Day}/
                                  {inquiry.DateInquiry?.Month}/
                                  {inquiry.DateInquiry?.Year}
                                </Table.Cell>
                              </Table.Row>
                            )
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Card className={`bg-green-200 mt-4`}>
                    <div className="flex gap-2 items-center text-gray-600 font-semibold">
                      <div>
                        <IoAlertCircleOutline fontSize={20} />
                      </div>
                      <div>Nada consta</div>
                    </div>
                  </Card>
                )}
              </div>
              <FooterQuery hasHeader={true} />
            </Card>
          ) : (
            <QueryLoading />
          )}
        </div>
      )}
    </>
  );
};

export default RestritivaPJQuery;
