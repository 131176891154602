import { Button, Label, TextInput } from "flowbite-react";
import { maskCNPJ, maskCPF, maskLicensePlate } from "../../utils/masks";
import { CgSpinner } from "react-icons/cg";
import { FaCheck, FaSearch } from "react-icons/fa";
import { isValidCNPJ, isValidCPF } from "../../utils/utils";
import { IoCloseCircleOutline } from "react-icons/io5";

const QueryInput = ({ info, setInfo, isLoading, error, type = "CPF" }) => {
  const handleChange = (event) => {
    let valor = event.target.value.replace(/\D/g, "");

    if (type === "CPF") {
      valor = maskCPF(valor);
    } else if (type === "CNPJ") {
      valor = maskCNPJ(valor);
    } else {
      if (valor.length <= 11) {
        valor = maskCPF(valor);
      } else {
        valor = maskCNPJ(valor);
      }
    }

    setInfo(valor);
  };

  const checkValidType = () => {
    const isCPFValid =
      type !== "CNPJ" && info.length === 14 && isValidCPF(info);
    const isCNPJValid =
      type !== "CPF" && info.length === 18 && isValidCNPJ(info);

    if (isCPFValid || isCNPJValid) {
      return true;
    }
    return false;
  };

  const checkErrorIcon = () => {
    if (type === "CPF") {
      return info.length === 14;
    } else if (type === "CNPJ") {
      return info.length === 18;
    } else {
      return info.length === 18 || info.length === 14;
    }
  };

  return (
    <div className="flex items-center gap-2 p-1">
      <div className="relative">
        <div className={`relative`}>
          <input
            id="info"
            type="text"
            className={`block px-2.5 pb-2.5 pt-4 w-full text-xs text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 peer min-w-52`}
            placeholder={""}
            value={info}
            maxLength={type === "CPF" ? "14" : "18"}
            onChange={handleChange}
            required
          />
          <label
            htmlFor="info"
            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-1 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            {type}
          </label>
        </div>

        {checkValidType() ? (
          <FaCheck className="absolute right-2 top-4 text-green-500" />
        ) : (
          checkErrorIcon() && <IoCloseCircleOutline className="absolute right-2 top-4 text-red-500" />
        )}
      </div>
      <Button
        className=" shadow-lg primary flex items-center justify-center"
        type="submit"
        isProcessing={isLoading}
        processingSpinner={<CgSpinner className="h-5 w-5 animate-spin" />}
        style={{ height: "43px" }}
        disabled={!checkValidType()}
      >
        <FaSearch />
      </Button>
      {error && <small className="text-red-600">{type} inválido!</small>}
    </div>
  );
};

export default QueryInput;
