import { Alert, Badge, Button, Card, Label, Spinner } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { isValidCPF } from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import { BOA_VISTA_CONTANTS } from "../../utils/boa-vista-variables";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import TitleStripe from "../common/TitleStripe";
import CardQueryDescription from "../common/CardQueryDescription";
import QueryInput from "../inputs/QueryInput";
import SliderToPreview from "../common/SliderToPreview";
import QueryLoading from "../common/QueryLoading";
import AvisoCard from "../common/query/AvisoCard";
import NoOcurrence from "../common/query/NoOcurrence";
import ProtestosDirCartorio from "../common/query/ProtestosDirCartorio";
import Protestos from "../common/query/Protestos";
import HistoricoConsultas from "../common/query/HistoricoConsultas";
import PendenciasFinanceiras from "../common/query/PendenciasFinanceiras";
import ChequesSustados from "../common/query/ChequesSustados";
import ChequesSemFundos from "../common/query/ChequesSemFundos";
import { EvaluationScore } from "../common/query/EvaluationScore";
import FooterQuery from "./FooterQuery";
import ReactApexChart from "react-apexcharts";

const PositivoPlusCPFQuery = ({
  query,
  selectedPrice,
  dataFromLocalStorage = null,
  hasHeaderPDF = "true",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [infoProtestos, setInfoProtestos] = useState(null);
  const [isDoneRequest, setIsDoneRequest] = useState(false);
  const [isLoadingProtestos, setIsLoadingProtestos] = useState(false);
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    isDoneRequest
  );

  useEffect(() => {
    if (dataFromLocalStorage) {
      setData(dataFromLocalStorage.consultaCredito);
      setDocument(dataFromLocalStorage?.consultaCredito?.dadosCadastrais?.cpf);
      setInfoProtestos(dataFromLocalStorage?.protestosDirCartorio);
      setIsDoneRequest(true);
    }
  }, []);

  const fetchInfo = async (raw) => {
    return await axios(
      `${QUERIES.GET_ACERTA_ESSENCIAL}/${info.replace(/\D/g, "")}`
    );
  };

  const fetchInfoProtestos = async (raw) => {
    return await axios(
      `${QUERIES.GET_PROTESTO_NACIONAL}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCPF(unmaskedValue)) {
      setError("CPF inválido!");
      isValid = false;
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setInfoProtestos(null);
        setIsLoading(true);
        setIsLoadingProtestos(true);
        setError("");
        setErrorQuery("");
        setIsDoneRequest(false);

        // Fetch principal
        const { data: dados } = await fetchInfo();
        setData(dados.data.consultaCredito);
        setIsLoading(false);

        // Fetch secundário com tratamento independente
        try {
          const protestos = await fetchInfoProtestos();
          setInfoProtestos(protestos.data.data);
        } catch (protestosError) {
          console.error("Erro ao buscar protestos:", protestosError);
          setInfoProtestos([]); // Defina um valor padrão caso ocorra erro
        } finally {
          setIsLoadingProtestos(false);
        }
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
        setIsLoading(false);
      } finally {
        setIsDoneRequest(true);
      }
    } else {
      setData(null);
    }
  };

  return (
    <>
      {!dataFromLocalStorage && (
        <>
          <div className="text-lg font-semibold mb-3 title-syncx">
            {query?.name}
          </div>
          <CardQueryDescription
            title="Informações da consulta"
            infos={[
              "Dados Cadastrais",
              "Score",
              "Negativações",
              "Protestos",
              "Cheques sem fundos",
              "Cheques sustados",
            ]}
            price={selectedPrice}
          >
            <form
              className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-col md:flex-row gap-2 items-center">
                <QueryInput
                  info={info}
                  setInfo={setInfo}
                  isLoading={isLoading}
                  error={error}
                  type="CPF"
                />
                <div>
                  {!errorQuery && data && (
                    <Button
                      className={`w-fit shadow-lg secondary ${
                        data && !isLoading ? "block" : "hidden"
                      }`}
                      isProcessing={pdfURL === ""}
                      disabled={pdfURL === ""}
                      onClick={() => handleClickDownloadPDF()}
                    >
                      Salvar como PDF
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </CardQueryDescription>

          <SliderToPreview />
        </>
      )}

      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`overflow-x-scroll ${
              data || isLoading ? "block" : "hidden"
            }`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="p-5 card-pdf relative overflow-hidden">
                  <HeaderQuery
                    title={query?.name}
                    hasHeader={hasHeaderPDF}
                    date={data?.resumoRetorno?.dataConsulta}
                  />

                  <TitleStripe>Dados Cadastrais</TitleStripe>
                  <div className="flex gap-16">
                    <div className="text-base flex-1">
                      <div className="mb-3">
                        <span className="font-semibold">Nome completo: </span>
                        {data?.dadosCadastrais?.nome}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Título de eleitor:{" "}
                        </span>
                        {data?.dadosCadastrais?.tituloEleitor || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Nome da mãe: </span>
                        {data?.dadosCadastrais?.nomeMae || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Grau de instrução:{" "}
                        </span>
                        {data?.dadosCadastrais?.grauInstrucao || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Data de nascimento:{" "}
                        </span>
                        {data?.dadosCadastrais?.dataNascimento ||
                          "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">RG: </span>
                        {data?.dadosCadastrais?.numeroRG || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Número de dependentes:{" "}
                        </span>
                        {data?.dadosCadastrais?.numeroDependentes ||
                          "Indisponível"}
                      </div>
                    </div>
                    <div className="text-base flex-1">
                      <div className="mb-3">
                        <span className="font-semibold">CPF: </span>
                        {document}
                      </div>
                      <div className="mb-3 flex gap-2">
                        <span className="font-semibold">Situação: </span>
                        <div
                          className={`${
                            data?.dadosCadastrais?.situacao?.toUpperCase() ===
                            "REGULAR"
                              ? "bg-primary"
                              : "bg-red-500"
                          } px-2 rounded-md text-white font-semibold`}
                        >
                          {data?.dadosCadastrais?.situacao || "Indisponível"}
                        </div>
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Origem CPF: </span>
                        {data?.dadosCadastrais?.regiaoCPF || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Data de atualização:{" "}
                        </span>
                        {data?.dadosCadastrais?.dataAtualizacao ||
                          "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Sexo: </span>
                        {data?.dadosCadastrais?.sexo || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Estado Civil: </span>
                        {data?.dadosCadastrais?.estadoCivil || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Nacionalidade: </span>
                        {data?.dadosCadastrais?.cidadeNascimento ||
                          "Indisponível"}
                      </div>
                    </div>
                  </div>

                  <TitleStripe>Quadro de avisos</TitleStripe>
                  <div className="container-alerts five-columns">
                    <AvisoCard
                      title="Negativações"
                      hasRestrictions={
                        +data?.resumoConsulta?.pendenciasFinanceiras
                          ?.quantidadeTotal > 0 ||
                        data?.resumoConsulta?.pendenciasFinanceiras
                          ?.valorTotal != "0"
                      }
                      messageError={`${data?.resumoConsulta?.pendenciasFinanceiras?.quantidadeTotal} ocorrências`}
                    />

                    <AvisoCard
                      title="Cheques Sustados"
                      hasRestrictions={
                        +data?.resumoConsulta?.chequesSustados
                          ?.quantidadeTotal > 0
                      }
                      messageError={`${data?.resumoConsulta?.chequesSustados?.quantidadeTotal}
                      ocorrências`}
                    />

                    <AvisoCard
                      title="Cheques sem Fundos"
                      hasRestrictions={
                        +data?.resumoConsulta?.chequesSemFundo
                          ?.quantidadeTotal > 0
                      }
                      messageError={`${data?.resumoConsulta?.chequesSemFundo?.quantidadeTotal}
                      ocorrências`}
                    />

                    <AvisoCard
                      title="Protestos"
                      hasRestrictions={
                        +data?.resumoConsulta?.protestos?.quantidadeTotal > 0
                      }
                      messageError={`${data?.resumoConsulta?.protestos?.quantidadeTotal} ocorrências`}
                    />

                    {isLoadingProtestos ? (
                      <div className="flex relative flex-col bg-gray-500 rounded-md font-semibold pt-1 pb-6 px-2 text-white shadow-md">
                        <div className="text-left text-sm">
                          Protestos dir. cartório
                        </div>
                        <div className="text-center font-semibold w-fit text-green-800 px-2 rounded-md mx-auto mt-4">
                          <Spinner
                            aria-label="Large spinner example"
                            size="md"
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <AvisoCard
                          title="Protestos dir. Cartório"
                          hasRestrictions={infoProtestos?.length > 0}
                          messageError={`${infoProtestos?.length}
                          ocorrências`}
                        />
                      </>
                    )}
                  </div>

                  <div>
                    <TitleStripe>Avaliação preliminar de crédito</TitleStripe>
                  </div>
                  <EvaluationScore
                    data={data}
                    score={data?.score?.score}
                    probability={data?.score?.probabilidade}
                  />

                  <div>
                    <TitleStripe className="mb-5">Negativações</TitleStripe>

                    {data?.pendenciasFinanceiras?.length > 0 ? (
                      <>
                        <PendenciasFinanceiras
                          quantidadeTotal={
                            data?.resumoConsulta?.pendenciasFinanceiras
                              ?.quantidadeTotal
                          }
                          valorTotal={data?.resumoConsulta?.pendenciasFinanceiras?.valorTotal
                            ?.replace(".", "")
                            ?.replace(",", ".")}
                          pendenciasFinanceiras={data?.pendenciasFinanceiras}
                        />
                      </>
                    ) : (
                      <NoOcurrence />
                    )}

                    <div>
                      <TitleStripe className="mb-5">
                        Cheques sustados
                      </TitleStripe>

                      {data?.chequesSustados?.length > 0 ? (
                        <>
                          <ChequesSustados
                            chequesSustados={data?.chequesSustados}
                          />
                        </>
                      ) : (
                        <NoOcurrence />
                      )}
                    </div>
                    <div>
                      <TitleStripe className="mb-5">
                        Cheques sem fundos
                      </TitleStripe>

                      {data?.chequesSemFundo?.length > 0 ? (
                        <>
                          <ChequesSemFundos
                            chequesSemFundos={data?.chequesSemFundo}
                          />
                        </>
                      ) : (
                        <NoOcurrence />
                      )}
                    </div>

                    <div>
                      <TitleStripe className="mb-5">
                        Títulos protestados
                      </TitleStripe>
                      {data?.protestos?.length > 0 ? (
                        <Protestos
                          quantidadeTotal={
                            data?.resumoConsulta?.protestos?.quantidadeTotal
                          }
                          valorTotal={
                            data?.resumoConsulta?.protestos?.valorTotal
                          }
                          protestos={data?.protestos}
                        />
                      ) : (
                        <NoOcurrence />
                      )}
                    </div>
                  </div>

                  <div>
                    <TitleStripe className="mb-5">
                      Protestos direto do cartório
                    </TitleStripe>
                    {!isLoadingProtestos ? (
                      <>
                        {infoProtestos?.length > 0 ? (
                          <ProtestosDirCartorio
                            quantidadeTotal={infoProtestos?.length}
                            protestosDirCartorio={infoProtestos}
                          />
                        ) : (
                          <NoOcurrence />
                        )}
                      </>
                    ) : (
                      <div role="status" className="w-full animate-pulse">
                        <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>{" "}
                      </div>
                    )}
                  </div>

                  <div>
                    <TitleStripe className="mb-5">
                      Histórico de consultas
                    </TitleStripe>

                    {data?.historicoConsultas?.length > 0 ? (
                      <div className="flex gap-2 items-start">
                        <div className="flex-1">
                          <HistoricoConsultas
                            historicoConsultas={data?.historicoConsultas?.slice(
                              0,
                              5
                            )}
                          />
                        </div>
                        {data?.historicoConsultasResumo
                          ?.paresMesAnoHistoricoConsultas?.length > 0 && (
                          <ReactApexChart
                            className="border border-gray-200 rounded-md w-2/5 mt-3"
                            options={{
                              chart: {
                                height: 150,
                                type: "bar",
                                toolbar: {
                                  show: false, // Remove o menu de ações
                                },
                              },
                              plotOptions: {
                                bar: {
                                  borderRadius: 2,
                                  dataLabels: {
                                    position: "center", // top, center, bottom
                                  },
                                },
                              },
                              dataLabels: {
                                enabled: true,
                                formatter: function (val) {
                                  return val;
                                },
                                offsetY: 0,
                                style: {
                                  fontSize: "12px",
                                  colors: ["white"],
                                },
                              },

                              xaxis: {
                                categories:
                                  data?.historicoConsultasResumo
                                    ?.paresMesAnoHistoricoConsultas,
                                position: "top",
                                axisBorder: {
                                  show: false,
                                },
                                axisTicks: {
                                  show: false,
                                },
                                crosshairs: {
                                  fill: {
                                    type: "gradient",
                                    gradient: {
                                      colorFrom: "#309ADB",
                                      colorTo: "#309ADB",
                                      stops: [0, 100],
                                      opacityFrom: 0.4,
                                      opacityTo: 0.5,
                                    },
                                  },
                                },
                                tooltip: {
                                  enabled: true,
                                },
                              },
                              yaxis: {
                                axisBorder: {
                                  show: false,
                                },
                                axisTicks: {
                                  show: false,
                                },
                                labels: {
                                  show: false,
                                  formatter: function (val) {
                                    return val;
                                  },
                                },
                              },
                              title: {
                                text: "Últimos 90 dias",
                                floating: true,
                                offsetY: 155,
                                align: "center",
                                style: {
                                  color: "#444",
                                },
                              },
                            }}
                            series={[
                              {
                                name: "Qtd. Consultas no período",
                                data: data?.historicoConsultasResumo
                                  ?.totaisHistoricoConsultas,
                              },
                            ]}
                            type="bar"
                            height={175}
                          />
                        )}
                      </div>
                    ) : (
                      <NoOcurrence />
                    )}
                  </div>

                  <FooterQuery hasHeader={hasHeaderPDF} />
                </Card>
              </>
            ) : (
              <QueryLoading />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default PositivoPlusCPFQuery;
