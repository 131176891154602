import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Spinner,
  Table,
} from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import axios from "../../../config/axiosInstance";
import { QUERIES_VEICULAR } from "../../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "../HeaderQuery";
import usePDF from "../../../hooks/usePDF";
import TitleStripe from "../../common/TitleStripe";
import CardQueryDescription from "../../common/CardQueryDescription";
import { FiAlertOctagon } from "react-icons/fi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import SliderToPreview from "../../common/SliderToPreview";
import moment from "moment";
import { MdBlock } from "react-icons/md";
import Loader from "../../common/Loader";
import FooterQuery from "../FooterQuery";
import NoOcurrence from "../../common/query/NoOcurrence";
import QueryVehicleInput from "../../inputs/QueryVehicleInput";

const DadosRenainfMultasForaEstadoDetranEQuery = ({
  query,
  selectedPrice,
  dataFromLocalStorage = null,
  hasHeaderPDF = "true",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const [dataCadastral, setDataCadastral] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    data
  );

  useEffect(() => {
    if (dataFromLocalStorage) {
      setData(dataFromLocalStorage?.dadosVeicular);
      setDataCadastral(dataFromLocalStorage?.credCadastro);
      setDocument(dataFromLocalStorage?.resumoRetorno?.document);
    }
  }, []);

  const fetchInfo = async (raw) => {
    return await axios(`${QUERIES_VEICULAR.GET_VEICULAR}/${query?.id}/${info}`);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);
    let isValid = true;

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setDataCadastral(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados?.data?.dadosVeiculo || null);
        setDataCadastral(dados?.data?.credCadastro || null);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setData(null);
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={["Resumo do veículo", "Bin Estadual", "Bin Nacional"]}
        price={selectedPrice}
        imageBanner="/assets/bg_banner_car.jpg"
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <QueryVehicleInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="PLACA"
            />
            <div>
              {!errorQuery && data && (
                <Button
                  className={`w-fit shadow-lg secondary ${
                    data && !isLoading ? "block" : "hidden"
                  }`}
                  isProcessing={pdfURL === ""}
                  disabled={pdfURL === ""}
                  onClick={() => handleClickDownloadPDF()}
                >
                  Salvar como PDF
                </Button>
              )}
            </div>
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`overflow-x-scroll ${
              data || isLoading ? "block" : "hidden"
            }`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="relative overflow-hidden p-5 card-pdf">
                  <HeaderQuery title={query?.name} type="vehicle" />

                  <TitleStripe>Resumo do veículo</TitleStripe>
                  <div className="flex gap-6">
                    <img
                      className="object-contain bg-gray-200 p-2 rounded"
                      src={`data:image/png;base64, ${
                        data?.binNacional?.logoFabricante?.imagemBase64 ||
                        data?.binEstadual?.logoFabricante?.imagemBase64
                      }`}
                    />
                    <div className="flex gap-16">
                      <div className="text-sm">
                        <div className="mb-3">
                          <span className="font-semibold">Marca/Modelo: </span>
                          {data?.binNacional?.marcaModelo || "Sem info."}
                        </div>
                        <div className="mb-3">
                          <span className="font-semibold">
                            Ano Fabr./Modelo:{" "}
                          </span>
                          {`${data?.binNacional?.anoFabricacao}/${data?.binNacional?.anoModelo}` ||
                            "Indisponível"}
                        </div>
                        <div className="mb-3">
                          <span className="font-semibold">Cor: </span>
                          {data?.binNacional?.corVeiculo || "Indisponível"}
                        </div>
                        <div className="mb-3">
                          <span className="font-semibold">Combustível: </span>
                          {data?.binNacional?.combustivel || "Indisponível"}
                        </div>
                      </div>
                      <div className="text-sm">
                        <div className="mb-3">
                          <span className="font-semibold">Placa: </span>
                          {document}
                        </div>
                        <div className="mb-3">
                          <span className="font-semibold">Chassi: </span>
                          {data?.binNacional?.chassi || "Indisponível"}
                        </div>
                        <div className="mb-3">
                          <span className="font-semibold">N. Motor: </span>
                          {data?.binNacional?.numeroMotor || "Indisponível"}
                        </div>
                      </div>
                    </div>
                  </div>

                  <TitleStripe>Quadro de avisos</TitleStripe>
                  <div className="container-alerts four-columns">
                    {data?.binEstadual?.restricoes?.dpvat?.existePendencia ===
                    "0" ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Débito dpvat</div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Débito dpvat</div>
                        <div className="text-center font-semibold">
                          R$
                          {data?.binEstadual?.restricoes?.dpvat?.valorPendencia}
                        </div>
                      </Alert>
                    )}
                    {data?.binEstadual?.restricoes?.ipva?.existePendencia ===
                    "1" ? (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Débito ipva</div>
                        <div className="text-center font-semibold">
                          R${" "}
                          {data?.binEstadual?.restricoes?.ipva?.valorPendencia}
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Débito ipva</div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    )}
                    {data?.binEstadual?.restricoes?.licenciamento
                      ?.existePendencia === "0" ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Débito licenciamento</div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Débito licenciamento</div>
                        <div className="text-center font-semibold">
                          R${" "}
                          {
                            data?.binEstadual?.restricoes?.licenciamento
                              ?.valorPendencia
                          }
                        </div>
                      </Alert>
                    )}
                    {data?.binEstadual?.restricoes?.multas?.existePendencia ===
                    "0" ? (
                      <Alert
                        color="success"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <IoMdCheckmarkCircleOutline
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Débito multas</div>
                        <div className="text-center font-semibold">
                          Nada consta
                        </div>
                      </Alert>
                    ) : (
                      <Alert
                        color="failure"
                        withBorderAccent
                        className="flex justify-center items-center flex-col"
                      >
                        <FiAlertOctagon
                          fontSize={30}
                          className="mx-auto mb-1"
                        />
                        <div className="text-center">Débito multas</div>
                        <div className="text-center font-semibold">
                          R${" "}
                          {
                            data?.binEstadual?.restricoes?.multas
                              ?.valorPendencia
                          }
                        </div>
                      </Alert>
                    )}
                  </div>

                  <div>
                    <TitleStripe>Informações do veículo</TitleStripe>
                    <div className="flex gap-10 mt-4">
                      <div className="text-sm flex-1">
                        <div className="title-vehicle mb-3 flex gap-2 items-center">
                          <div>Base Nacional</div>
                          <img
                            className="w-5"
                            src="/assets/base_nacional.png"
                          />
                        </div>
                        <div className="p-2 rounded bg-gray-100">
                          <div className="mb-3">
                            <span className="font-semibold">
                              Marca/Modelo:{" "}
                            </span>
                            {data?.binNacional?.marcaModelo || "Sem info."}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Ano Fabr./Modelo:{" "}
                            </span>
                            {`${data?.binNacional?.anoFabricacao}/${data?.binNacional?.anoModelo}` ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tempo de circulação:{" "}
                            </span>
                            {`${
                              moment().format("YYYY") -
                              +data?.binNacional?.anoFabricacao
                            } anos` || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Categoria: </span>
                            {data?.binNacional?.CATEGORIA_VEICULO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo de veículo:{" "}
                            </span>
                            {data?.binNacional?.TIPO_VEICULO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Placa: </span>
                            {document || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">RENAVAM: </span>
                            {data?.binNacional?.RENAVAM || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">chassi: </span>
                            {data?.binNacional?.chassi || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Situação: </span>
                            {data?.binNacional?.SITUACAO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Câmbio: </span>
                            {data?.binNacional?.CAIXA_CAMBIO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Capacidade Carga:{" "}
                            </span>
                            {data?.binNacional?.CAPACIDADE_CARGA ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Cilindrada: </span>
                            {data?.binNacional?.CILINDRADA || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">CMT: </span>
                            {data?.binNacional?.CMT || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Combustível: </span>
                            {data?.binNacional?.combustivel || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Cor: </span>
                            {data?.binNacional?.corVeiculo || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Emplacamento:{" "}
                            </span>
                            {data?.binNacional?.dataEmplacamento ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Espécie: </span>
                            {data?.binNacional?.especieVeiculo ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Município: </span>
                            {data?.binNacional?.municipio || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">uf: </span>
                            {data?.binNacional?.uf || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Nº Carroceria:{" "}
                            </span>
                            {data?.binNacional?.numeroCarroceria ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Nº DI: </span>
                            {data?.binNacional?.numeroDI || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data registro DI:{" "}
                            </span>
                            {data?.binNacional?.dataRegistroDI ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Nº Eixo Auxiliar:{" "}
                            </span>
                            {data?.binNacional?.numeroEixoAuxiliar ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Nº Eixo Traseiro:{" "}
                            </span>
                            {data?.binNacional?.numeroEixoTrazeiro ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Qtd. Eixos: </span>
                            {data?.binNacional?.numeroEixos || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Nº Motor: </span>
                            {data?.binNacional?.numeroMotor || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">PBT: </span>
                            {data?.binNacional?.PBT || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Potência: </span>
                            {data?.binNacional?.potenciaVeiculo ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Procedência: </span>
                            {data?.binNacional?.procedencia || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Qtd. Passageiros:{" "}
                            </span>
                            {data?.binNacional?.quantidadePassageiros ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Situação do chassi:{" "}
                            </span>
                            {data?.binNacional?.situacaoChassi ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo de Carroceria:{" "}
                            </span>
                            {data?.binNacional?.tipoCarroceria ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo Doc. Faturado:{" "}
                            </span>
                            {data?.binNacional?.tipoDocumentoFaturado ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo Doc. Importador:{" "}
                            </span>
                            {data?.binNacional?.tipoDocumentoImportador ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Nº Importador:{" "}
                            </span>
                            {data?.binNacional?.numeroIdentificacaoImportador ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo Op. Importação:{" "}
                            </span>
                            {data?.binNacional?.tipoOperacaoImportacaoVeiculo ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Montagem: </span>
                            {data?.binNacional?.tipoMontagem || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Nº Faturado: </span>
                            {data?.binNacional?.numeroIdentificacaoFaturado ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              uf Faturamento:{" "}
                            </span>
                            {data?.binNacional?.ufFaturamento || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data Limite Rest. Tributária:{" "}
                            </span>
                            {data?.binNacional
                              ?.DATA_LIMITE_RESTRICAO_TRIBUTARIA ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data Atualização:{" "}
                            </span>
                            {data?.binNacional?.dataAtualizacao ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Código Órgão SRF:{" "}
                            </span>
                            {data?.binNacional?.codigoOrgaoSRF ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data Emissão Último CRV:{" "}
                            </span>
                            {data?.binNacional?.dataEmissaoUltimoCRV ||
                              "Indisponível"}
                          </div>
                          <div className="title-vehicle">
                            Restrições | Nacional
                          </div>
                          {data?.binNacional?.restricoes
                            ?.existeRestricaoRouboFurto === "0" &&
                          data?.binNacional?.restricoes?.veiculoBaixado ===
                            "0" &&
                          data?.binNacional?.restricoes
                            ?.existeRestricaoRenajud === "0" &&
                          data?.binNacional?.restricoes
                            ?.existeRestricaoGeral === "0" ? (
                            <div className="flex gap-2 items-center font-semibold mt-3">
                              <IoMdCheckmarkCircleOutline color="green" />
                              <div>Não constam restrições</div>
                            </div>
                          ) : (
                            <div className="flex flex-col gap-2 mt-3">
                              {data?.binNacional?.restricoes
                                ?.existeRestricaoRouboFurto === "1" && (
                                <div className="flex gap-2 items-center font-semibold">
                                  <MdBlock color="red" />
                                  <div>Restrição: Roubo e furto</div>
                                </div>
                              )}

                              {data?.binNacional?.restricoes
                                ?.existeRestricaoRenajud === "1" && (
                                <div className="flex gap-2 items-center font-semibold">
                                  <MdBlock color="red" />
                                  <div>Restrição: renajud</div>
                                </div>
                              )}

                              {data?.binNacional?.restricoes
                                ?.existeRestricaoGeral === "1" && (
                                <div className="flex gap-2 items-center font-semibold">
                                  <MdBlock color="red" />
                                  <div>Restrição: Geral</div>
                                </div>
                              )}

                              {data?.binNacional?.restricoes?.veiculoBaixado ===
                                "1" && (
                                <div className="flex gap-2 items-center font-semibold">
                                  <MdBlock color="red" />
                                  <div>Restrição: Veículo Baixado</div>
                                </div>
                              )}
                            </div>
                          )}

                          <div className="title-vehicle mt-4">
                            Proprietário | Nacional
                          </div>
                          <div className="mt-3">
                            <span className="font-semibold">Nome:</span>{" "}
                            {data?.binNacional?.proprietario?.nome ||
                              "Indisponível"}
                          </div>
                          <div className="mt-2">
                            <span className="font-semibold">Documento:</span>{" "}
                            {data?.binNacional?.proprietario?.documento ||
                              "Indisponível"}
                          </div>
                        </div>
                      </div>
                      <div className="text-sm flex-1">
                        <div className="title-vehicle mb-3">Base Estadual</div>
                        <div className="p-2 rounded bg-gray-100">
                          <div className="mb-3">
                            <span className="font-semibold">
                              Marca/Modelo:{" "}
                            </span>
                            {data?.binEstadual?.marcaModelo || "Sem info."}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Ano Fabr./Modelo:{" "}
                            </span>
                            {`${data?.binEstadual?.anoFabricacao}/${data?.binEstadual?.anoModelo}` ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tempo de circulação:{" "}
                            </span>
                            {`${
                              moment().format("YYYY") -
                              +data?.binEstadual?.anoFabricacao
                            } anos` || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Categoria: </span>
                            {data?.binEstadual?.CATEGORIA_VEICULO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo de veículo:{" "}
                            </span>
                            {data?.binEstadual?.TIPO_VEICULO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Placa: </span>
                            {document || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">RENAVAM: </span>
                            {data?.binEstadual?.RENAVAM || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">chassi: </span>
                            {data?.binEstadual?.chassi || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Situação: </span>
                            {data?.binEstadual?.SITUACAO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Câmbio: </span>
                            {data?.binEstadual?.CAIXA_CAMBIO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Capacidade Carga:{" "}
                            </span>
                            {data?.binEstadual?.CAPACIDADE_CARGA ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Cilindrada: </span>
                            {data?.binEstadual?.CILINDRADA || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">CMT: </span>
                            {data?.binEstadual?.CMT || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Combustível: </span>
                            {data?.binEstadual?.combustivel || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Cor: </span>
                            {data?.binEstadual?.corVeiculo || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Emplacamento:{" "}
                            </span>
                            {data?.binEstadual?.dataEmplacamento ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Espécie: </span>
                            {data?.binEstadual?.especieVeiculo ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Município: </span>
                            {data?.binEstadual?.municipio || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">uf: </span>
                            {data?.binEstadual?.uf || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Nº Carroceria:{" "}
                            </span>
                            {data?.binEstadual?.numeroCarroceria ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Nº DI: </span>
                            {data?.binEstadual?.numeroDI || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data registro DI:{" "}
                            </span>
                            {data?.binEstadual?.dataRegistroDI ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Nº Eixo Auxiliar:{" "}
                            </span>
                            {data?.binEstadual?.numeroEixoAuxiliar ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Nº Eixo Traseiro:{" "}
                            </span>
                            {data?.binEstadual?.numeroEixoTrazeiro ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Qtd. Eixos: </span>
                            {data?.binEstadual?.numeroEixos || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Nº Motor: </span>
                            {data?.binEstadual?.numeroMotor || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">PBT: </span>
                            {data?.binEstadual?.pbt || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Potência: </span>
                            {data?.binEstadual?.potenciaVeiculo ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Procedência: </span>
                            {data?.binEstadual?.procedencia || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Qtd. Passageiros:{" "}
                            </span>
                            {data?.binEstadual?.quantidadePassageiros ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Situação do chassi:{" "}
                            </span>
                            {data?.binEstadual?.situacaoChassi ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo de Carroceria:{" "}
                            </span>
                            {data?.binEstadual?.tipoCarroceria ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo Doc. Faturado:{" "}
                            </span>
                            {data?.binEstadual?.tipoDocumentoFaturado ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo Doc. Importador:{" "}
                            </span>
                            {data?.binEstadual?.tipoDocumentoImportador ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Nº Importador:{" "}
                            </span>
                            {data?.binEstadual?.numeroIdentificacaoImportador ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo Op. Importação:{" "}
                            </span>
                            {data?.binEstadual?.tipoOperacaoImportacaoVeiculo ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Montagem: </span>
                            {data?.binEstadual?.tipoMontagem || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Nº Faturado: </span>
                            {data?.binEstadual?.numeroIdentificacaoFaturado ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              uf Faturamento:{" "}
                            </span>
                            {data?.binEstadual?.ufFaturamento || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data Limite Rest. Tributária:{" "}
                            </span>
                            {data?.binEstadual
                              ?.DATA_LIMITE_RESTRICAO_TRIBUTARIA ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data Atualização:{" "}
                            </span>
                            {data?.binEstadual?.dataAtualizacao ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Código Órgão SRF:{" "}
                            </span>
                            {data?.binEstadual?.codigoOrgaoSRF ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data Emissão Último CRV:{" "}
                            </span>
                            {data?.binEstadual?.dataEmissaoUltimoCRV ||
                              "Indisponível"}
                          </div>
                          <div className="title-vehicle">
                            Restrições | Estadual
                          </div>
                          {data?.binEstadual?.restricoes
                            ?.existeRestricaoRouboFurto === "0" &&
                          data?.binEstadual?.restricoes?.veiculoBaixado ===
                            "0" &&
                          data?.binEstadual?.restricoes
                            ?.existeRestricaoRenajud === "0" &&
                          data?.binEstadual?.restricoes
                            ?.existeRestricaoGeral === "0" ? (
                            <div className="flex gap-2 items-center font-semibold mt-3">
                              <IoMdCheckmarkCircleOutline color="green" />
                              <div>Não constam restrições</div>
                            </div>
                          ) : (
                            <div className="flex flex-col gap-2 mt-3">
                              {data?.binEstadual?.restricoes
                                ?.existeRestricaoRouboFurto === "1" && (
                                <div className="flex gap-2 items-center font-semibold">
                                  <MdBlock color="red" />
                                  <div>Restrição: Roubo e furto</div>
                                </div>
                              )}

                              {data?.binEstadual?.restricoes
                                ?.existeRestricaoRenajud === "1" && (
                                <div className="flex gap-2 items-center font-semibold">
                                  <MdBlock color="red" />
                                  <div>Restrição: renajud</div>
                                </div>
                              )}

                              {data?.binEstadual?.restricoes
                                ?.existeRestricaoGeral === "1" && (
                                <div className="flex gap-2 items-center font-semibold">
                                  <MdBlock color="red" />
                                  <div>Restrição: Geral</div>
                                </div>
                              )}

                              {data?.binEstadual?.restricoes?.veiculoBaixado ===
                                "1" && (
                                <div className="flex gap-2 items-center font-semibold">
                                  <MdBlock color="red" />
                                  <div>Restrição: Veículo Baixado</div>
                                </div>
                              )}
                            </div>
                          )}
                          <div className="title-vehicle mt-4">
                            multas e débitos | Estadual
                          </div>
                          <div className="flex gap-2 items-center font-semibold mt-3">
                            {data?.binEstadual?.restricoes?.dpvat
                              ?.existePendencia === "1" ? (
                              <MdBlock color="red" />
                            ) : (
                              <IoMdCheckmarkCircleOutline color="green" />
                            )}

                            <div>Débitos: dpvat</div>
                            {data?.binEstadual?.restricoes?.dpvat
                              ?.existePendencia === "1" && (
                              <div>
                                | R${" "}
                                {
                                  data?.binEstadual?.restricoes?.dpvat
                                    ?.valorPendencia
                                }
                              </div>
                            )}
                          </div>
                          <div className="flex gap-2 items-center font-semibold mt-3">
                            {data?.binEstadual?.restricoes?.ipva
                              ?.existePendencia === "1" ? (
                              <MdBlock color="red" />
                            ) : (
                              <IoMdCheckmarkCircleOutline color="green" />
                            )}
                            <div>Débitos: ipva</div>
                            {data?.binEstadual?.restricoes?.ipva
                              ?.existePendencia === "1" && (
                              <div>
                                | R${" "}
                                {
                                  data?.binEstadual?.restricoes?.ipva
                                    ?.valorPendencia
                                }
                              </div>
                            )}
                          </div>
                          <div className="flex gap-2 items-center font-semibold mt-3">
                            {data?.binEstadual?.restricoes?.licenciamento
                              ?.existePendencia === "1" ? (
                              <MdBlock color="red" />
                            ) : (
                              <IoMdCheckmarkCircleOutline color="green" />
                            )}
                            <div>Débitos: licenciamento</div>
                            {data?.binEstadual?.restricoes?.licenciamento
                              ?.existePendencia === "1" && (
                              <div>
                                | R${" "}
                                {
                                  data?.binEstadual?.restricoes?.licenciamento
                                    ?.valorPendencia
                                }
                              </div>
                            )}
                          </div>
                          <div className="flex gap-2 items-center font-semibold mt-3">
                            {data?.binEstadual?.restricoes?.multas
                              ?.existePendencia === "1" ? (
                              <MdBlock color="red" />
                            ) : (
                              <IoMdCheckmarkCircleOutline color="green" />
                            )}
                            <div>Débitos: multas</div>
                            {data?.binEstadual?.restricoes?.multas
                              ?.existePendencia === "1" && (
                              <div>
                                | R${" "}
                                {
                                  data?.binEstadual?.restricoes?.multas
                                    ?.valorPendencia
                                }
                              </div>
                            )}
                          </div>

                          <div className="title-vehicle mt-4">
                            Proprietário | Estadual
                          </div>
                          <div className="mt-3">
                            <span className="font-semibold">Nome:</span>{" "}
                            {data?.binEstadual?.proprietario?.nome ||
                              "Indisponível"}
                          </div>
                          <div className="mt-2">
                            <span className="font-semibold">Documento:</span>{" "}
                            {data?.binEstadual?.proprietario?.documento ||
                              "Indisponível"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {dataCadastral?.emails?.statusRetorno?.codigo === "1" && (
                    <div>
                      <TitleStripe>E-mails de contato</TitleStripe>

                      <>
                        {dataCadastral?.emails?.ocorrencias?.length === 0 ? (
                          <NoOcurrence />
                        ) : (
                          <div className="overflow-hidden rounded-lg border border-gray-300 mt-3">
                            <Table striped className="text-sm">
                              <Table.Head>
                                <Table.HeadCell className="bg-gray-300 p-1">
                                  Email
                                </Table.HeadCell>
                              </Table.Head>
                              <Table.Body className="divide-y">
                                {dataCadastral?.emails?.ocorrencias?.map(
                                  (email, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white p-1">
                                        {email || "-"}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </Table.Body>
                            </Table>
                          </div>
                        )}
                      </>
                    </div>
                  )}

                  {dataCadastral?.telefoneCelular?.statusRetorno?.codigo ===
                    "1" && (
                    <div>
                      <TitleStripe>Números de celulares</TitleStripe>
                      <>
                        {dataCadastral?.telefoneCelular?.ocorrencias?.length ===
                        0 ? (
                          <NoOcurrence />
                        ) : (
                          <div className="overflow-hidden rounded-lg border border-gray-300 mt-3">
                            <Table striped className="text-sm">
                              <Table.Head>
                                <Table.HeadCell className="bg-gray-300 p-1">
                                  Nome
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  DDD
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Telefone
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Tipo de pessoa
                                </Table.HeadCell>
                              </Table.Head>
                              <Table.Body className="divide-y">
                                {dataCadastral?.telefoneCelular?.ocorrencias?.map(
                                  (telefone, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white p-1">
                                        {telefone.nome}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {telefone.ddd}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {telefone.telefone}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {telefone.tipoPessoa}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </Table.Body>
                            </Table>
                          </div>
                        )}
                      </>
                    </div>
                  )}

                  {dataCadastral?.telefoneFixo?.statusRetorno?.codigo ===
                    "1" && (
                    <div>
                      <TitleStripe>Números de telefone fixo</TitleStripe>
                      <>
                        {dataCadastral?.telefoneFixo?.ocorrencias?.length ===
                        0 ? (
                          <NoOcurrence />
                        ) : (
                          <div className="overflow-hidden rounded-lg border border-gray-300 mt-3">
                            <Table striped className="text-sm">
                              <Table.Head>
                                <Table.HeadCell className="bg-gray-300 p-1">
                                  Nome
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  DDD
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Telefone
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Tipo de pessoa
                                </Table.HeadCell>
                              </Table.Head>
                              <Table.Body className="divide-y">
                                {dataCadastral?.telefoneFixo?.ocorrencias?.map(
                                  (telefone, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white p-1">
                                        {telefone.nome}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {telefone.ddd}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {telefone.telefone}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {telefone.tipoPessoa}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </Table.Body>
                            </Table>
                          </div>
                        )}
                      </>
                    </div>
                  )}

                  {dataCadastral?.parentes?.statusRetorno?.codigo === "1" && (
                    <div>
                      <TitleStripe>Parentes</TitleStripe>

                      <>
                        {dataCadastral?.parentes?.ocorrencias?.length === 0 ? (
                          <NoOcurrence />
                        ) : (
                          <div className="overflow-hidden rounded-lg border border-gray-300 mt-3">
                            <Table striped className="text-sm">
                              <Table.Head>
                                <Table.HeadCell className="bg-gray-300 p-1">
                                  Nome
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Documento
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Endereço
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Bairro
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Cidade
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Data de nascimento
                                </Table.HeadCell>
                              </Table.Head>
                              <Table.Body className="divide-y">
                                {dataCadastral?.parentes?.ocorrencias?.map(
                                  (pessoa, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white p-1">
                                        {pessoa.nome || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {pessoa.documento || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {pessoa.endereco || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {pessoa.bairro || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {pessoa.cidade || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {pessoa.nascimento || "-"}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </Table.Body>
                            </Table>
                          </div>
                        )}
                      </>
                    </div>
                  )}

                  {dataCadastral?.enderecos?.statusRetorno?.codigo === "1" && (
                    <div>
                      <TitleStripe>Endereços</TitleStripe>
                      <>
                        {dataCadastral?.enderecos?.ocorrencias?.length === 0 ? (
                          <NoOcurrence />
                        ) : (
                          <div className="overflow-hidden rounded-lg border border-gray-300 mt-3">
                            <Table striped className="text-sm">
                              <Table.Head>
                                <Table.HeadCell className="bg-gray-300 p-1">
                                  Endereço
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Bairro
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Cidade
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  UF
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  CEP
                                </Table.HeadCell>
                              </Table.Head>
                              <Table.Body className="divide-y">
                                {dataCadastral?.enderecos?.ocorrencias?.map(
                                  (endereco, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white p-1">
                                        {endereco.endereco || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {endereco.bairro || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {endereco.cidade || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {endereco.uf || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {endereco.cep || "-"}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </Table.Body>
                            </Table>
                          </div>
                        )}
                      </>
                    </div>
                  )}

                  <FooterQuery hasHeader={hasHeaderPDF} />
                </Card>
              </>
            ) : (
              <Loader
                words={["Resumo do veículo", "Bin Estadual", "Bin Nacional"]}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DadosRenainfMultasForaEstadoDetranEQuery;
