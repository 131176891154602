import React, { useEffect, useState } from "react";
import { Card } from "flowbite-react";
import { USERS } from "../../config/apiConfig";
import { useQuery } from "react-query";
import axios from "../../config/axiosInstance";
import DrawerLoading from "../DrawerLoading";
import { AiOutlineFileSearch } from "react-icons/ai";
import ForbiddenQueriesPermission from "../common/ForbiddenQueriesPermission";
import { IoSearch } from "react-icons/io5";
import CompletaVeicularQuery from "../queries/CompletaVeicularQuery";
import LeilaoPositivoQuery from "../queries/autos/LeilaoPositivoQuery";
import VeiculoEssencialQuery from "../queries/autos/VeiculoEssencialQuery";
import PrecificacaoQuery from "../queries/autos/PrecificacaoQuery";
import DadosProprietarioAtualQuery from "../queries/autos/DadosProprietarioAtualQuery";
import LeilaoFacilQuery from "../queries/autos/LeilaoFacilQuery";
import DadosRenainfMultasForaEstadoDetranEQuery from "../queries/autos/DadosRenainfMultasForaEstadoDetranEQuery";
import DadosRenajudDetalhamentoQuery from "../queries/autos/DadosRenajudDetalhamentoQuery";
import HistoricoDeRouboEFurtoQuery from "../queries/autos/HistoricoDeRouboEFurtoQuery";
import LaudoCsvPrimeQuery from "../queries/autos/LaudoCsvPrimeQuery";
import DadosHistoricoDeGravameQuery from "../queries/autos/DadosHistoricoDeGravameQuery";
import LeilaoBase01ScoreVeicularQuery from "../queries/autos/LeilaoBase01ScoreVeicularQuery";
import IndicioDeSinistro01Query from "../queries/autos/IndicioDeSinistro01Query";
import FichaTecnicaQuery from "../queries/autos/FichaTecnicaQuery";
import LeilaoScoreParecerTecnicoIndicioQuery from "../queries/autos/LeilaoScoreParecerTecnicoIndicioQuery";
import NacionalProvedorUnicoQuery from "../queries/autos/NacionalProvedorUnicoQuery";
import EstadualProvedorUnicoQuery from "../queries/autos/EstadualProvedorUnicoQuery";
import CRLVEQuery from "../queries/autos/CRLVEQuery";
import AutoVistoriaQuery from "../queries/autos/AutoVistoriaQuery";

const QueriesAutoTable = () => {
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [searchTerm, setSearchTerm] = useState(""); // Novo estado para a busca

  const {
    isLoading,
    error,
    data: userQueryPermissions,
  } = useQuery(["userQueryPermissions"], async () => {
    const { data: items } = await axios.get(
      `${USERS.GET_USER_QUERY_PERMISSIONS}`
    );

    return items.data.filter((query) => query.categoryId === 3);
  });

  useEffect(() => {
    if (selectedQuery) {
      const matchingQuery = userQueryPermissions.find(
        (query) => query.id === selectedQuery?.id
      );
      setSelectedPrice(matchingQuery?.price);
    }
  }, [selectedQuery]);

  const filteredQueries = userQueryPermissions?.filter((query) =>
    query?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const queries = [
    {
      id: 26,
      name: "Leilão Positivo",
      component: (
        <LeilaoPositivoQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
    },
    {
      id: 28,
      name: "Veículo Essencial",
      component: (
        <VeiculoEssencialQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
    },
    {
      id: 29,
      name: "Leilão Fácil",
      component: (
        <LeilaoFacilQuery query={selectedQuery} selectedPrice={selectedPrice} />
      ),
    },
    {
      id: 30,
      name: "Dados Renainf - Multas Fora Estado, Detran",
      component: (
        <DadosRenainfMultasForaEstadoDetranEQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
    },
    {
      id: 31,
      name: "Dados Renainf - Multas Fora Estado, Detran",
      component: (
        <DadosRenajudDetalhamentoQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
    },
    {
      id: 32,
      name: "Histórico de Roubo e Furto",
      component: (
        <HistoricoDeRouboEFurtoQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
    },
    {
      id: 33,
      name: "Laudo CSV Prime",
      component: (
        <LaudoCsvPrimeQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
    },
    {
      id: 34,
      name: "Auto Vistoria",
      component: (
        <AutoVistoriaQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
    },
    {
      id: 36,
      name: "Precificação",
      component: (
        <PrecificacaoQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
    },
    {
      id: 37,
      name: "Dados Histórico de Gravame",
      component: (
        <DadosHistoricoDeGravameQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
    },
    {
      id: 38,
      name: "Leilão Base 01 + Score Veicular",
      component: (
        <LeilaoBase01ScoreVeicularQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
    },
    {
      id: 39,
      name: "Indicio De Sinistro 01",
      component: (
        <IndicioDeSinistro01Query
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
    },
    {
      id: 40,
      name: "Dados Proprietário Atual",
      component: (
        <DadosProprietarioAtualQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
    },
    {
      id: 41,
      name: "Ficha Técnica",
      component: (
        <FichaTecnicaQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
    },
    {
      id: 42,
      name: "Estadual Provedor Único",
      component: (
        <EstadualProvedorUnicoQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
    },
    {
      id: 43,
      name: "Nacional Provedor Único",
      component: (
        <NacionalProvedorUnicoQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
    },
    {
      id: 44,
      name: "Leilão Score + Parecer Técnico + Indício",
      component: (
        <LeilaoScoreParecerTecnicoIndicioQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
    },
    {
      id: 44,
      name: "Leilão Score + Parecer Técnico + Indício",
      component: (
        <LeilaoScoreParecerTecnicoIndicioQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
    },
    {
      id: 45,
      name: "CRLVe",
      component: (
        <CRLVEQuery query={selectedQuery} selectedPrice={selectedPrice} />
      ),
    },
  ];

  return (
    <div className="w-100 flex flex-col lg:flex-row relative">
      {isLoading && <DrawerLoading />}
      {userQueryPermissions?.length > 0 ? (
        <>
          <div className="w-full lg:w-3/12 p-4 flex flex-col gap-3">
            <h2 className="title-syncx text-lg">Consultas veiculares</h2>
            <div className="border-none rounded-md bg-secondary p-2 shadow-lg flex items-center gap-1 px-3">
              <IoSearch color="white" fontSize={20} />
              <input
                className="border-none rounded-md bg-secondary w-full placeholder-white focus:outline-none focus:border-none text-white text-sm"
                type="text"
                placeholder="Busque aqui sua consulta"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} // Atualiza o valor da busca
              />
            </div>
            <div
              className="bg-gray-200 rounded-md shadow-md p-2 overflow-y-auto flex flex-col gap-2 styled-scrollbar"
              style={{ maxHeight: "520px" }}
            >
              {filteredQueries?.map((query, index) => (
                <Card
                  key={index}
                  className={`card-query text-blue-900 ${
                    selectedQuery?.id === query.id && "active"
                  }`}
                  onClick={() => setSelectedQuery(query)}
                >
                  <span>{query.name}</span>
                </Card>
              ))}
            </div>
          </div>
          {selectedQuery ? (
            <div className={`w-full lg:w-9/12 p-4`}>
              {
                queries.find((query) => query.id === selectedQuery?.id)
                  ?.component
              }
            </div>
          ) : (
            <div className="mt-14 w-full lg:w-9/12 text-center py-10 border-dashed border-gray-300 border-2 rounded font-semibold text-gray-500 flex items-center justify-center min-h-60 flex gap-1 flex-col">
              <AiOutlineFileSearch fontSize={30} />
              <span>Escolha uma opção ao lado para realizar uma consulta.</span>
            </div>
          )}
        </>
      ) : (
        <ForbiddenQueriesPermission />
      )}
    </div>
  );
};

export default QueriesAutoTable;
