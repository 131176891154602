import { Table } from "flowbite-react";

const ParticipacaoEmEmpresas = ({ empresas }) => {
  return (
    <>
      <div className="overflow-x-auto border-gray-100 border rounded-lg mt-3">
        <Table striped>
          <Table.Head className="text-black">
            <Table.HeadCell
              className="text-center py-2 px-1"
              style={{ background: "#76b9fc" }}
            >
              Nº
            </Table.HeadCell>
            <Table.HeadCell className="py-2" style={{ background: "#76b9fc" }}>
              Empresa
            </Table.HeadCell>
            {/* <Table.HeadCell
              className="py-2 text-center"
              style={{ background: "#76b9fc" }}
            >
              Restrições
            </Table.HeadCell> */}
            <Table.HeadCell
              className="text-center py-2 px-1"
              style={{ background: "#76b9fc" }}
            >
              Data <br /> Entrada
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center py-2 px-1"
              style={{ background: "#76b9fc" }}
            >
              Part. (%)
            </Table.HeadCell>
          </Table.Head>
          {empresas?.length > 0 && (
            <Table.Body className="divide-y">
              {empresas?.map((empresa, index) => (
                <Table.Row
                  key={index}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black"
                >
                  <Table.Cell className="font-medium dark:text-white text-center py-1 px-1">
                    {index + 1}
                  </Table.Cell>
                  <Table.Cell className=" py-1">
                    <div>{empresa?.empresa || "-"}</div>
                    <div style={{ fontSize: "12px" }}>
                      CNPJ: {empresa?.cnpj}
                    </div>
                    {/* {+empresa?.valor > 0 && (
                      <a href="" className="text-xs text-blue-500 uppercase">
                        Deseja consultar este CNPJ?
                      </a>
                    )} */}
                  </Table.Cell>
                  {/* <Table.Cell className="text-center py-1 px-1">
                    {+empresa?.valor > 0 ? (
                      <span className="text-red-600 font-semibold bg-red-100 px-2 py-1 rounded-md text-xs">
                        Sim
                      </span>
                    ) : (
                      <span className="text-green-600 font-semibold bg-green-100 px-2 py-1 rounded-md text-xs">
                        Não
                      </span>
                    )}
                  </Table.Cell> */}
                  <Table.Cell className="text-center py-1 px-1">
                    {empresa?.dataEntrada || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center py-1 px-1 px-1">
                    {empresa?.participacaoEmPorcentagem || "-"}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </div>
    </>
  );
};

export default ParticipacaoEmEmpresas;
