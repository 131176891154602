import { Alert, Badge, Button, Card, Table } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import axios from "../../../config/axiosInstance";
import { QUERIES_VEICULAR } from "../../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "../HeaderQuery";
import usePDF from "../../../hooks/usePDF";
import TitleStripe from "../../common/TitleStripe";
import CardQueryDescription from "../../common/CardQueryDescription";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import SliderToPreview from "../../common/SliderToPreview";
import { FaCar, FaCheckCircle } from "react-icons/fa";
import Loader from "../../common/Loader";
import FooterQuery from "../FooterQuery";
import NoOcurrence from "../../common/query/NoOcurrence";
import QueryVehicleInput from "../../inputs/QueryVehicleInput";

const LeilaoBase01ScoreVeicularQuery = ({
  query,
  selectedPrice,
  dataFromLocalStorage = null,
  hasHeaderPDF = "true",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    data
  );

  useEffect(() => {
    if (dataFromLocalStorage) {
      setData(dataFromLocalStorage?.dadosVeicular);
      setDocument(dataFromLocalStorage?.resumoRetorno?.document);
    }
  }, []);

  const fetchInfo = async (raw) => {
    return await axios(`${QUERIES_VEICULAR.GET_VEICULAR}/${query?.id}/${info}`);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);
    let isValid = true;

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados?.data?.dadosVeiculo || null);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setData(null);
    }
  };

  const getImageScoreSale = (score) => {
    const scoreLetter = {
      A: "1",
      B: "2",
      C: "3",
      D: "4",
      E: "5",
    };

    return `/assets/score/score_vehicle_${scoreLetter[score]}.png`;
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={["Leilão"]}
        price={selectedPrice}
        imageBanner="/assets/bg_banner_car.jpg"
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <QueryVehicleInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="PLACA"
            />
            <div>
              {!errorQuery && data && (
                <Button
                  className={`w-fit shadow-lg secondary ${
                    data && !isLoading ? "block" : "hidden"
                  }`}
                  isProcessing={pdfURL === ""}
                  disabled={pdfURL === ""}
                  onClick={() => handleClickDownloadPDF()}
                >
                  Salvar como PDF
                </Button>
              )}
            </div>
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`overflow-x-scroll ${
              data || isLoading ? "block" : "hidden"
            }`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="relative overflow-hidden p-5 card-pdf">
                  <HeaderQuery title={query?.name} type="vehicle" />

                  {data?.leilaoConjugado?.statusRetorno?.codigo === "1" && (
                    <div>
                      <TitleStripe>Dados de Leilões Realizados</TitleStripe>

                      {data?.leilaoConjugado?.ocorrencias?.length === 0 ? (
                        <Card className={`bg-green-200 mt-4`}>
                          <div className="flex gap-2 items-center font-semibold">
                            <div>
                              <IoMdCheckmarkCircleOutline fontSize={20} />
                            </div>
                            <div>Nenhum registro de leilão encontrado!</div>
                          </div>
                        </Card>
                      ) : (
                        <>
                          {data?.leilaoConjugado?.ocorrencias?.map(
                            (leilaoCompleto, indexL) => (
                              <div key={indexL}>
                                <div className="title-syncx mt-3">
                                  Dados do veículo
                                </div>
                                <div className="grid grid-cols-2 gap-3 text-sm mt-3 mb-5">
                                  <div className="flex gap-2 items-center">
                                    <span className="font-semibold">
                                      Marca/Modelo:
                                    </span>
                                    <span>
                                      {
                                        leilaoCompleto?.dadosVeiculo
                                          ?.marcaModelo
                                      }
                                    </span>
                                  </div>
                                  <div className="flex gap-2 items-center">
                                    <span className="font-semibold">
                                      Ano Fabr./Modelo:
                                    </span>
                                    <span>
                                      {
                                        leilaoCompleto?.dadosVeiculo
                                          ?.anoFabricacao
                                      }
                                      /{leilaoCompleto?.dadosVeiculo?.anoModelo}
                                    </span>
                                  </div>
                                  <div className="flex gap-2 items-center">
                                    <span className="font-semibold">
                                      Categoria:
                                    </span>
                                    <span>
                                      {leilaoCompleto?.dadosVeiculo?.categoria}
                                    </span>
                                  </div>
                                  <div className="flex gap-2 items-center">
                                    <span className="font-semibold">
                                      Motor:
                                    </span>
                                    <span>
                                      {leilaoCompleto?.dadosVeiculo?.motor}
                                    </span>
                                  </div>
                                  <div className="flex gap-2 items-center">
                                    <span className="font-semibold">
                                      Combustível:
                                    </span>
                                    <span>
                                      {
                                        leilaoCompleto?.dadosVeiculo
                                          ?.combustivel
                                      }
                                    </span>
                                  </div>
                                  <div className="flex gap-2 items-center">
                                    <span className="font-semibold">Cor:</span>
                                    <span>
                                      {leilaoCompleto?.dadosVeiculo?.cor}
                                    </span>
                                  </div>
                                  <div className="flex gap-2 items-center">
                                    <span className="font-semibold">
                                      Chassi:
                                    </span>
                                    <span>
                                      {leilaoCompleto?.dadosVeiculo?.chassi}
                                    </span>
                                  </div>
                                </div>
                                <div className="title-vehicle mt-3">
                                  Dados do leilão | {leilaoCompleto?.nomeBase}
                                </div>
                                {leilaoCompleto?.ocorrencias?.length > 0 ? (
                                  <>
                                    <div className="overflow-hidden rounded-lg border border-gray-300 mt-3">
                                      <Table striped className="w-full text-xs">
                                        <Table.Head>
                                          <Table.HeadCell className="text-center bg-gray-300 px-1 py-1">
                                            Leiloeiro
                                          </Table.HeadCell>
                                          <Table.HeadCell className="text-center bg-gray-300 px-1 py-1">
                                            Data
                                          </Table.HeadCell>
                                          <Table.HeadCell className="text-center bg-gray-300 px-1 py-1">
                                            Lote
                                          </Table.HeadCell>
                                          <Table.HeadCell className="text-center bg-gray-300 px-1 py-1">
                                            Pátio
                                          </Table.HeadCell>
                                          <Table.HeadCell className="text-center bg-gray-300 px-1 py-1">
                                            Situação chassi
                                          </Table.HeadCell>
                                          <Table.HeadCell className="text-center bg-gray-300 px-1 py-1">
                                            Condição Geral
                                          </Table.HeadCell>
                                          <Table.HeadCell className="text-center bg-gray-300 px-1 py-1">
                                            Comitente
                                          </Table.HeadCell>
                                        </Table.Head>
                                        <Table.Body className="divide-y">
                                          {leilaoCompleto?.ocorrencias?.map(
                                            (leilao, index) => (
                                              <Table.Row
                                                key={index}
                                                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                              >
                                                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center px-1 py-1">
                                                  {leilao?.leiloeiro || "-"}
                                                </Table.Cell>
                                                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center px-1 py-1">
                                                  {leilao?.dataLeilao || "-"}
                                                </Table.Cell>
                                                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center px-1 py-1">
                                                  {leilao?.lote || "-"}
                                                </Table.Cell>
                                                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center px-1 py-1">
                                                  {leilao?.patio || "-"}
                                                </Table.Cell>
                                                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center px-1 py-1">
                                                  {leilao?.situacaoChassi ||
                                                    "-"}
                                                </Table.Cell>
                                                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center px-1 py-1">
                                                  {leilao?.condicaoGeralVeiculo ||
                                                    "-"}
                                                </Table.Cell>
                                                <Table.Cell className="font-medium text-gray-900 dark:text-white text-center px-1 py-1">
                                                  {leilao?.comitente || "-"}
                                                </Table.Cell>
                                              </Table.Row>
                                            )
                                          )}
                                        </Table.Body>
                                      </Table>
                                    </div>

                                    <div className="title-vehicle mt-5">
                                      Itens verificados no leilão
                                    </div>
                                    <ul className="flex flex-wrap gap-4 mt-3">
                                      {[
                                        "Classificação de avaria",
                                        "Classificação para aceite securitário",
                                        "Classificação de probabilidade de vistoria",
                                        "Sinistro de pequena monta",
                                        "Sinistro de média monta",
                                        "Sinistro de grande monta",
                                        "Leilões presenciais",
                                        "Leilões on-line",
                                        "Leilões judiciais",
                                        "Leilões de seguradoras",
                                        "Leilões de bancos e financeiras",
                                        "Leilões de empresas",
                                      ].map((item, index) => (
                                        <li
                                          key={index}
                                          className="flex gap-2 items-center bg-green-200 rounded p-1 text-sm"
                                        >
                                          <FaCheckCircle color="green" />
                                          {item}
                                        </li>
                                      ))}
                                    </ul>

                                    <div className="title-vehicle mt-5">
                                      Score
                                    </div>

                                    <img
                                      className="mx-auto my-4 max-h-40"
                                      src={getImageScoreSale(
                                        leilaoCompleto?.score?.pontuacao?.toUpperCase()
                                      )}
                                    />
                                    {leilaoCompleto?.score?.aceitacao && (
                                      <>
                                        {" "}
                                        <div className="title-vehicle mt-5">
                                          Aceitação de Seguro
                                        </div>
                                        <div className="mt-2 text-sm bg-gray-100 rounded p-2 italic">
                                          Com base em estudo estatístico, a
                                          régua aponta a probabilidade de 0% a
                                          100% do veículo ser segurado nas
                                          principais seguradoras do país.
                                        </div>
                                        <div className="square">
                                          <FaCar
                                            fontSize={28}
                                            style={{
                                              left: `${
                                                leilaoCompleto?.score
                                                  ?.aceitacao === "100"
                                                  ? +leilaoCompleto?.score
                                                      ?.aceitacao - 4
                                                  : leilaoCompleto?.score
                                                      ?.aceitacao
                                              }%`,
                                            }}
                                          />
                                        </div>
                                        <div className="font-bold flex justify-end -mt-4">
                                          {leilaoCompleto?.score?.aceitacao}%
                                        </div>
                                      </>
                                    )}

                                    {leilaoCompleto?.score
                                      ?.percentualSobreTabelaReferencia && (
                                      <>
                                        {" "}
                                        <div className="title-vehicle mt-5">
                                          Percentual sobre a Tabela de
                                          Referência
                                        </div>
                                        <div className="mt-2 text-sm bg-gray-100 rounded p-2 italic">
                                          Com base em estudo estatístico, a
                                          régua aponta o possível percentual da
                                          tabela de referência (fipe) utilizada
                                          pela seguradora no momento da
                                          contratação do seguro do bem.
                                        </div>
                                        <div className="square">
                                          <FaCar
                                            fontSize={28}
                                            style={{
                                              left: `${
                                                leilaoCompleto?.score
                                                  ?.percentualSobreTabelaReferencia ===
                                                "100"
                                                  ? +leilaoCompleto?.score
                                                      ?.percentualSobreTabelaReferencia -
                                                    4
                                                  : leilaoCompleto?.score
                                                      ?.percentualSobreTabelaReferencia
                                              }%`,
                                            }}
                                          />
                                        </div>
                                        <div className="font-bold flex justify-end -mt-4">
                                          {
                                            leilaoCompleto?.score
                                              ?.percentualSobreTabelaReferencia
                                          }
                                          %
                                        </div>
                                      </>
                                    )}

                                    {leilaoCompleto?.score
                                      ?.exigeVistoriaEspecial && (
                                      <>
                                        <div className="title-vehicle mt-5">
                                          Necessidade de Vistoria Especial
                                        </div>
                                        <div className="mt-2 text-sm bg-gray-100 rounded p-2 italic">
                                          Com base em estudo estatístico, a
                                          régua aponta a menor ou maior
                                          probabilidade de exigência de vistoria
                                          especial por parte da seguradora.
                                        </div>
                                        <div className="square">
                                          <FaCar
                                            fontSize={28}
                                            style={{
                                              left: `${leilaoCompleto?.score?.percentualSobreTabelaReferencia}%`,
                                            }}
                                          />
                                        </div>
                                        <div className="font-bold flex justify-end -mt-4">
                                          {
                                            leilaoCompleto?.score
                                              ?.exigeVistoriaEspecial
                                          }
                                        </div>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <NoOcurrence />
                                )}
                              </div>
                            )
                          )}
                        </>
                      )}
                    </div>
                  )}

                  <FooterQuery hasHeader={hasHeaderPDF} />
                </Card>
              </>
            ) : (
              <Loader words={["Leilão"]} />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default LeilaoBase01ScoreVeicularQuery;
