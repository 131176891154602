import React, { useState } from "react";
import { Alert, Badge, Card, Table } from "flowbite-react";
import { API, COMPANY } from "../../config/apiConfig";
import axios from "../../config/axiosInstance";
import { useQuery } from "react-query";
import { CodeBlock, dracula, CopyBlock } from "react-code-blocks";
import TitleStripe from "../common/TitleStripe";

const DocsTable = () => {
  const [selectedQuery, setSelectedQuery] = useState(null);
  const listForms = [];

  const {
    isLoading: isLoadingToken,
    error: errorToken,
    data: token,
  } = useQuery(["token"], async () => {
    const data = await axios.get(`${API.GET_TOKEN}`);

    return data.data.token;
  });

  const {
    isLoading: isLoadingDocs,
    error: errorDocs,
    data: docs,
  } = useQuery(["docs"], async () => {
    const data = await axios.get(`${COMPANY.GET_DOCS}`);

    return data.data.data;
  });

  return (
    <div className="flex flex-col lg:flex-row gap-2">
      <div className="p-4 flex flex-col gap-2 w-full md:w-1/4">
        <h1 className="title-syncx mb-2">Consultas</h1>
        <ul className="flex flex-col bg-white rounded-md p-2 shadow">
          {docs?.map((doc, index) => (
            <li
              key={index}
              className={`border-b border-gray-300 text-sm p-2 ${
                selectedQuery?.Query?.id === doc?.Query?.id &&
                "bg-primary text-white rounded-md font-semibold"
              }`}
              onClick={() => setSelectedQuery(doc)}
            >
              {doc?.Query?.name}
            </li>
          ))}
        </ul>
      </div>
      <div className="w-full md:w-3/4">
        <Card className={`w-full mt-3`}>
          <div className="mb-4">
            <h2 className="title-syncx">
              Documentação da API: Positivo Plus CNPJ
            </h2>
            <br /> Esta documentação descreve como integrar-se à API utilizando
            um método <b>GET</b> com autenticação baseada em token. <br />
            Siga as instruções abaixo para enviar uma solicitação autenticada e
            entender a resposta da API.
            <br />
            <br />
            Endpoint URL:{" "}
            <Badge className="inline" color="indigo">
              https://app.acessopositivo.com.br{selectedQuery?.endpoint}
            </Badge>{" "}
            <br />
            Método:{" "}
            <Badge className="inline" color="indigo">
              {selectedQuery?.method}
            </Badge>
            <br />
            Autenticação:{" "}
            <Badge className="inline" color="indigo">
              Bearer Token
            </Badge>
            <br />
            <br />
            <div className="font-semibold">Token:</div>
            <CopyBlock text={token} theme={dracula} codeBlock />
            <Alert className="mt-2" color="warning" withBorderAccent>
              Não divulgue esse token com ninguém! O token é valido por 2 anos.
              Caso o token expire, entrar em contato diretamente com o suporte.
            </Alert>
            <div className="mt-4">
              <TitleStripe>Parâmetros da requisição</TitleStripe>
              <div className="overflow-x-auto border border-gray-300 rounded-lg mt-0 md:mt-5">
                <Table striped>
                  <Table.Head>
                    <Table.HeadCell className="text-center bg-gray-300 p-2">
                      Parâmetro
                    </Table.HeadCell>
                    <Table.HeadCell className="text-center bg-gray-300 p-2">
                      Tipo
                    </Table.HeadCell>
                    <Table.HeadCell className="text-center bg-gray-300 p-2">
                      Obrigatório
                    </Table.HeadCell>
                    <Table.HeadCell className="text-center bg-gray-300 p-2">
                      Observações
                    </Table.HeadCell>
                  </Table.Head>
                  <Table.Body className="divide-y">
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center p-2">
                        CNPJ
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center p-2">
                        Texto
                      </Table.Cell>
                      <Table.Cell className="text-center p-2 text-red-500">
                        Sim
                      </Table.Cell>
                      <Table.Cell className="text-center p-2">
                        Informar o CNPJ a ser consultado no parâmetro da URL.
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center p-2">
                        generatePDF
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center p-2">
                        Texto (true|false)
                      </Table.Cell>
                      <Table.Cell className="text-center p-2">Não</Table.Cell>
                      <Table.Cell className="text-center p-2">
                        Caso queira gerar o pdf e retornar junto a resposta,
                        colocar o parametro como true
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center p-2">
                        hasHeaderPDF
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center p-2">
                        Texto (true|false)
                      </Table.Cell>
                      <Table.Cell className="text-center p-2">Não</Table.Cell>
                      <Table.Cell className="text-center p-2">
                        Caso queira esconder o cabeçalho da empresa no PDF,
                        colocar o parametro como false
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
            <br />
            <span className="text-sm -mt-2 block italic">
              Obs: Envie o token de autenticação no cabeçalho da requisição.
            </span>
          </div>
          <div>
            <TitleStripe>Parâmetros da resposta</TitleStripe>
            <div className="overflow-x-auto border border-gray-300 rounded-lg mt-0 md:mt-5">
              <Table striped>
                <Table.Head>
                  <Table.HeadCell className="text-center bg-gray-300 p-2">
                    Chave
                  </Table.HeadCell>
                  <Table.HeadCell className="text-center bg-gray-300 p-2">
                    Tipo
                  </Table.HeadCell>
                  <Table.HeadCell className="text-center bg-gray-300 p-2">
                    Observações
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                      dadosCadastrais
                    </Table.Cell>
                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                      Objeto
                    </Table.Cell>
                    <Table.Cell className="text-center p-2">
                      Dados Cadastrais
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                      resumoConsulta
                    </Table.Cell>
                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                      Objeto
                    </Table.Cell>
                    <Table.Cell className="text-center p-2">
                      Resumo da consulta, caso haja pendências, irá aparecer o
                      resumo
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                      Score
                    </Table.Cell>
                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                      Objeto
                    </Table.Cell>
                    <Table.Cell className="text-center p-2">
                      Score de crédito
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                      pendenciasFinanceiras
                    </Table.Cell>
                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                      Array
                    </Table.Cell>
                    <Table.Cell className="text-center p-2">
                      Lista de todas as pendências financeiras
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                      protestos
                    </Table.Cell>
                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                      Array
                    </Table.Cell>
                    <Table.Cell className="text-center p-2">
                      Lista de todas os protestos
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                      chequesSemFundo
                    </Table.Cell>
                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                      Array
                    </Table.Cell>
                    <Table.Cell className="text-center p-2">
                      Lista de todas os cheques sem fundo
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                      chequesSustados
                    </Table.Cell>
                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                      Array
                    </Table.Cell>
                    <Table.Cell className="text-center p-2">
                      Lista de todas os cheques sustados
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                      historicoConsultas
                    </Table.Cell>
                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                      Array
                    </Table.Cell>
                    <Table.Cell className="text-center p-2">
                      Lista de todas as últimas consultas
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          </div>
          <TitleStripe>Exemplo de resposta</TitleStripe>
          <CodeBlock
            text={selectedQuery?.exampleJSON}
            language={"json"}
            showLineNumbers={true}
            wrapLines
            theme={dracula}
          />
        </Card>
      </div>
    </div>
  );
};

export default DocsTable;
