import Input from "../components/inputs/Input";
import { AiOutlineLoading } from "react-icons/ai";
import { Alert, Button, Checkbox, Label, Tabs } from "flowbite-react";
import useCEP from "../hooks/useCEP";
import { useForm } from "react-hook-form";
import { CHARGES, USERS } from "../config/apiConfig";
import axios from "../config/axiosInstance";
import { useEffect, useRef, useState } from "react";
import { useApplication } from "../contexts/ApplicationContext";
import { useQuery } from "react-query";
import CPFInput from "../components/inputs/CPFInput";
import PhoneInput from "../components/inputs/PhoneInput";
import moment from "moment";
import { formatToReal, isValidCNPJ, isValidCPF } from "../utils/utils";
import usePDF from "../hooks/usePDF";
import CNPJInput from "../components/inputs/CNPJInput";
import SelectInput from "../components/inputs/SelectInput";
import TitleStripe from "../components/common/TitleStripe";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

const ExtrajudicialNotificationPage = () => {
  const { user } = useApplication();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(null);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [data, setData] = useState(null);
  const [whatsappEmailCheckbox, setWhatsappEmailCheckbox] = useState(false);
  const [physicalCheckbox, setPhysicalCheckbox] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    "info",
    null,
    data
  );

  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  const {
    cep,
    setCep,
    address,
    loading: loadingCEP,
    error: errorCEP,
  } = useCEP();

  const onClickSearchCEP = (e) => {
    setCep(getValues("debtorAddressPostalCode"));
  };

  useEffect(() => {
    setValue("debtorAddress", address?.logradouro);
    setValue("debtorAddressNeighborhood", address?.bairro);
    setValue("debtorAddressCity", address?.localidade);
  }, [address]);

  const total =
    (whatsappEmailCheckbox ? 9.9 : 0) + (physicalCheckbox ? 4.9 : 0);

  const checkDocument = (e, type) => {
    const unmaskedValue = e.target.value.replace(/\D/g, "");
    let isValid;

    if (unmaskedValue.length === 11) {
      isValid = isValidCPF(unmaskedValue);
    } else if (unmaskedValue.length === 14) {
      isValid = isValidCNPJ(unmaskedValue);
    } else {
      isValid = false;
    }

    if (!isValid) {
      setError(`${type}`, {
        type: "manual",
        message: "Documento Inválido",
      });
    } else {
      clearErrors(`${type}`);
    }
  };

  const onSubmit = async (data) => {
    setData(data);
    setActiveStep(2);
  };

  const onClickStep = (step) => {
    setActiveStep(step);
  };

  const handleClickSendCharge = async () => {
    setIsSubmitting(true);
    setErrorSubmit(null);
    try {
      const blob = await handleClickDownloadPDF("charge");
      const dataForm = data;
      dataForm.price = total;
      const formData = new FormData();
      formData.append("file", blob, "cobranca.pdf");
      formData.append("data", JSON.stringify(dataForm));

      const response = await axios.post(CHARGES.SEND, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setActiveStep(3);
    } catch (error) {
      setErrorSubmit("Erro ao tentar enviar cobrança");
    } finally {
      setIsSubmitting(false);
    }
  };

  const cleanForm = () => {
    setActiveStep(1);
    setData(null);
    setWhatsappEmailCheckbox(false);
    setPhysicalCheckbox(false);
    reset();
  };

  return (
    <div className="bg-white px-4 py-4 rounded-md shadow-md">
      {activeStep === 1 && (
        <form className="mb-10" onSubmit={handleSubmit(onSubmit)}>
          <TitleStripe className="mb-4">Dados da empresa credora</TitleStripe>
          <div className="mb-4 flex flex-col md:flex-row  gap-4">
            <div className="flex-1">
              <Input
                type="text"
                label="Empresa *"
                name="creditorCompany"
                required={true}
                register={register}
                error={errors?.creditorCompany && "Este campo é obrigatório"}
              />
            </div>
            <div className="flex-1">
              <CNPJInput
                type="text"
                label="CNPJ *"
                name="creditorDocument"
                required={true}
                register={register}
                error={errors?.creditorDocument?.message}
                value={watch("creditorDocument") || ""}
                onBlur={(e) => checkDocument(e, "creditorDocument")}
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="flex flex-col md:flex-row gap-4">
              <Input
                type="date"
                label="Data da compra *"
                name="buyDate"
                required={true}
                register={register}
                className="flex-1"
              />
              <Input
                type="date"
                label="Data de vencimento *"
                name="dueDate"
                required={true}
                register={register}
                className="flex-1"
              />
            </div>
          </div>
          <div className="mb-4">
            <div className="flex flex-col md:flex-row gap-4 items-start">
              <div className="flex-1 w-full">
                <Input
                  type="text"
                  label="Natureza do débito *"
                  name="debtNature"
                  required={true}
                  register={register}
                />
                <small>
                  Ex: Duplicata, Aluguel, Mensalidade escolar, cheques...
                </small>
              </div>
              <div className="flex-1">
                <Input
                  type="number"
                  label="Valor do débito *"
                  name="debtValue"
                  required={true}
                  register={register}
                  step="0.01"
                  error={errors?.debtValue && "Este campo é obrigatório"}
                />
              </div>
            </div>
            <div className="mt-2 mb-4 flex flex-col md:flex-row gap-4">
              <div className="flex-1">
                <Input
                  type="email"
                  label="E-mail para contato *"
                  name="creditorEmail"
                  required={true}
                  register={register}
                  error={errors?.creditorEmail && "Este campo é obrigatório"}
                />
              </div>
              <div className="flex-1 w-full">
                <PhoneInput
                  type="text"
                  label="Telefone para contato *"
                  name="creditorPhoneNumber"
                  required={true}
                  register={register}
                  error={
                    errors?.creditorPhoneNumber && "Este campo é obrigatório"
                  }
                  value={watch("creditorPhoneNumber") || ""}
                />
              </div>
            </div>
          </div>
          <TitleStripe className="mb-4">Dados do devedor</TitleStripe>
          <div className="mb-4 flex flex-col items-start md:flex-row gap-4">
            <div className="w-full md:w-40">
              <SelectInput register={register} label="Tipo" name="type">
                <option value="FISICA">Física</option>
                <option value="JURIDICA">Jurídica</option>
              </SelectInput>
            </div>
            {watch("type") === "FISICA" ? (
              <>
                <div className="flex-1">
                  <Input
                    type="text"
                    label="Nome *"
                    name="debtorName"
                    required={true}
                    register={register}
                    error={errors?.debtorName && "Este campo é obrigatório"}
                  />
                </div>
                <div className="flex-1 w-full">
                  <CPFInput
                    type="text"
                    label="Cpf *"
                    name="debtorDocument"
                    required={true}
                    register={register}
                    error={errors?.debtorDocument && "Este campo é obrigatório"}
                    value={watch("debtorDocument") || ""}
                    onBlur={(e) => checkDocument(e, "debtorDocument")}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="flex-1">
                  <Input
                    type="text"
                    label="Razão Social *"
                    name="debtorName"
                    required={true}
                    register={register}
                    error={
                      errors?.debtorName?.message && "Este campo é obrigatório"
                    }
                  />
                </div>
                <div className="flex-1">
                  <CNPJInput
                    type="text"
                    label="CNPJ *"
                    name="debtorDocument"
                    required={true}
                    register={register}
                    error={
                      errors?.debtorDocument?.message &&
                      "Este campo é obrigatório"
                    }
                    value={watch("debtorDocument") || ""}
                    onBlur={(e) => checkDocument(e, "debtorDocument")}
                  />
                </div>
              </>
            )}
          </div>
          <div className="mb-4 flex flex-col md:flex-row gap-4">
            <div className="flex-1">
              <Input
                type="email"
                label="E-mail *"
                name="debtorEmail"
                required={true}
                register={register}
                error={errors?.debtorEmail && "Este campo é obrigatório"}
              />
            </div>
            <div className="flex-1">
              <PhoneInput
                type="text"
                label="Telefone *"
                name="debtorPhoneNumber"
                required={true}
                register={register}
                error={errors?.debtorPhoneNumber && "Este campo é obrigatório"}
                value={watch("debtorPhoneNumber") || ""}
              />
            </div>
          </div>

          <div className="mb-4 flex gap-2">
            <Input
              type="text"
              label="CEP *"
              name="debtorAddressPostalCode"
              required={true}
              register={register}
              className="flex-1"
            />
            <Button
              onClick={onClickSearchCEP}
              className="flex align-center whitespace-nowrap primary"
              style={{ width: "220px", paddingTop: "4px" }}
              isProcessing={loadingCEP}
              processingSpinner={
                <AiOutlineLoading className="h-6 w-6 animate-spin" />
              }
            >
              Buscar CEP
            </Button>
          </div>
          <div className="mb-4">
            <Input
              type="text"
              label="Endereço *"
              name="debtorAddress"
              required={true}
              register={register}
            />
          </div>
          <div className="mb-4">
            <div className="flex gap-4 flex-col md:flex-row">
              <Input
                type="text"
                label="Número *"
                name="debtorAddressNumber"
                required={true}
                register={register}
                className="flex-1"
              />
              <Input
                type="text"
                label="Bairro *"
                name="debtorAddressNeighborhood"
                required={true}
                register={register}
                className="flex-1"
              />
              <Input
                type="text"
                label="Cidade *"
                name="debtorAddressCity"
                required={true}
                register={register}
                className="flex-1"
              />
            </div>
          </div>
          <div className="py-3 px-2 rounded-md bg-blue-100 mb-3">
            <h2 className="title-syncx mb-4">Método de envio</h2>
            <div className="flex max-w-md flex-col gap-4" id="checkbox">
              <div className="flex items-center gap-2">
                <Checkbox
                  id="whtasappEmailCheckbox"
                  checked={whatsappEmailCheckbox}
                  onChange={(e) => setWhatsappEmailCheckbox(e.target.checked)}
                />
                <Label htmlFor="whtasappEmailCheckbox" className="flex">
                  Whatsapp + Email - R$9,90
                </Label>
              </div>
              <div className="flex gap-2">
                <div className="flex h-5 items-center">
                  <Checkbox
                    id="physicalCheckbox"
                    checked={physicalCheckbox}
                    onChange={(e) => setPhysicalCheckbox(e.target.checked)}
                  />
                </div>
                <div className="flex flex-col">
                  <Label htmlFor="physicalCheckbox">
                    Notificação Física - R$4,90
                  </Label>
                </div>
              </div>
            </div>
          </div>

          {user?.Company?.typePayment === "PRE" && (
            <div className="flex justify-end mb-4 text-lg">
              Seu saldo:{" "}
              <span className="font-semibold">
                &nbsp;{formatToReal(user?.Company?.credit || 0)}
              </span>
            </div>
          )}

          <div className="flex justify-end mb-4 text-lg">
            Total para envio:{" "}
            <span className="font-semibold">&nbsp;{formatToReal(total)}</span>
          </div>

          <Button
            type="submit"
            className="w-full primary"
            disabled={
              user?.Company?.typePayment === "PRE" &&
              total > user?.Company?.credit
            }
          >
            Próximo
          </Button>
        </form>
      )}

      {activeStep === 2 && (
        <>
          <div className="bg-gray-100 p-5  overflow-x-scroll">
            <div className="text-center font-semibold underline">
              Visualizar prévia da cobrança
            </div>
            <div
              className="mx-auto mt-5 border border-gray-200 rounded-md shadow-md bg-white overflow-hidden"
              style={{ width: "800px" }}
            >
              <div ref={componentRef} className="p-5 bg-white">
                <div className="flex justify-end mb-4">
                  <img
                    className="w-40"
                    src="/assets/logo_acesso_cobrancas.png"
                  />
                </div>
                <h1 className="text-center font-semibold text-xl mb-10">
                  Carta de Comunicado Extrajudicial
                </h1>
                <div className="mb-4">
                  {watch("debtorAddressCity") || "São Paulo"},{" "}
                  {moment(new Date())
                    .format("DD MMMM yyyy")
                    ?.replaceAll(" ", " de ")}
                  .
                </div>
                <div>Prezado(a) Sr(a),</div>
                <div className="font-semibold">
                  {watch("debtorName") || "NOME_DO_DEVEDOR"}
                </div>
                <div className="font-semibold">
                  {watch("type") === "FISICA" ? "CPF" : "CNPJ"}:{" "}
                  {watch("debtorDocument") || "CPF_DO_DEVEDOR"}
                </div>

                <div className="mt-5 mb-4">
                  Vimos pela presente informar a V.Sa. que se encontra em
                  aberto, junto à empresa credora abaixo, um débito não quitado,
                  a saber:
                </div>

                <div className="flex items-center gap-16 mb-2">
                  <div className="flex-1">
                    <span className="font-semibold">Empresa: </span>
                    {watch("creditorCompany") || "NOME_DA_EMPRESA"}
                  </div>
                  <div className="flex-1">
                    <span className="font-semibold">CNPJ: </span>
                    {watch("creditorDocument") || "CNPJ_DA_EMPRESA"}
                  </div>
                </div>
                <div className="flex items-center gap-16 mb-2">
                  <div className="flex-1">
                    <span className="font-semibold">Data da compra: </span>
                    {watch("buyDate")
                      ? moment(watch("buyDate")).format("DD/MM/yyyy")
                      : "DATA_DA_COMPRA"}
                  </div>
                  <div className="flex-1">
                    <span className="font-semibold">Data de vencimento: </span>
                    {watch("dueDate")
                      ? moment(watch("dueDate")).format("DD/MM/yyyy")
                      : "DATA_DE_VENC"}
                  </div>
                </div>
                <div className="flex items-center gap-16 mb-2">
                  <div className="flex-1">
                    <span className="font-semibold">Natureza do débito: </span>
                    {watch("debtNature") || "NAT_DO_DEBITO"}
                  </div>
                  <div className="flex-1">
                    <span className="font-semibold">Valor do débito: </span>
                    {watch("debtValue")
                      ? formatToReal(watch("debtValue"))
                      : "-"}
                  </div>
                </div>

                <hr className="mt-4" />

                <p className="mt-4">
                  Tendo em vista que até o presente momento não foi acusado o
                  recebimento da referida referido débito, solicitamos a V.Sa a
                  quitação desta ou seu comparecimento na empresa credora, a fim
                  de regularizar tal pendência no prazo máximo de 10 dias
                  corridos
                </p>

                <p className="mt-4">
                  Informamos, ainda, que a não regularização da referida
                  pendência no prazo acima estabelecido ensejará tomada da(s)
                  seguinte(s) providência(s) legal(is).
                </p>

                <p className="mt-4">
                  - Encaminhamento de vosso nome aos bancos de dados de proteção
                  ao crédito SCPC Boa Vista Serviços, SPC Brasil, Serasa
                  Experian e Quod. <br />
                  - Protesto de Título <br />- Entre os itens Protesto de titulo
                  e Propositura da competente ação judicial, você podo inserir ;
                  Cobrança Extrajudicial promovida por escritório de advocacia
                  especializado com cobrança de 10% de honorários.
                </p>
                <p className="mt-4">
                  Desta forma, a fim de se evitar tal situação, reiteramos o
                  pedido no sentido de que V.Sa. promova a quitação da referida
                  dívida ou o seu comparecimento no estabelecimento acima
                  descrito, no prazo máximo de 10 dias, a fim de que seja
                  resolvida tal pendência.
                </p>

                <div className="mt-4 bg-gray-100 p-2">
                  <p>
                    Informamos ainda que V.Sa encontra-se constitúido em mora
                    para todos os efeitos legais a partir do recebimento da
                    presente notificação, conforme os termos do artigo 397 da
                    lei 10.406/02 - Código Civil Brasileiro
                  </p>

                  <p className="mt-4">
                    Solicitamos a V.Sa desconsiderar a presente, caso o referido
                    débito já tenha sido quitado.
                  </p>

                  <div className="mt-5">Respeitosamente,</div>
                  <div>
                    {watch("creditorCompany") || "NOME_DA_EMPRESA_AQUI"}
                  </div>
                </div>
              </div>
              <div className="mt-5 flex justify-between p-5">
                <Button className="bg-gray-600" onClick={() => onClickStep(1)}>
                  Voltar para edição
                </Button>
                <Button
                  onClick={handleClickSendCharge}
                  isProcessing={isSubmitting}
                  processingSpinner={
                    <AiOutlineLoading className="h-6 w-6 animate-spin" />
                  }
                  disabled={isSubmitting}
                >
                  Enviar cobrança
                </Button>
              </div>
              {errorSubmit && (
                <Alert className="mt-4" color={`failure`}>
                  <span className="font-medium">{errorSubmit}</span>
                </Alert>
              )}
            </div>
          </div>
        </>
      )}

      {activeStep === 3 && (
        <div className="h-72 rounded-md flex justify-center items-center">
          <div className="bg-white rounded-md p-5 flex justify-center flex-col items-center gap-2">
            <FaCheckCircle color="green" fontSize={30} />
            <h1 className="font-semibold text-lg">
              Sua notificação foi enviada com sucesso!
            </h1>
            <div className="flex gap-2">
              <Button onClick={cleanForm} className="font-semibold">
                Enviar outra notificação
              </Button>
              <Link
                className="bg-gray-500 text-white py-2 text-sm font-semibold px-4 flex items-center justify-center rounded-md"
                to="/reports/charges"
              >
                Visualizar todas
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExtrajudicialNotificationPage;
