import { Alert, Badge, Button, Card } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import axios from "../../../config/axiosInstance";
import { QUERIES_VEICULAR } from "../../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "../HeaderQuery";
import usePDF from "../../../hooks/usePDF";
import TitleStripe from "../../common/TitleStripe";
import CardQueryDescription from "../../common/CardQueryDescription";
import SliderToPreview from "../../common/SliderToPreview";
import Loader from "../../common/Loader";
import FooterQuery from "../FooterQuery";
import NoOcurrence from "../../common/query/NoOcurrence";
import QueryVehicleInput from "../../inputs/QueryVehicleInput";

const DadosHistoricoDeGravameQuery = ({
  query,
  selectedPrice,
  dataFromLocalStorage = null,
  hasHeaderPDF = "true",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    data
  );

  useEffect(() => {
    if (dataFromLocalStorage) {
      setData(dataFromLocalStorage?.dadosVeicular);
      setDocument(dataFromLocalStorage?.resumoRetorno?.document);
    }
  }, []);

  const fetchInfo = async (raw) => {
    return await axios(`${QUERIES_VEICULAR.GET_VEICULAR}/${query?.id}/${info}`);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);
    let isValid = true;

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados?.data?.dadosVeiculo || null);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setData(null);
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={["Histórico de gravame"]}
        price={selectedPrice}
        imageBanner="/assets/bg_banner_car.jpg"
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <QueryVehicleInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="PLACA"
            />
            <div>
              {!errorQuery && data && (
                <Button
                  className={`w-fit shadow-lg secondary ${
                    data && !isLoading ? "block" : "hidden"
                  }`}
                  isProcessing={pdfURL === ""}
                  disabled={pdfURL === ""}
                  onClick={() => handleClickDownloadPDF()}
                >
                  Salvar como PDF
                </Button>
              )}
            </div>
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`overflow-x-scroll ${
              data || isLoading ? "block" : "hidden"
            }`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="relative overflow-hidden p-5 card-pdf">
                  <HeaderQuery title={query?.name} type="vehicle" />

                  <TitleStripe>Histórico de gravame</TitleStripe>

                  {data?.gravame?.ocorrencias?.length > 0 ? (
                    <ul className="flex flex-col gap-2">
                      {data?.gravame?.ocorrencias?.map((grav, index) => (
                        <li key={index}>
                          <div className="flex gap-16 border border-gray-100 p-2 bg-gray-100">
                            <div className="text-sm flex-1">
                              <div className="mb-3">
                                <span className="font-semibold">
                                  Status Gravame:{" "}
                                </span>
                                {grav.statusGravame || "Sem info."}
                              </div>
                              <div className="mb-3">
                                <span className="font-semibold">
                                  Status Veículo:{" "}
                                </span>
                                {grav.statusVeiculo || "Sem info."}
                              </div>
                              <div className="mb-3">
                                <span className="font-semibold">
                                  Data Atualização Status:{" "}
                                </span>
                                {`${grav.dataAtualizacaoStatus}` ||
                                  "Indisponível"}
                              </div>
                              <div className="mb-3">
                                <span className="font-semibold">RENAVAM: </span>
                                {grav.renavam || "Indisponível"}
                              </div>
                              <div className="mb-3">
                                <span className="font-semibold">UF: </span>
                                {grav.uf || "Indisponível"}
                              </div>
                              <div className="mb-3">
                                <span className="font-semibold">
                                  Doc. Agente:{" "}
                                </span>
                                {grav.docAgente || "Indisponível"}
                              </div>
                              <div className="mb-3">
                                <span className="font-semibold">
                                  Número Gravame:{" "}
                                </span>
                                {grav.numeroGravame || "Indisponível"}
                              </div>
                              <div className="mb-3">
                                <span className="font-semibold">
                                  Número Contrato:{" "}
                                </span>
                                {grav.numeroContrato || "Indisponível"}
                              </div>
                            </div>
                            <div className="text-sm flex-1">
                              <div className="mb-3">
                                <span className="font-semibold">
                                  Documento:{" "}
                                </span>
                                {document || "Indisponível"}
                              </div>
                              <div className="mb-3">
                                <span className="font-semibold">Placa: </span>
                                {document}
                              </div>
                              <div className="mb-3">
                                <span className="font-semibold">
                                  UF Licenciamento:{" "}
                                </span>
                                {grav.ufLicenciamento || "Indisponível"}
                              </div>
                              <div className="mb-3">
                                <span className="font-semibold">
                                  Doc. Financiado:{" "}
                                </span>
                                {grav.docFinanciado || "Indisponível"}
                              </div>
                              <div className="mb-3">
                                <span className="font-semibold">
                                  Nome Financiado:{" "}
                                </span>
                                {grav.nomeFinanciado || "Indisponível"}
                              </div>
                              <div className="mb-3">
                                <span className="font-semibold">
                                  Nome Agente:{" "}
                                </span>
                                {grav.nomeAgente || "Indisponível"}
                              </div>
                              <div className="mb-3">
                                <span className="font-semibold">
                                  Data Contrato:{" "}
                                </span>
                                {grav.dataContrato || "Indisponível"}
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <NoOcurrence />
                  )}

                  <FooterQuery hasHeader={hasHeaderPDF} />
                </Card>
              </>
            ) : (
              <Loader words={["Histórico de gravame"]} />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DadosHistoricoDeGravameQuery;
