import { Alert, Badge, Button, Card } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import axios from "../../../config/axiosInstance";
import { QUERIES_VEICULAR } from "../../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "../HeaderQuery";
import usePDF from "../../../hooks/usePDF";
import TitleStripe from "../../common/TitleStripe";
import CardQueryDescription from "../../common/CardQueryDescription";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import SliderToPreview from "../../common/SliderToPreview";
import Loader from "../../common/Loader";
import FooterQuery from "../FooterQuery";
import NoOcurrence from "../../common/query/NoOcurrence";
import QueryVehicleInput from "../../inputs/QueryVehicleInput";

const IndicioDeSinistro01Query = ({
  query,
  selectedPrice,
  dataFromLocalStorage = null,
  hasHeaderPDF = "true",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    data
  );

  useEffect(() => {
    if (dataFromLocalStorage) {
      setData(dataFromLocalStorage?.dadosVeicular);
      setDocument(dataFromLocalStorage?.resumoRetorno?.document);
    }
  }, []);

  const fetchInfo = async (raw) => {
    return await axios(`${QUERIES_VEICULAR.GET_VEICULAR}/${query?.id}/${info}`);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);
    let isValid = true;

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados?.data?.dadosVeiculo || null);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setData(null);
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={["Indício de sinistro"]}
        price={selectedPrice}
        imageBanner="/assets/bg_banner_car.jpg"
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <QueryVehicleInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="PLACA"
            />
            <div>
              {!errorQuery && data && (
                <Button
                  className={`w-fit shadow-lg secondary ${
                    data && !isLoading ? "block" : "hidden"
                  }`}
                  isProcessing={pdfURL === ""}
                  disabled={pdfURL === ""}
                  onClick={() => handleClickDownloadPDF()}
                >
                  Salvar como PDF
                </Button>
              )}
            </div>
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`overflow-x-scroll ${
              data || isLoading ? "block" : "hidden"
            }`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="relative overflow-hidden p-5 card-pdf">
                  <HeaderQuery title={query?.name} type="vehicle" />
                  {data?.sinistroConjugado?.statusRetorno?.codigo === "1" && (
                    <div>
                      <TitleStripe>Dados de indícios de sinistro</TitleStripe>

                      {data?.sinistroConjugado?.ocorrencias?.length === 0 ? (
                        <Card className={`bg-green-200 mt-4`}>
                          <div className="flex gap-2 items-center font-semibold">
                            <div>
                              <IoMdCheckmarkCircleOutline fontSize={20} />
                            </div>
                            <div>
                              Nenhum registro de indício de sinistro encontrado!
                            </div>
                          </div>
                        </Card>
                      ) : (
                        <>
                          {data?.sinistroConjugado?.ocorrencias?.map(
                            (sinistroCompleto, indexL) => (
                              <div key={indexL}>
                                <div className="title-vehicle mt-3">
                                  Dados do sinistro -{" "}
                                  {sinistroCompleto.nomeBase}
                                </div>
                                {sinistroCompleto?.existeOcorrencia === "1" ? (
                                  <div className="bg-red-100 rounded p-3 mt-3">
                                    {sinistroCompleto?.descricaoOcorrencia}
                                  </div>
                                ) : (
                                  <NoOcurrence />
                                )}
                              </div>
                            )
                          )}
                        </>
                      )}
                    </div>
                  )}
                  <FooterQuery hasHeader={hasHeaderPDF} />
                </Card>
              </>
            ) : (
              <Loader words={["Indício de sinistro"]} />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default IndicioDeSinistro01Query;
