import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Spinner,
  Table,
} from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import axios from "../../../config/axiosInstance";
import { QUERIES } from "../../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "../HeaderQuery";
import usePDF from "../../../hooks/usePDF";
import TitleStripe from "../../common/TitleStripe";
import CardQueryDescription from "../../common/CardQueryDescription";
import QueryInput from "../../inputs/QueryInput";
import SliderToPreview from "../../common/SliderToPreview";
import FooterQuery from "../FooterQuery";
import NoOcurrence from "../../common/query/NoOcurrence";
import QueryLoading from "../../common/QueryLoading";

const CndtCertidaoNacionalDeDebitosTrabalhistasQuery = ({
  query,
  selectedPrice,
  dataFromLocalStorage = null,
  hasHeaderPDF = "true",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const [dataCadastral, setDataCadastral] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    data
  );

  useEffect(() => {
    if (dataFromLocalStorage) {
      setData(dataFromLocalStorage?.dadosVeicular);
      setDataCadastral(dataFromLocalStorage?.credCadastro);
      setDocument(dataFromLocalStorage?.resumoRetorno?.document);
    }
  }, []);

  const fetchInfo = async (raw) => {
    return await axios(`${QUERIES.GET_CNDT}/${info}`);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);
    let isValid = true;

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setDataCadastral(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados?.data?.dadosVeiculo || null);
        setDataCadastral(dados?.data?.credCadastro || null);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setData(null);
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={["Certidão Negativa de trabalho"]}
        price={selectedPrice}
        imageBanner="/assets/bg_banner_car.jpg"
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CPF/CNPJ"
            />
            <div>
              {!errorQuery && data && (
                <Button
                  className={`w-fit shadow-lg secondary ${
                    data && !isLoading ? "block" : "hidden"
                  }`}
                  isProcessing={pdfURL === ""}
                  disabled={pdfURL === ""}
                  onClick={() => handleClickDownloadPDF()}
                >
                  Salvar como PDF
                </Button>
              )}
            </div>
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`overflow-x-scroll ${
              data || isLoading ? "block" : "hidden"
            }`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="relative overflow-hidden p-5 card-pdf">
                  <HeaderQuery title={query?.name} type="vehicle" />

                  {dataCadastral?.cndt?.statusRetorno?.codigo === "1" && (
                    <div>
                      <TitleStripe>Certidão Negativa de Trabalho</TitleStripe>

                      <div className="mt-3">
                        <div className="text-sm grid grid-cols-2 gap-2">
                          <div>
                            <span className="font-semibold">
                              Proprietário Atual:{" "}
                            </span>
                            {dataCadastral?.cndt?.nome || "Sem info."}
                          </div>
                          <div>
                            <span className="font-semibold">Documento: </span>
                            {dataCadastral?.cndt?.documento || "Sem info."}
                          </div>
                          <div>
                            <span className="font-semibold">
                              Data expedição:{" "}
                            </span>
                            {dataCadastral?.cndt?.expedicao || "Indisponível"}
                          </div>
                          <div>
                            <span className="font-semibold">
                              Data validade:{" "}
                            </span>
                            {dataCadastral?.cndt?.validade || "Indisponível"}
                          </div>
                          <div>
                            <span className="font-semibold">
                              Data emissão:{" "}
                            </span>
                            {dataCadastral?.cndt?.emissao || "Indisponível"}
                          </div>
                          <div>
                            <span className="font-semibold">
                              Cod. Certidão:{" "}
                            </span>
                            {dataCadastral?.cndt?.codCertidao || "Indisponível"}
                          </div>
                        </div>
                      </div>

                      {dataCadastral?.cndt?.certidao?.IMAGE_FILE
                        ?.FILE_BASE64 && (
                        <div className="bg-gray-100 p-4 mt-6">
                          <img
                            className="max-w-96 mx-auto"
                            src={`data:image/png;base64, ${dataCadastral?.cndt?.certidao?.IMAGE_FILE?.FILE_BASE64}`}
                          />
                        </div>
                      )}
                    </div>
                  )}

                  {dataCadastral?.emails?.statusRetorno?.codigo === "1" && (
                    <div>
                      <TitleStripe>E-mails de contato</TitleStripe>

                      <>
                        {dataCadastral?.emails?.ocorrencias?.length === 0 ? (
                          <NoOcurrence />
                        ) : (
                          <div className="overflow-hidden rounded-lg border border-gray-300 mt-3">
                            <Table striped className="text-sm">
                              <Table.Head>
                                <Table.HeadCell className="bg-gray-300 p-1">
                                  Email
                                </Table.HeadCell>
                              </Table.Head>
                              <Table.Body className="divide-y">
                                {dataCadastral?.emails?.ocorrencias?.map(
                                  (email, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white p-1">
                                        {email || "-"}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </Table.Body>
                            </Table>
                          </div>
                        )}
                      </>
                    </div>
                  )}

                  {dataCadastral?.telefoneCelular?.statusRetorno?.codigo ===
                    "1" && (
                    <div>
                      <TitleStripe>Números de celulares</TitleStripe>
                      <>
                        {dataCadastral?.telefoneCelular?.ocorrencias?.length ===
                        0 ? (
                          <NoOcurrence />
                        ) : (
                          <div className="overflow-hidden rounded-lg border border-gray-300 mt-3">
                            <Table striped className="text-sm">
                              <Table.Head>
                                <Table.HeadCell className="bg-gray-300 p-1">
                                  Nome
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  DDD
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Telefone
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Tipo de pessoa
                                </Table.HeadCell>
                              </Table.Head>
                              <Table.Body className="divide-y">
                                {dataCadastral?.telefoneCelular?.ocorrencias?.map(
                                  (telefone, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white p-1">
                                        {telefone.nome}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {telefone.ddd}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {telefone.telefone}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {telefone.tipoPessoa}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </Table.Body>
                            </Table>
                          </div>
                        )}
                      </>
                    </div>
                  )}

                  {dataCadastral?.telefoneFixo?.statusRetorno?.codigo ===
                    "1" && (
                    <div>
                      <TitleStripe>Números de telefone fixo</TitleStripe>
                      <>
                        {dataCadastral?.telefoneFixo?.ocorrencias?.length ===
                        0 ? (
                          <NoOcurrence />
                        ) : (
                          <div className="overflow-hidden rounded-lg border border-gray-300 mt-3">
                            <Table striped className="text-sm">
                              <Table.Head>
                                <Table.HeadCell className="bg-gray-300 p-1">
                                  Nome
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  DDD
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Telefone
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Tipo de pessoa
                                </Table.HeadCell>
                              </Table.Head>
                              <Table.Body className="divide-y">
                                {dataCadastral?.telefoneFixo?.ocorrencias?.map(
                                  (telefone, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white p-1">
                                        {telefone.nome}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {telefone.ddd}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {telefone.telefone}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {telefone.tipoPessoa}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </Table.Body>
                            </Table>
                          </div>
                        )}
                      </>
                    </div>
                  )}

                  {dataCadastral?.parentes?.statusRetorno?.codigo === "1" && (
                    <div>
                      <TitleStripe>Parentes</TitleStripe>

                      <>
                        {dataCadastral?.parentes?.ocorrencias?.length === 0 ? (
                          <NoOcurrence />
                        ) : (
                          <div className="overflow-hidden rounded-lg border border-gray-300 mt-3">
                            <Table striped className="text-sm">
                              <Table.Head>
                                <Table.HeadCell className="bg-gray-300 p-1">
                                  Nome
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Documento
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Endereço
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Bairro
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Cidade
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Data de nascimento
                                </Table.HeadCell>
                              </Table.Head>
                              <Table.Body className="divide-y">
                                {dataCadastral?.parentes?.ocorrencias?.map(
                                  (pessoa, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white p-1">
                                        {pessoa.nome || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {pessoa.documento || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {pessoa.endereco || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {pessoa.bairro || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {pessoa.cidade || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {pessoa.nascimento || "-"}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </Table.Body>
                            </Table>
                          </div>
                        )}
                      </>
                    </div>
                  )}

                  {dataCadastral?.enderecos?.statusRetorno?.codigo === "1" && (
                    <div>
                      <TitleStripe>Endereços</TitleStripe>
                      <>
                        {dataCadastral?.enderecos?.ocorrencias?.length === 0 ? (
                          <NoOcurrence />
                        ) : (
                          <div className="overflow-hidden rounded-lg border border-gray-300 mt-3">
                            <Table striped className="text-sm">
                              <Table.Head>
                                <Table.HeadCell className="bg-gray-300 p-1">
                                  Endereço
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Bairro
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  Cidade
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  UF
                                </Table.HeadCell>
                                <Table.HeadCell className="text-center bg-gray-300 p-1">
                                  CEP
                                </Table.HeadCell>
                              </Table.Head>
                              <Table.Body className="divide-y">
                                {dataCadastral?.enderecos?.ocorrencias?.map(
                                  (endereco, index) => (
                                    <Table.Row
                                      key={index}
                                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    >
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white p-1">
                                        {endereco.endereco || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {endereco.bairro || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {endereco.cidade || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {endereco.uf || "-"}
                                      </Table.Cell>
                                      <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-1">
                                        {endereco.cep || "-"}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                              </Table.Body>
                            </Table>
                          </div>
                        )}
                      </>
                    </div>
                  )}

                  <FooterQuery hasHeader={hasHeaderPDF} />
                </Card>
              </>
            ) : (
              <QueryLoading />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CndtCertidaoNacionalDeDebitosTrabalhistasQuery;
