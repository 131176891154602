import React, { useState } from "react";
import { Badge, Card, Table } from "flowbite-react";
import ExtrajudicialNotificationPage from "../../pages/ExtrajudicialNotificationPage";

const ChargesTable = () => {
  const [selectedQuery, setSelectedQuery] = useState(0);
  const listForms = [];

  return (
    <div className="flex flex-col lg:flex-row gap-2">
      <div className="p-4 flex flex-col gap-2 w-full md:w-1/4">
        <h1 className="title-syncx mb-2">Alertas e Registros</h1>
        <Card
          className={`card-query ${selectedQuery === 0 && "active"}`}
          onClick={() => setSelectedQuery(0)}
        >
          Notificação Extra Judicial
        </Card>
      </div>
      <div className="w-full md:w-3/4">
        {selectedQuery === 0 && <ExtrajudicialNotificationPage />}
      </div>
    </div>
  );
};

export default ChargesTable;
