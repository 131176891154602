import { Alert, Button, Card, Spinner } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { isValidCNPJ, maskCEP } from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import TitleStripe from "../common/TitleStripe";
import CardQueryDescription from "../common/CardQueryDescription";
import QueryInput from "../inputs/QueryInput";
import SliderToPreview from "../common/SliderToPreview";
import QueryLoading from "../common/QueryLoading";
import AvisoCard from "../common/query/AvisoCard";
import PendenciasFinanceiras from "../common/query/PendenciasFinanceiras";
import NoOcurrence from "../common/query/NoOcurrence";
import Protestos from "../common/query/Protestos";
import ProtestosDirCartorio from "../common/query/ProtestosDirCartorio";
import ChequesSustados from "../common/query/ChequesSustados";
import ChequesSemFundos from "../common/query/ChequesSemFundos";
import HistoricoConsultas from "../common/query/HistoricoConsultas";
import { EvaluationScorePJ } from "../common/query/EvaluationScorePJ";
import FooterQuery from "./FooterQuery";
import ReactApexChart from "react-apexcharts";

const PositivoPlusCNPJQuery = ({
  query,
  selectedPrice,
  dataFromLocalStorage = null,
  hasHeaderPDF = "true",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [errorData, setErrorData] = useState("");
  const [data, setData] = useState(null);
  const [isLoadingProtestos, setIsLoadingProtestos] = useState(false);
  const [infoProtestos, setInfoProtestos] = useState(null);
  const [isDoneRequest, setIsDoneRequest] = useState(false);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    isDoneRequest
  );

  useEffect(() => {
    if (dataFromLocalStorage) {
      setData(dataFromLocalStorage.consultaCredito);
      setDocument(dataFromLocalStorage?.consultaCredito?.dadosCadastrais?.cnpj);
      setInfoProtestos(dataFromLocalStorage?.protestosDirCartorio);
      setIsDoneRequest(true);
    }
  }, []);

  const fetchDefineRisco = async (raw) => {
    return await axios(
      `${QUERIES.GET_DEFINE_RISCO}/${info.replace(/\D/g, "")}`
    );
  };

  const fetchInfoProtestos = async (raw) => {
    return await axios(
      `${QUERIES.GET_PROTESTO_NACIONAL}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCNPJ(unmaskedValue)) {
      setError("CNPJ inválido!");
      isValid = false;
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorData("");
        setInfoProtestos(null);
        setIsLoadingProtestos(true);
        setIsDoneRequest(false);

        const { data: dados } = await fetchDefineRisco();
        setData(dados.data.consultaCredito);
        setIsLoading(false);

        try {
          const protestos = await fetchInfoProtestos();
          if (protestos.data.status !== "success")
            throw new Error("erro ao buscar");
          setInfoProtestos(protestos.data.data);
        } catch (protestosError) {
          console.error("Erro ao buscar protestos:", protestosError);
          setInfoProtestos([]);
        } finally {
          setIsLoadingProtestos(false);
        }
      } catch (error) {
        setErrorData(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
        setIsLoading(false);
      } finally {
        setIsDoneRequest(true);
      }
    } else {
      setData(null);
    }
  };

  return (
    <div>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>

      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Dados Cadastrais",
          "Score",
          "Negativações",
          "Protestos",
          "Cheques sem fundos",
          "Cheques sustados",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 mt-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CNPJ"
            />
            <div>
              {!errorData && data && (
                <Button
                  className={`w-fit shadow-lg secondary ${
                    data && !isLoading ? "block" : "hidden"
                  }`}
                  isProcessing={pdfURL === ""}
                  disabled={pdfURL === ""}
                  onClick={() => handleClickDownloadPDF()}
                >
                  Salvar como PDF
                </Button>
              )}
            </div>
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorData ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorData}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`overflow-x-scroll ${
              data || isLoading ? "block" : "hidden"
            }`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="mt-0 p-4 card-pdf relative overflow-hidden">
                  <HeaderQuery title={query?.name} hasHeader={hasHeaderPDF} />

                  <TitleStripe>Dados Cadastrais</TitleStripe>
                  <div className="flex flex-row gap-16">
                    <div className="text-sm flex-1">
                      <div className="mb-3">
                        <span className="font-semibold">Razão Social: </span>
                        {data?.dadosCadastrais?.razaoSocial}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Nome Fantasia: </span>
                        {data?.dadosCadastrais?.nomeFantasia}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Natureza Jurídica:{" "}
                        </span>
                        {data?.dadosCadastrais?.naturezaJuridica}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">CNPJ/MF: </span>
                        {document}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">I.E.: </span>
                        {data?.dadosCadastrais?.inscricaoEstadual}
                      </div>

                      <div className="mb-3">
                        <span className="font-semibold">CNAE Principal: </span>
                        {data?.dadosCadastrais?.ramoAtividade}
                      </div>

                      <div className="mb-3">
                        <span className="font-semibold">Fundação: </span>
                        {data?.dadosCadastrais?.dataFundacao}
                      </div>
                    </div>
                    <div className="text-sm flex-1">
                      <div className="mb-3 flex gap-2 items-center">
                        <span className="font-semibold">Situação: </span>
                        <span
                          className={
                            data?.dadosCadastrais?.situacao?.toLowerCase() ===
                            "ativo"
                              ? "bg-primary text-white px-2 rounded-md"
                              : "bg-red-500 text-white px-2 rounded-md"
                          }
                        >
                          {data?.dadosCadastrais?.situacao}
                        </span>
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Endereço: </span>
                        {data?.dadosCadastrais?.endereco}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Bairro: </span>
                        {data?.dadosCadastrais?.bairro}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Cidade/UF: </span>
                        {data?.dadosCadastrais?.cidade}/
                        {data?.dadosCadastrais?.uf}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">CEP: </span>
                        {maskCEP(data?.dadosCadastrais?.cep)}
                      </div>
                    </div>
                  </div>

                  <TitleStripe>Quadro de avisos</TitleStripe>

                  <div className="container-alerts five-columns">
                    <AvisoCard
                      title="Negativações"
                      hasRestrictions={
                        data?.resumoConsulta?.pendenciasFinanceiras
                          ?.quantidadeTotal != 0
                      }
                      messageError={`${data?.resumoConsulta?.pendenciasFinanceiras?.quantidadeTotal}
                    ocorrências`}
                    />
                    <AvisoCard
                      title="Cheques sem Fundos"
                      hasRestrictions={
                        data?.resumoConsulta?.chequesSemFundo
                          ?.quantidadeTotal != 0
                      }
                      messageError={`${data?.resumoConsulta?.chequesSemFundo?.quantidadeTotal} 
                        ocorrências`}
                    />

                    <AvisoCard
                      title="Cheques Sustados"
                      hasRestrictions={
                        data?.resumoConsulta?.chequesSustados
                          ?.quantidadeTotal != 0
                      }
                      messageError={`${data?.resumoConsulta?.chequesSustados?.quantidadeTotal} ocorrências`}
                    />

                    <AvisoCard
                      title="Protestos"
                      hasRestrictions={
                        data?.resumoConsulta?.protestos?.quantidadeTotal != 0
                      }
                      messageError={`${data?.resumoConsulta?.protestos?.quantidadeTotal} ocorrências`}
                    />

                    {isLoadingProtestos ? (
                      <div className="flex relative flex-col bg-gray-500 rounded-md font-semibold pt-1 pb-6 px-2 text-white shadow-md">
                        <div className="text-left text-sm">
                          Protestos dir. cartório
                        </div>
                        <div className="text-center font-semibold w-fit text-green-800 px-2 rounded-md mx-auto mt-4">
                          <Spinner
                            aria-label="Large spinner example"
                            size="md"
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <AvisoCard
                          title="Protestos dir. Cartório"
                          hasRestrictions={infoProtestos?.length > 0}
                          messageError={`${infoProtestos?.length}
                          ocorrências`}
                        />
                      </>
                    )}
                  </div>
                  <div>
                    <TitleStripe>Avaliação preliminar de crédito</TitleStripe>
                  </div>
                  <EvaluationScorePJ
                    data={data}
                    score={data?.score?.score}
                    probability={+data?.score?.probabilidade * 100}
                    type="CNPJ"
                  />
                  <div>
                    <TitleStripe className="mb-4">Negativações</TitleStripe>

                    {data?.pendenciasFinanceiras?.length > 0 ? (
                      <>
                        <PendenciasFinanceiras
                          quantidadeTotal={
                            data?.resumoConsulta?.pendenciasFinanceiras
                              ?.quantidadeTotal
                          }
                          valorTotal={
                            data?.resumoConsulta?.pendenciasFinanceiras
                              ?.valorTotal
                          }
                          pendenciasFinanceiras={data?.pendenciasFinanceiras}
                        />
                      </>
                    ) : (
                      <NoOcurrence />
                    )}
                  </div>
                  <div>
                    <TitleStripe className="mb-5">Protestos</TitleStripe>
                    {data?.protestos?.length > 0 ? (
                      <Protestos
                        quantidadeTotal={
                          data?.resumoConsulta?.protestos?.quantidadeTotal
                        }
                        valorTotal={data?.resumoConsulta?.protestos?.valorTotal}
                        protestos={data?.protestos}
                      />
                    ) : (
                      <NoOcurrence />
                    )}
                  </div>

                  <div>
                    <TitleStripe className="mb-5">
                      Protestos direto do cartório
                    </TitleStripe>
                    {!isLoadingProtestos ? (
                      <>
                        {infoProtestos?.length > 0 ? (
                          <ProtestosDirCartorio
                            quantidadeTotal={infoProtestos?.length}
                            protestosDirCartorio={infoProtestos}
                          />
                        ) : (
                          <NoOcurrence />
                        )}
                      </>
                    ) : (
                      <div role="status" className="w-full animate-pulse">
                        <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>{" "}
                      </div>
                    )}
                  </div>
                  <div>
                    <TitleStripe className="mb-5">
                      Cheques sem fundo
                    </TitleStripe>
                    {data?.chequesSemFundo?.length > 0 ? (
                      <>
                        <ChequesSemFundos
                          quantidadeTotal={
                            data?.resumoConsulta?.chequesSemFundo
                              ?.quantidadeTotal
                          }
                          chequesSemFundos={data?.chequesSemFundo}
                        />
                      </>
                    ) : (
                      <NoOcurrence />
                    )}
                  </div>
                  <div>
                    <TitleStripe className="mb-5">Cheques sustados</TitleStripe>
                    {data?.chequesSustados?.length > 0 ? (
                      <>
                        <ChequesSustados
                          quantidadeTotal={
                            data?.resumoConsulta?.chequesSustados
                              ?.quantidadeTotal
                          }
                          chequesSustados={data?.chequesSustados}
                        />
                      </>
                    ) : (
                      <NoOcurrence />
                    )}
                  </div>

                  <div>
                    <TitleStripe className="mb-5">
                      Histórico de consultas
                    </TitleStripe>

                    {data?.historicoConsultas?.length > 0 ? (
                      <div className="flex flex-col gap-2">
                        <div className="flex-1">
                          <HistoricoConsultas
                            historicoConsultas={data?.historicoConsultas?.slice(
                              0,
                              5
                            )}
                          />
                        </div>
                        {data?.historicoConsultasResumo
                          ?.paresMesAnoHistoricoConsultas?.length > 0 && (
                          <ReactApexChart
                            className="border border-gray-200 rounded-md mt-3"
                            options={{
                              chart: {
                                height: 150,
                                type: "bar",
                                toolbar: {
                                  show: false, // Remove o menu de ações
                                },
                              },
                              plotOptions: {
                                bar: {
                                  borderRadius: 2,
                                  dataLabels: {
                                    position: "center", // top, center, bottom
                                  },
                                },
                              },
                              dataLabels: {
                                enabled: true,
                                formatter: function (val) {
                                  return val;
                                },
                                offsetY: 0,
                                style: {
                                  fontSize: "12px",
                                  colors: ["white"],
                                },
                              },

                              xaxis: {
                                categories:
                                  data?.historicoConsultasResumo
                                    ?.paresMesAnoHistoricoConsultas,
                                position: "top",
                                axisBorder: {
                                  show: false,
                                },
                                axisTicks: {
                                  show: false,
                                },
                                crosshairs: {
                                  fill: {
                                    type: "gradient",
                                    gradient: {
                                      colorFrom: "#309ADB",
                                      colorTo: "#309ADB",
                                      stops: [0, 100],
                                      opacityFrom: 0.4,
                                      opacityTo: 0.5,
                                    },
                                  },
                                },
                                tooltip: {
                                  enabled: true,
                                },
                              },
                              yaxis: {
                                axisBorder: {
                                  show: false,
                                },
                                axisTicks: {
                                  show: false,
                                },
                                labels: {
                                  show: false,
                                  formatter: function (val) {
                                    return val;
                                  },
                                },
                              },
                              title: {
                                text: "Últimos 12 meses",
                                floating: true,
                                offsetY: 200,
                                align: "center",
                                style: {
                                  color: "#444",
                                },
                              },
                            }}
                            series={[
                              {
                                name: "Qtd. Consultas no período",
                                data: data?.historicoConsultasResumo
                                  ?.totaisHistoricoConsultas,
                              },
                            ]}
                            type="bar"
                            height={220}
                          />
                        )}
                      </div>
                    ) : (
                      <NoOcurrence />
                    )}
                  </div>

                  <FooterQuery hasHeader={hasHeaderPDF} />
                </Card>
              </>
            ) : (
              <QueryLoading />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PositivoPlusCNPJQuery;
