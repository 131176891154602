import React, { useEffect, useState } from "react";
import { Badge, Card } from "flowbite-react";
import { USERS } from "../../config/apiConfig";
import { useQuery } from "react-query";
import axios from "../../config/axiosInstance";
import PositivoPlusCPFQuery from "../queries/PositivoPlusCPFQuery";
import ScrBacenScoreQuery from "../queries/ScrBacenScoreQuery";
import RestritivaPFQuery from "../queries/RestritivaPFQuery";
import DrawerLoading from "../DrawerLoading";
import { AiOutlineFileSearch } from "react-icons/ai";
import ProtestoNacionalQuery from "../queries/ProtestoNacionalQuery";
import RestritivaPJQuery from "../queries/RestritivaPJQuery";
import PositivoPlusCNPJQuery from "../queries/PositivoPlusCNPJQuery";
import DefineLimiteQuery from "../queries/DefineLimiteQuery";
import DefineLimitePositivoQuery from "../queries/DefineLimitePositivoQuery";
import ForbiddenQueriesPermission from "../common/ForbiddenQueriesPermission";
import CompletaPlusCNPJQuery from "../queries/CompletaPlusCNPJQuery";
import CadinQuery from "../queries/CadinQuery";
import AcertaCompletoCPFQuery from "../queries/AcertaCompletoCPFQuery";
import AvalieQuery from "../queries/AvalieQuery";
import { IoSearch } from "react-icons/io5";
import SerasaBasicoQuery from "../queries/SerasaBasicoQuery";
import CreditoPremiumCPFQuery from "../queries/CreditoPremiumCPFQuery";
import CreditoPremiumCNPJQuery from "../queries/CreditoPremiumCNPJQuery";
import SerasaPremiumCPFQuery from "../queries/SerasaPremiumCPFQuery";
import SerasaPremiumCNPJQuery from "../queries/SerasaPremiumCNPJQuery";
import InfocredCPF from "../queries/InfocredCPF";
import InfocredCNPJ from "../queries/InfocredCNPJ";
import CompletaPlusCPFQuery from "../queries/CompletaPlusCPFQuery";
import CndtCertidaoNacionalDeDebitosTrabalhistasQuery from "../queries/autos/CndtCertidaoNacionalDeDebitosTrabalhistasQuery";
import AcessoPrimeCPFQuery from "../queries/AcessoPrimeCPFQuery";
import AcessoPrimeCNPJQuery from "../queries/AcessoPrimeCNPJQuery";
import RelatorioCompletoCPFQuery from "../queries/RelatorioCompletoCPFQuery";

const QueriesCreditTable = () => {
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("ALL");

  const {
    isLoading,
    error,
    data: userQueryPermissions,
  } = useQuery(["userQueryPermissions"], async () => {
    const { data: items } = await axios.get(
      `${USERS.GET_USER_QUERY_PERMISSIONS}`
    );

    return items.data.filter((query) => query.categoryId === 1);
  });

  useEffect(() => {
    if (selectedQuery) {
      const matchingQuery = userQueryPermissions.find(
        (query) => query.id === selectedQuery?.id
      );
      setSelectedPrice(matchingQuery?.price);
    }
  }, [selectedQuery]);

  const filteredQueries = userQueryPermissions?.filter((query) =>
    query?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const queries = [
    {
      id: 1,
      name: "Positivo Plus CNPJ",
      component: (
        <PositivoPlusCNPJQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "CNPJ",
    },
    {
      id: 2,
      name: "Positivo Plus CPF",
      component: (
        <PositivoPlusCPFQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "CPF",
    },
    {
      id: 3,
      name: "SCR BACEN CPF",
      component: (
        <ScrBacenScoreQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "CPF",
    },
    {
      id: 5,
      name: "Restritiva CPF",
      component: (
        <RestritivaPFQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "CPF",
    },
    {
      id: 7,
      name: "Restritiva PJ",
      component: (
        <RestritivaPJQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "CNPJ",
    },
    {
      id: 8,
      name: "Protesto Nacional PF/PJ",
      component: (
        <ProtestoNacionalQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "ALL",
    },
    {
      id: 9,
      name: "Define Limite PJ",
      component: (
        <DefineLimiteQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "CNPJ",
    },
    {
      id: 10,
      name: "Define Limite Positivo PJ",
      component: (
        <DefineLimitePositivoQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "CNPJ",
    },
    {
      id: 14,
      name: "Completa Plus CNPJ",
      component: (
        <CompletaPlusCNPJQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "ALL",
    },
    {
      id: 15,
      name: "Cadin",
      component: (
        <CadinQuery query={selectedQuery} selectedPrice={selectedPrice} />
      ),
      type: "ALL",
    },
    {
      id: 16,
      name: "Avalie",
      component: (
        <AvalieQuery query={selectedQuery} selectedPrice={selectedPrice} />
      ),
      type: "ALL",
    },
    {
      id: 17,
      name: "12 - Acesso Completo",
      component: (
        <AcertaCompletoCPFQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "CPF",
    },
    {
      id: 18,
      name: "Serasa Relatório Básico",
      component: (
        <SerasaBasicoQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "ALL",
    },
    {
      id: 20,
      name: "Crédito Premium CPF",
      component: (
        <CreditoPremiumCPFQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "CPF",
    },
    {
      id: 21,
      name: "Crédito Premium CNPJ",
      component: (
        <CreditoPremiumCNPJQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "CNPJ",
    },
    {
      id: 22,
      name: "Serasa Premium CPF",
      component: (
        <SerasaPremiumCPFQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "CPF",
    },
    {
      id: 23,
      name: "Serasa Premium CNPJ",
      component: (
        <SerasaPremiumCNPJQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "CNPJ",
    },
    {
      id: 24,
      name: "22 - Infocred CNPJ",
      component: (
        <InfocredCNPJ query={selectedQuery} selectedPrice={selectedPrice} />
      ),
      type: "CNPJ",
    },
    {
      id: 25,
      name: "22 - Infocred CPF",
      component: (
        <InfocredCPF query={selectedQuery} selectedPrice={selectedPrice} />
      ),
      type: "CPF",
    },
    {
      id: 27,
      name: "Completa Plus CPF",
      component: (
        <CompletaPlusCPFQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "CPF",
    },
    {
      id: 35,
      name: "Certidão Nacional de Débitos Trabalhistas",
      component: (
        <CndtCertidaoNacionalDeDebitosTrabalhistasQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "CPF",
    },
    {
      id: 46,
      name: "Acesso Prime CPF",
      component: (
        <AcessoPrimeCPFQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "CPF",
    },
    {
      id: 47,
      name: "Acesso Prime CNPJ",
      component: (
        <AcessoPrimeCNPJQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "CNPJ",
    },
    {
      id: 48,
      name: "Relatorio Completo CPF",
      component: (
        <RelatorioCompletoCPFQuery
          query={selectedQuery}
          selectedPrice={selectedPrice}
        />
      ),
      type: "CPF",
    },
  ];

  return (
    <div className="w-100 flex flex-col lg:flex-row relative">
      {isLoading && <DrawerLoading />}
      {userQueryPermissions?.length > 0 ? (
        <>
          <div className="w-full lg:w-3/12 p-4 flex flex-col gap-3">
            <h2 className="title-syncx text-lg">Consultas de crédito</h2>
            <div className="border-none rounded-md bg-secondary p-2 shadow-lg flex items-center gap-1 px-3">
              <IoSearch color="white" fontSize={20} />
              <input
                className="border-none rounded-md bg-secondary w-full placeholder-white focus:outline-none focus:border-none text-white text-sm"
                type="text"
                placeholder="Busque aqui sua consulta"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} // Atualiza o valor da busca
              />
            </div>
            <ul className="flex -mb-4 ml-3 text-xs">
              <li
                className={`border border-gray-200 p-3 rounded px-4 cursor-pointer ${
                  activeTab === "ALL"
                    ? "text-blue-800 font-semibold bg-gray-200"
                    : "bg-gray-100"
                }`}
                onClick={() => setActiveTab("ALL")}
              >
                Todas
              </li>
              <li
                className={`border border-gray-200 p-3 rounded px-4 cursor-pointer ${
                  activeTab === "CPF"
                    ? "text-blue-800 font-semibold bg-gray-200"
                    : "bg-gray-100"
                }`}
                onClick={() => setActiveTab("CPF")}
              >
                CPF
              </li>
              <li
                className={`border border-gray-200 p-3 rounded px-4 cursor-pointer ${
                  activeTab === "CNPJ"
                    ? "text-blue-800 font-semibold bg-gray-200"
                    : "bg-gray-100"
                }`}
                onClick={() => setActiveTab("CNPJ")}
              >
                CNPJ
              </li>
            </ul>
            <div
              className="bg-gray-200 rounded-md shadow-md p-2 overflow-y-auto flex flex-col gap-2 styled-scrollbar"
              style={{ maxHeight: "520px" }}
            >
              {filteredQueries?.map((query) => {
                const matchedQuery = queries.find(
                  (queryToCompare) => queryToCompare.id === query.id
                );
                const isActiveTab =
                  activeTab === "ALL" ||
                  matchedQuery?.type === activeTab ||
                  matchedQuery?.type === "ALL";

                return (
                  isActiveTab && (
                    <Card
                      key={query.id}
                      className={`card-query text-blue-900 ${
                        selectedQuery?.id === query.id ? "active" : ""
                      }`}
                      onClick={() => setSelectedQuery(query)}
                    >
                      <span>{query.name}</span>
                    </Card>
                  )
                );
              })}
            </div>
          </div>
          {selectedQuery ? (
            <div className={`w-full lg:w-9/12 p-4`}>
              {
                queries.find((query) => query.id === selectedQuery?.id)
                  ?.component
              }
            </div>
          ) : (
            <div className="mt-14 w-full lg:w-9/12 text-center py-10 border-dashed border-gray-300 border-2 rounded font-semibold text-gray-500 flex items-center justify-center min-h-60 flex gap-1 flex-col">
              <AiOutlineFileSearch fontSize={30} />
              <span>Escolha uma opção ao lado para realizar uma consulta.</span>
            </div>
          )}
        </>
      ) : (
        <ForbiddenQueriesPermission />
      )}
    </div>
  );
};

export default QueriesCreditTable;
