import {
  Alert,
  Button,
  Card,
  Label,
  Table,
  TextInput,
  Timeline,
} from "flowbite-react";
import React, { useEffect, useState } from "react";
import { isValidCPF } from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import CardQueryDescription from "../common/CardQueryDescription";
import QueryLoading from "../common/QueryLoading";
import QueryInput from "../inputs/QueryInput";
import TitleStripe from "../common/TitleStripe";

const LocalizaCPFQuery = ({ query, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);

  const fetchInfo = async (raw) => {
    return await axios(
      `${QUERIES.GET_LOCALIZA_CPF}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCPF(unmaskedValue)) {
      setError("CPF inválido!");
      isValid = false;
    }

    if (isValid) {
      try {
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados.data.RETORNO);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setData(null);
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Dados Cadastrais",
          "Informações profissionais",
          "Possíveis parentes",
          "Histórico de endereços",
          "Telefones fixos/celulares",
          "E-mails",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 mt-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex gap-2 items-center">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CPF"
            />
          </div>
          {error && <small className="text-red-600">{error}</small>}
        </form>
      </CardQueryDescription>
      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <div className={`${data || isLoading ? "block" : "hidden"}`}>
          {!isLoading ? (
            <Card>
              <HeaderQuery title={query?.name} />
              <TitleStripe>Informações da pessoa:</TitleStripe>
              <div className="flex flex-col md:flex-row gap-3 md:gap-20">
                <div className="flex-1">
                  <div className="mb-3">
                    <span className="font-semibold">CPF: </span>
                    {data?.CADASTRAIS?.CPF}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Nome completo: </span>
                    {data?.CADASTRAIS?.NOME}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Nome da mãe: </span>
                    {data?.CADASTRAIS?.NOME_MAE}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Sexo: </span>
                    {data?.CADASTRAIS?.SEXO}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Data de nascimento: </span>
                    {data?.CADASTRAIS?.NASCTO}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Idade: </span>
                    {data?.CADASTRAIS?.IDADE}
                  </div>
                </div>
                <div className="flex-1">
                  <div className="mb-3">
                    <span className="font-semibold">Título de eleitor: </span>
                    {data?.CADASTRAIS?.TITULO_ELEITOR || "Sem info."}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">RG: </span>
                    {data?.CADASTRAIS?.RG || "Sem info."}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Nacionalidade: </span>
                    {data?.CADASTRAIS?.NACIONALIDADE || "Sem info."}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Profissão: </span>
                    {data?.CADASTRAIS?.PROFISSAO || "Sem info."}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Salário: </span>
                    {data?.CADASTRAIS?.SALARIO || "Sem info."}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Escolaridade: </span>
                    {data?.CADASTRAIS?.ESCOLARIDADE || "Sem info."}
                  </div>
                </div>
              </div>

              <div className="-mt-2">
                <TitleStripe className="mb-5">
                  Informações profissionais
                </TitleStripe>
                {data?.REFERENCIA_PROFISSIONAL ? (
                  <Timeline>
                    {Array.isArray(data?.REFERENCIA_PROFISSIONAL?.EMPRESA) ? (
                      <>
                        {data?.REFERENCIA_PROFISSIONAL?.EMPRESA?.map(
                          (referencia, index) => (
                            <Timeline.Item key={index}>
                              <Timeline.Point />
                              <Timeline.Content>
                                <Timeline.Time>
                                  Ano base: {referencia.ANO_BASE}
                                </Timeline.Time>
                                <Timeline.Title>
                                  {referencia.RAZAO_SOCIAL}
                                </Timeline.Title>
                                <Timeline.Body>
                                  <div className="flex items-center gap-1">
                                    <span>Profissão:</span>
                                    <span>
                                      {referencia.PROFISSAO || "Sem info."}
                                    </span>
                                  </div>
                                  <div className="flex items-center gap-1">
                                    <span>Data de admissão:</span>
                                    <span>
                                      {referencia.DATA_ADMISSAO || "Sem info."}
                                    </span>
                                  </div>
                                  <div className="flex items-center gap-1">
                                    <span>Data de demissão:</span>
                                    <span>
                                      {referencia.DATA_DEMISSAO || "Sem info."}
                                    </span>
                                  </div>
                                  <div className="flex items-center gap-1">
                                    <span>Motivo de desligamento:</span>
                                    <span>
                                      {referencia.MOTIVO_DESLIGAMENTO ||
                                        "Sem info."}
                                    </span>
                                  </div>
                                </Timeline.Body>
                              </Timeline.Content>
                            </Timeline.Item>
                          )
                        )}
                      </>
                    ) : (
                      <Timeline.Item>
                        <Timeline.Point />
                        <Timeline.Content>
                          <Timeline.Time>
                            Ano base:{" "}
                            {data?.REFERENCIA_PROFISSIONAL?.EMPRESA?.ANO_BASE}
                          </Timeline.Time>
                          <Timeline.Title>
                            {
                              data?.REFERENCIA_PROFISSIONAL?.EMPRESA
                                ?.RAZAO_SOCIAL
                            }
                          </Timeline.Title>
                          <Timeline.Body>
                            <div className="flex items-center gap-1">
                              <span>Profissão:</span>
                              <span>
                                {data?.REFERENCIA_PROFISSIONAL?.EMPRESA
                                  ?.PROFISSAO || "Sem info."}
                              </span>
                            </div>
                            <div className="flex items-center gap-1">
                              <span>Data de admissão:</span>
                              <span>
                                {data?.REFERENCIA_PROFISSIONAL?.EMPRESA
                                  ?.DATA_ADMISSAO || "Sem info."}
                              </span>
                            </div>
                            <div className="flex items-center gap-1">
                              <span>Data de demissão:</span>
                              <span>
                                {data?.REFERENCIA_PROFISSIONAL?.EMPRESA
                                  ?.DATA_DEMISSAO || "Sem info."}
                              </span>
                            </div>
                            <div className="flex items-center gap-1">
                              <span>Motivo de desligamento:</span>
                              <span>
                                {data?.REFERENCIA_PROFISSIONAL?.EMPRESA
                                  ?.MOTIVO_DESLIGAMENTO || "Sem info."}
                              </span>
                            </div>
                          </Timeline.Body>
                        </Timeline.Content>
                      </Timeline.Item>
                    )}
                  </Timeline>
                ) : (
                  <Alert className="mb-5 text-base" color="gray">
                    Nada consta
                  </Alert>
                )}
              </div>

              <div className="-mt-5">
                <TitleStripe className="mb-3 -mt-5">
                  Histórico de endereços
                </TitleStripe>
                {data?.ENDERECOS ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-gray-300">
                            Endereço
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Complemento
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Bairro
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Cidade
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            CEP
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {Array.isArray(data?.ENDERECOS?.ENDERECO) ? (
                            <>
                              {data?.ENDERECOS?.ENDERECO?.map(
                                (address, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                      {address.LOGRADOURO} Nº {address.NUMERO}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {address.COMPLEMENTO}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {address.BAIRRO}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {address.CIDADE}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {address.CEP}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </>
                          ) : (
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                              <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                {data?.ENDERECOS?.ENDERECO?.LOGRADOURO} Nº{" "}
                                {data?.ENDERECOS?.ENDERECO?.NUMERO}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {data?.ENDERECOS?.ENDERECO?.COMPLEMENTO}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {data?.ENDERECOS?.ENDERECO?.BAIRRO}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {data?.ENDERECOS?.ENDERECO?.CIDADE}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {data?.ENDERECOS?.ENDERECO?.CEP}
                              </Table.Cell>
                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Alert>Nada consta</Alert>
                )}
              </div>

              <div>
                <TitleStripe className="mb-3">Possíveis parentes</TitleStripe>
                {data?.POSSIVEIS_PARENTES ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-gray-300">
                            Nome
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            CPF
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Local
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Parentesco
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Idade
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {Array.isArray(data?.POSSIVEIS_PARENTES?.CONTATO) ? (
                            <>
                              {data?.POSSIVEIS_PARENTES?.CONTATO?.map(
                                (contato, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                      {contato.NOME}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {contato.CPF}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {contato.LOCAL}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {contato.PARENTESCO}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {contato.IDADE}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </>
                          ) : (
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                              <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                {data?.POSSIVEIS_PARENTES?.CONTATO?.NOME}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {data?.POSSIVEIS_PARENTES?.CONTATO?.CPF}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {data?.POSSIVEIS_PARENTES?.CONTATO?.LOCAL}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {data?.POSSIVEIS_PARENTES?.CONTATO?.PARENTESCO}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {data?.POSSIVEIS_PARENTES?.CONTATO?.IDADE}
                              </Table.Cell>
                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Alert>Nada consta</Alert>
                )}
              </div>
              <div>
                <TitleStripe className="mb-3">Telefones móveis</TitleStripe>
                {data?.TELEFONES_MOVEL ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-gray-300">
                            Número
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Operadora
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Prioridade
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {Array.isArray(data?.TELEFONES_MOVEL?.TELEFONE) ? (
                            <>
                              {data?.TELEFONES_MOVEL?.TELEFONE?.map(
                                (telefone, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                      {telefone.NUMERO}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {telefone.OPERADORA}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {telefone.PRIORIDADE}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </>
                          ) : (
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                              <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                {data?.TELEFONES_MOVEL?.TELEFONE?.NUMERO}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {data?.TELEFONES_MOVEL?.TELEFONE?.OPERADORA}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {data?.TELEFONES_MOVEL?.TELEFONE?.PRIORIDADE}
                              </Table.Cell>
                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Alert>Nada consta</Alert>
                )}
              </div>
              <div>
                <TitleStripe className="mb-3">Telefones fixos</TitleStripe>
                {data?.TELEFONES_FIXO ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-gray-300">
                            Número
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Operadora
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Prioridade
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {Array.isArray(data?.TELEFONES_FIXO?.TELEFONE) ? (
                            <>
                              {data?.TELEFONES_FIXO?.TELEFONE?.map(
                                (telefone, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                      {telefone.NUMERO}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {telefone.OPERADORA}
                                    </Table.Cell>
                                    <Table.Cell className="text-center">
                                      {telefone.PRIORIDADE}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </>
                          ) : (
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                              <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                {data?.TELEFONES_FIXO?.TELEFONE?.NUMERO}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {data?.TELEFONES_FIXO?.TELEFONE?.OPERADORA}
                              </Table.Cell>
                              <Table.Cell className="text-center">
                                {data?.TELEFONES_FIXO?.TELEFONE?.PRIORIDADE}
                              </Table.Cell>
                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Alert>Nada consta</Alert>
                )}
              </div>
              <div>
                <TitleStripe className="mb-3">E-mails</TitleStripe>
                {data?.EMAILS ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="bg-gray-300">
                            E-mail
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {Array.isArray(data?.EMAILS?.EMAIL) ? (
                            <>
                              {data?.EMAILS?.EMAIL?.map((email, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                    {email}
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </>
                          ) : (
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                              <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                {data?.EMAILS?.EMAIL}
                              </Table.Cell>
                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Alert>Nada consta</Alert>
                )}
              </div>
            </Card>
          ) : (
            <QueryLoading />
          )}
        </div>
      )}
    </>
  );
};

export default LocalizaCPFQuery;
