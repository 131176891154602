import { Alert, Badge, Button, Card } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import axios from "../../../config/axiosInstance";
import { QUERIES_VEICULAR } from "../../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "../HeaderQuery";
import usePDF from "../../../hooks/usePDF";
import TitleStripe from "../../common/TitleStripe";
import CardQueryDescription from "../../common/CardQueryDescription";
import SliderToPreview from "../../common/SliderToPreview";
import { TiArrowRight } from "react-icons/ti";
import Loader from "../../common/Loader";
import FooterQuery from "../FooterQuery";
import NoOcurrence from "../../common/query/NoOcurrence";
import QueryVehicleInput from "../../inputs/QueryVehicleInput";

const LaudoCsvPrimeQuery = ({
  query,
  selectedPrice,
  dataFromLocalStorage = null,
  hasHeaderPDF = "true",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    data
  );

  useEffect(() => {
    if (dataFromLocalStorage) {
      setData(dataFromLocalStorage?.dadosVeicular);
      setDocument(dataFromLocalStorage?.resumoRetorno?.document);
    }
  }, []);

  const fetchInfo = async (raw) => {
    return await axios(`${QUERIES_VEICULAR.GET_VEICULAR}/${query?.id}/${info}`);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);
    let isValid = true;

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados?.data?.dadosVeiculo || null);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setData(null);
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Resumo do veículo",
          "Informações do proprietário atual",
          "Alertas",
        ]}
        price={selectedPrice}
        imageBanner="/assets/bg_banner_car.jpg"
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <QueryVehicleInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="PLACA"
            />
            <div>
              {!errorQuery && data && (
                <Button
                  className={`w-fit shadow-lg secondary ${
                    data && !isLoading ? "block" : "hidden"
                  }`}
                  isProcessing={pdfURL === ""}
                  disabled={pdfURL === ""}
                  onClick={() => handleClickDownloadPDF()}
                >
                  Salvar como PDF
                </Button>
              )}
            </div>
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`overflow-x-scroll ${
              data || isLoading ? "block" : "hidden"
            }`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="relative overflow-hidden p-5 card-pdf">
                  <HeaderQuery title={query?.name} type="vehicle" />

                  <TitleStripe>Dados do proprietário atual</TitleStripe>

                  <div className="flex gap-16">
                    <div className="text-base">
                      <div className="mb-3">
                        <span className="font-semibold">
                          Proprietário Atual:{" "}
                        </span>
                        {data?.proprietarioAtual?.proprietario?.nome ||
                          "Sem info."}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Marca/Modelo: </span>
                        {data?.proprietarioAtual?.proprietario?.marcaModelo ||
                          "Sem info."}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Ano Fabr./Modelo:{" "}
                        </span>
                        {`${data?.proprietarioAtual?.proprietario?.veiculo?.anoFabricacao}/${data?.proprietarioAtual?.proprietario?.veiculo?.anoModelo}` ||
                          "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Cor: </span>
                        {data?.proprietarioAtual?.proprietario?.veiculo
                          ?.corVeiculo || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Combustível: </span>
                        {data?.proprietarioAtual?.proprietario?.veiculo
                          ?.combustivel || "Indisponível"}
                      </div>
                    </div>
                    <div className="text-base">
                      <div className="mb-3">
                        <span className="font-semibold">Documento: </span>
                        {data?.proprietarioAtual?.proprietario?.documento ||
                          "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Placa: </span>
                        {document}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Chassi: </span>
                        {data?.proprietarioAtual?.proprietario?.veiculo
                          ?.chassi || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">N. Motor: </span>
                        {data?.proprietarioAtual?.proprietario?.veiculo
                          ?.numeroMotor || "Indisponível"}
                      </div>
                    </div>
                  </div>

                  {data?.alertas?.statusRetorno?.codigo === "1" && (
                    <div>
                      <TitleStripe>
                        Informações, Alertas ou Restrições
                      </TitleStripe>

                      {data?.alertas?.ocorrencias?.length > 0 ? (
                        <ul className="mt-3 text-sm flex flex-col gap-2">
                          {data?.alertas?.ocorrencias?.map(
                            (ocorrencia, index) => (
                              <li
                                key={index}
                                className="bg-red-100 px-2 py-1 rounded flex flex-col gap-1"
                              >
                                <div className="font-semibold capitalize">
                                  Severidade:{" "}
                                  {ocorrencia.severidade?.toLowerCase()}
                                </div>
                                <div className="flex items-center gap-1 capitalize">
                                  <TiArrowRight />
                                  {ocorrencia.descricao?.toLowerCase()}
                                </div>
                                <div className="flex items-center gap-1 capitalize">
                                  <TiArrowRight />
                                  {ocorrencia.mensagem?.toLowerCase()}
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                      ) : (
                        <NoOcurrence />
                      )}
                    </div>
                  )}
                  <FooterQuery hasHeader={hasHeaderPDF} />
                </Card>
              </>
            ) : (
              <Loader
                words={[
                  "Resumo do veículo",
                  "Informações do proprietário atual",
                  "Alertas",
                ]}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default LaudoCsvPrimeQuery;
