import React from "react";
import moment from "moment";
import { useApplication } from "../../contexts/ApplicationContext";

const HeaderQuery = ({
  title,
  type = "default",
  hasHeader = "true",
  date = "",
}) => {
  const { user } = useApplication();

  return (
    <>
      {hasHeader === "true" && (
        <>
          {user?.Company?.showLogo && (
            <img
              src="/assets/banner_header.png"
              className="w-full absolute top-0 left-0"
            />
          )}

          <div
            className={`flex flex-row justify-between text-end gap-2 -mt-2 mb-1 items-center ${
              user?.Company?.showLogo ? "pt-14" : "pt-4"
            }`}
          >
            <h2 className="text-center font-semibold text-base whitespace-nowrap inline-block">
              Relatório de Inteligência
            </h2>
            <div>
              <div className="font-semibold">{title}</div>
              <div className="text-sm text-end">
                <span>Data | Hora: </span>
                {date !== ""
                  ? date
                  : `${moment(moment()).format("DD/MM/yyyy | HH:mm")}h`}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default HeaderQuery;
