import { Alert, Button, Card, Table } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { isValidCNPJ, isValidCPF } from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import TitleStripe from "../common/TitleStripe";
import CardQueryDescription from "../common/CardQueryDescription";
import QueryInput from "../inputs/QueryInput";
import SliderToPreview from "../common/SliderToPreview";
import QueryLoading from "../common/QueryLoading";
import AvisoCard from "../common/query/AvisoCard";
import PendenciasFinanceiras from "../common/query/PendenciasFinanceiras";
import NoOcurrence from "../common/query/NoOcurrence";
import Protestos from "../common/query/Protestos";
import ChequesSemFundos from "../common/query/ChequesSemFundos";
import QuadroSocietario from "../common/query/QuadroSocietario";
import moment from "moment";
import FooterQuery from "./FooterQuery";

const CompletaPlusCPFQuery = ({
  query,
  selectedPrice,
  dataFromLocalStorage = null,
  hasHeaderPDF = "true",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [error, setError] = useState("");
  const [errorData, setErrorData] = useState("");
  const [resumoRetorno, setResumoRetorno] = useState(null);
  const [data, setData] = useState(null);
  const [document, setDocument] = useState("");
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    data
  );

  useEffect(() => {
    if (dataFromLocalStorage) {
      setData(dataFromLocalStorage.consultaCredito);
      setDocument(dataFromLocalStorage?.consultaCredito?.dadosCadastrais?.cpf);
    }
  }, []);

  const fetchInfo = async (type) => {
    return await axios(
      `${QUERIES.GET_COMPLETA_PLUS_CPF}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setDocument(info);
    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;
    let type = "F";

    if (unmaskedValue.length <= 11) {
      if (!isValidCPF(unmaskedValue)) {
        setError("CPF inválido!");
        isValid = false;
      }
    } else {
      if (!isValidCNPJ(unmaskedValue)) {
        setError("CNPJ inválido!");
        isValid = false;
      }
      type = "J";
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setErrorData("");
        const { data: dados } = await fetchInfo(type);
        setData(dados?.data?.consultaCredito);
        setResumoRetorno(dados?.data?.resumoRetorno);
      } catch (error) {
        setErrorData(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setData(null);
    }
  };

  const formatArraySocios = (arraySocios) => {
    return arraySocios?.map((socio) => {
      return {
        nome_empresa: socio.empresa,
        cpf_cnpj: socio.cnpj,
        data_entrada: socio.dataEntrada,
        participacao: socio.participacaoEmPorcentagem,
      };
    });
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>

      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Pendências Financeiras",
          "Protestos",
          "Cheques sem fundos",
          "Cadin",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 mt-5 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CPF"
            />
            {!errorData && data && (
              <Button
                className={`w-fit shadow-lg secondary ${
                  data && !isLoading ? "block" : "hidden"
                }`}
                isProcessing={pdfURL === ""}
                disabled={pdfURL === ""}
                onClick={() => handleClickDownloadPDF()}
              >
                Salvar como PDF
              </Button>
            )}
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorData && (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorData}</span>
        </Alert>
      )}
      {!errorData && (
        <div
          className={`overflow-x-scroll ${
            data || isLoading ? "block" : "hidden"
          }`}
          ref={componentRef}
        >
          {!isLoading ? (
            <Card className="relative overflow-hidden card-pdf p-5">
              <HeaderQuery title={query?.name} hasHeader={hasHeaderPDF} />
              <TitleStripe>Informações:</TitleStripe>
              <div className="flex gap-20 text-sm">
                <div className="flex-1">
                  <div className="mb-3">
                    <span className="font-semibold">Documento: </span>
                    {data?.dadosCadastrais?.cpf}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Tipo: </span>
                    {document?.length === 18 ||
                    (!document?.includes("-") && document.length === 14)
                      ? "Jurídica"
                      : "Física"}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Nome: </span>
                    {data?.dadosCadastrais?.nome}
                  </div>
                  <div>
                    <span className="font-semibold">Data: </span>
                    {resumoRetorno?.dataConsulta}
                  </div>
                </div>
              </div>
              <TitleStripe>Quadro de avisos</TitleStripe>
              <div className="container-alerts four-columns">
                <AvisoCard
                  title="Pendências Financeiras"
                  hasRestrictions={
                    +data?.resumoConsulta?.pendenciasFinanceiras
                      ?.quantidadeTotal > 0
                  }
                  messageError={`${+data?.resumoConsulta?.pendenciasFinanceiras
                    ?.quantidadeTotal} ocorrências`}
                />
                <AvisoCard
                  title="Cadin"
                  hasRestrictions={data?.cadin?.length > 0}
                  messageError={`${data?.cadin?.length} ocorrências`}
                />
                <AvisoCard
                  title="Protestos"
                  hasRestrictions={
                    +data?.resumoConsulta?.protestos?.quantidadeTotal > 0
                  }
                  messageError={`${data?.resumoConsulta?.protestos?.quantidadeTotal} ocorrências`}
                />
                <AvisoCard
                  title="Cheques sem Fundo"
                  hasRestrictions={
                    +data?.resumoConsulta?.chequesSemFundo?.quantidadeTotal > 0
                  }
                  messageError={`${+data?.resumoConsulta?.chequesSemFundo
                    ?.quantidadeTotal} ocorrências`}
                />
              </div>

              <div>
                <TitleStripe className="mb-3">Quadro societário</TitleStripe>
                {data?.participacaoEmEmpresas?.length > 0 ? (
                  <QuadroSocietario
                    socios={formatArraySocios(data?.participacaoEmEmpresas)}
                  />
                ) : (
                  <NoOcurrence />
                )}
              </div>

              <div>
                <TitleStripe className="mb-3">
                  Pendências Financeiras
                </TitleStripe>
                {data?.pendenciasFinanceiras?.length > 0 ? (
                  <PendenciasFinanceiras
                    pendenciasFinanceiras={data?.pendenciasFinanceiras}
                  />
                ) : (
                  <NoOcurrence />
                )}
              </div>

              <div>
                <TitleStripe className="mb-3">Cadin</TitleStripe>
                {data?.cadin?.length > 0 ? (
                  <>
                    <div className="overflow-x-auto border-gray-300 border rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell
                            className="text-center text-black"
                            style={{ background: "#76B9FB" }}
                          >
                            Sequência
                          </Table.HeadCell>
                          <Table.HeadCell
                            className="text-center text-black"
                            style={{ background: "#76B9FB" }}
                          >
                            Sigla Credor
                          </Table.HeadCell>
                          <Table.HeadCell
                            className="text-center text-black"
                            style={{ background: "#76B9FB" }}
                          >
                            Credor
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {data?.cadin?.map((cadinItem, index) => (
                            <Table.Row
                              key={index}
                              className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black"
                            >
                              <Table.Cell className="font-medium dark:text-white text-center text-black py-1">
                                {cadinItem?.sequencia || "-"}
                              </Table.Cell>
                              <Table.Cell className="text-center text-black py-1">
                                {cadinItem?.siglaCredor || "-"}
                              </Table.Cell>
                              <Table.Cell className="text-center text-black py-1">
                                {cadinItem?.nomeCredor || "-"}
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <NoOcurrence />
                )}
              </div>

              <div>
                <TitleStripe className="mb-3">Protestos</TitleStripe>
                {data?.protestos?.length > 0 ? (
                  <Protestos protestos={data?.protestos} />
                ) : (
                  <NoOcurrence />
                )}
              </div>
              <div>
                <TitleStripe className="mb-3">Cheques sem fundo</TitleStripe>
                {data?.cheques?.length > 0 ? (
                  <ChequesSemFundos chequesSemFundos={data?.cheque} />
                ) : (
                  <NoOcurrence />
                )}
              </div>

              <FooterQuery hasHeader={hasHeaderPDF} />
            </Card>
          ) : (
            <QueryLoading />
          )}
        </div>
      )}
    </>
  );
};

export default CompletaPlusCPFQuery;
