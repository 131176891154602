import { Alert, Button, Card, Table } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import {
  formatToReal,
  getImageURLScore,
  isValidCNPJ,
  isValidCPF,
} from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import TitleStripe from "../common/TitleStripe";
import CardQueryDescription from "../common/CardQueryDescription";
import QueryInput from "../inputs/QueryInput";
import SliderToPreview from "../common/SliderToPreview";
import QueryLoading from "../common/QueryLoading";
import NoOcurrence from "../common/query/NoOcurrence";
import FooterQuery from "./FooterQuery";

const ScrBacenScoreQuery = ({
  query,
  selectedPrice,
  dataFromLocalStorage = null,
  hasHeaderPDF = "true",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [errorData, setErrorData] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    data
  );

  useEffect(() => {
    if (dataFromLocalStorage) {
      setData(dataFromLocalStorage);
      setDocument(dataFromLocalStorage?.resumoRetorno?.document);
    }
  }, []);

  const fetchInfo = async (type) => {
    return await axios(
      `${QUERIES.GET_SCR_BACEN_SCORE}/${info.replace(/\D/g, "")}?type=${type}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;
    let type = "F";

    if (unmaskedValue.length <= 11) {
      if (!isValidCPF(unmaskedValue)) {
        setError("CPF inválido!");
        isValid = false;
      }
    } else {
      if (!isValidCNPJ(unmaskedValue)) {
        setError("CNPJ inválido!");
        isValid = false;
      }
      type = "J";
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setErrorData("");
        const { data: dados } = await fetchInfo(type);

        if (Object.keys(dados.data.CREDCADASTRAL).length === 0) {
          throw new Error("Algum erro ocorreu com sua consulta");
        }
        setData(dados.data);
      } catch (error) {
        setErrorData(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setData(null);
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>

      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Dados Cadastrais",
          "SCR Score",
          "Créditos a vencer",
          "Créditos vencidos",
          "Limite de crédito",
          "Prejuízos",
          "Operações",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 mt-5 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CPF/CNPJ"
            />
            {!errorData && data && (
              <Button
                className={`w-fit shadow-lg secondary ${
                  data && !isLoading ? "block" : "hidden"
                }`}
                isProcessing={pdfURL === ""}
                disabled={pdfURL === ""}
                onClick={() => handleClickDownloadPDF()}
              >
                Salvar como PDF
              </Button>
            )}
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorData && (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorData}</span>
        </Alert>
      )}
      {!errorData && (
        <div
          className={`overflow-x-scroll ${
            data || isLoading ? "block" : "hidden"
          }`}
          ref={componentRef}
        >
          {!isLoading ? (
            <Card className="relative overflow-hidden p-5 card-pdf">
              <HeaderQuery hasHeader={hasHeaderPDF} title={query?.name} />
              <TitleStripe>Dados Cadastrais</TitleStripe>
              <div className="flex gap-20">
                <div className="text-sm flex-1">
                  <div className="mb-3">
                    <span className="font-semibold">Documento: </span>
                    {document}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">Tipo: </span>
                    {data?.CREDCADASTRAL?.RELATORIO_SCR?.TIPO_DOCUMENTO}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Quantidade operações:{" "}
                    </span>
                    {data?.CREDCADASTRAL?.RELATORIO_SCR?.QUANTIDADE_OPERACOES}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Quantidade instituições:{" "}
                    </span>
                    {
                      data?.CREDCADASTRAL?.RELATORIO_SCR
                        ?.QUANTIDADE_INSTITUICOES
                    }
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Coobrigação assumida:{" "}
                    </span>
                    R${" "}
                    {data?.CREDCADASTRAL?.RELATORIO_SCR?.COOBRIGACAO_ASSUMIDA}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Coobrigação recebida:{" "}
                    </span>
                    R${" "}
                    {data?.CREDCADASTRAL?.RELATORIO_SCR?.COOBRIGACAO_RECEBIDA}
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Início relacionamento:{" "}
                    </span>
                    {
                      data?.CREDCADASTRAL?.RELATORIO_SCR
                        ?.DATA_INICIO_RELACIONAMENTO
                    }
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Data Base consultada:{" "}
                    </span>
                    {data?.CREDCADASTRAL?.RELATORIO_SCR?.DATABASE_CONSULTADA}
                  </div>
                </div>
                <div className="text-sm flex-1">
                  <div className="mb-3">
                    <span className="font-semibold">
                      Percentual documentos processados:{" "}
                    </span>
                    {
                      data?.CREDCADASTRAL?.RELATORIO_SCR
                        ?.PERCENTUAL_DOCUMENTOS_PROCESSADOS
                    }
                    %
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Percentual volume processado:{" "}
                    </span>
                    {
                      data?.CREDCADASTRAL?.RELATORIO_SCR
                        ?.PERCENTUAL_VOLUME_PROCESSADO
                    }
                    %
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Quantidade operações(discordância):{" "}
                    </span>
                    {
                      data?.CREDCADASTRAL?.RELATORIO_SCR
                        ?.QUANTIDADE_OPERACOES_DISCORDANCIA
                    }
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Quantidade operações(subjudice):{" "}
                    </span>
                    {
                      data?.CREDCADASTRAL?.RELATORIO_SCR
                        ?.QUANTIDADE_OPERACOES_SUBJUDICE
                    }
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Responsabilidade total(discordância):{" "}
                    </span>
                    {
                      data?.CREDCADASTRAL?.RELATORIO_SCR
                        ?.RESPONSABILIDADE_TOTAL_DISCORDANCIA
                    }
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Responsabilidade total(subjudice):{" "}
                    </span>
                    {
                      data?.CREDCADASTRAL?.RELATORIO_SCR
                        ?.RESPONSABILIDADE_TOTAL_SUBJUDICE
                    }
                  </div>
                  <div className="mb-3">
                    <span className="font-semibold">
                      Risco indireto(vendor):{" "}
                    </span>
                    {data?.CREDCADASTRAL?.RELATORIO_SCR?.RISCO_INDIRETO_VENDOR}
                  </div>
                </div>
              </div>
              <div>
                <TitleStripe>SCR Score</TitleStripe>
              </div>
              <div className="flex items-center">
                <div className="flex gap-2 items-center flex-1">
                  <div className="p-4 px-10 mx-auto">
                    <img
                      src={getImageURLScore(
                        +data?.CREDCADASTRAL?.RELATORIO_SCR?.SCORE?.PONTUACAO
                      )}
                    />
                    <div className="text-center font-semibold text-lg">
                      {+data?.CREDCADASTRAL?.RELATORIO_SCR?.SCORE?.PONTUACAO}
                    </div>
                  </div>
                </div>
                <div className="flex-1 text-sm border border-gray-300 p-2">
                  <span className="font-semibold">Importante:</span> a decisão
                  de aprovação ou não do crédito é de exclusiva responsabilidade
                  do concedente. as informações prestadas pelo provedor tem como
                  objevo subsidiar essas decisões e, em hipótese alguma devem
                  ser ulizadas como jusficava, pelo concedente do crédito, para
                  a tomada da referida decisão.
                </div>
              </div>

              <div>
                <TitleStripe className="mb-5">Crédito a vencer</TitleStripe>
                {data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO
                  ?.CREDITO_AVENCER?.OPERACOES?.length > 0 ? (
                  <>
                    <div className="overflow-x-auto border border-gray-300 rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell
                            className=""
                            style={{ background: "#76B9FB" }}
                          >
                            Crédito
                          </Table.HeadCell>
                          <Table.HeadCell
                            className="text-center "
                            style={{ background: "#76B9FB" }}
                          >
                            Valor
                          </Table.HeadCell>
                          <Table.HeadCell
                            className="text-center "
                            style={{ background: "#76B9FB" }}
                          >
                            Percentual
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="font-medium text-black dark:text-white font-semibold py-1">
                              Carteira ativa A
                            </Table.Cell>
                            <Table.Cell className="text-center font-semibold py-1 text-black">
                              {formatToReal(
                                data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO?.CREDITO_AVENCER?.VALOR?.replace(
                                  ",",
                                  "."
                                )
                              )}
                            </Table.Cell>
                            <Table.Cell className="text-center font-semibold py-1 text-black">
                              {
                                data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO
                                  ?.CREDITO_AVENCER?.PERCENTUAL
                              }
                              %
                            </Table.Cell>
                          </Table.Row>
                          {data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO?.CREDITO_AVENCER?.OPERACOES?.map(
                            (credito, index) => (
                              <Table.Row
                                key={index}
                                className="bg-white dark:border-gray-700 dark:bg-gray-800 py-1"
                              >
                                <Table.Cell className="whitespace-nowrap font-medium text-black dark:text-white py-1">
                                  {credito.DESCRICAO}
                                </Table.Cell>
                                <Table.Cell className="text-center py-1 text-black">
                                  {formatToReal(
                                    credito.VALOR?.replace(",", ".")
                                  )}
                                </Table.Cell>
                                <Table.Cell className="text-center py-1 text-black">
                                  {credito.PERCENTUAL}%
                                </Table.Cell>
                              </Table.Row>
                            )
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <NoOcurrence />
                )}

                <div className="mt-10">
                  <TitleStripe className="mb-5">Crédito vencido</TitleStripe>
                  {data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO
                    ?.CREDITO_VENCIDO?.OPERACOES?.length > 0 ? (
                    <>
                      <div className="overflow-x-auto border border-gray-300 rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell
                              className=""
                              style={{ background: "#76B9FB" }}
                            >
                              Crédito
                            </Table.HeadCell>
                            <Table.HeadCell
                              className="text-center "
                              style={{ background: "#76B9FB" }}
                            >
                              Valor
                            </Table.HeadCell>
                            <Table.HeadCell
                              className="text-center "
                              style={{ background: "#76B9FB" }}
                            >
                              Percentual
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                              <Table.Cell className="text-black font-medium text-gray-900 dark:text-white font-semibold py-1">
                                Vencido
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold py-1 text-red-500">
                                {formatToReal(
                                  data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO?.CREDITO_VENCIDO?.VALOR?.replace(
                                    ",",
                                    "."
                                  )
                                )}
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold py-1">
                                {
                                  data?.CREDCADASTRAL?.RELATORIO_SCR
                                    ?.CONSOLIDADO?.CREDITO_VENCIDO?.PERCENTUAL
                                }
                                %
                              </Table.Cell>
                            </Table.Row>
                            {data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO?.CREDITO_VENCIDO?.OPERACOES?.map(
                              (credito, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="font-medium text-black dark:text-white py-1">
                                    {credito.DESCRICAO}
                                  </Table.Cell>
                                  <Table.Cell className="text-center py-1 text-black">
                                    {formatToReal(
                                      credito.VALOR?.replace(",", ".")
                                    )}
                                  </Table.Cell>
                                  <Table.Cell className="text-center py-1 text-black">
                                    {credito.PERCENTUAL}%
                                  </Table.Cell>
                                </Table.Row>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <NoOcurrence />
                  )}
                </div>
                <div className="mt-10">
                  <TitleStripe className="mb-5">Limite de crédito</TitleStripe>
                  {data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO
                    ?.LIMITE_CREDITO?.OPERACOES?.length > 0 ? (
                    <>
                      <div className="overflow-x-auto border border-gray-300 rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell
                              className=""
                              style={{ background: "#76B9FB" }}
                            >
                              Crédito
                            </Table.HeadCell>
                            <Table.HeadCell
                              className="text-center "
                              style={{ background: "#76B9FB" }}
                            >
                              Valor
                            </Table.HeadCell>
                            <Table.HeadCell
                              className="text-center "
                              style={{ background: "#76B9FB" }}
                            >
                              Percentual
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                              <Table.Cell className=" font-medium text-black dark:text-white font-semibold py-1">
                                Limite de crédito
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold py-1 text-black">
                                {formatToReal(
                                  data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO?.LIMITE_CREDITO?.VALOR?.replace(
                                    ",",
                                    "."
                                  )
                                )}
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold py-1 text-black">
                                {
                                  data?.CREDCADASTRAL?.RELATORIO_SCR
                                    ?.CONSOLIDADO?.LIMITE_CREDITO?.PERCENTUAL
                                }
                                %
                              </Table.Cell>
                            </Table.Row>
                            {data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO?.LIMITE_CREDITO?.OPERACOES?.map(
                              (credito, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="font-medium text-black dark:text-white py-1">
                                    {credito.DESCRICAO}
                                  </Table.Cell>
                                  <Table.Cell className="text-center py-1 text-black">
                                    {formatToReal(
                                      credito.VALOR?.replace(",", ".")
                                    )}
                                  </Table.Cell>
                                  <Table.Cell className="text-center py-1 text-black">
                                    {credito.PERCENTUAL}%
                                  </Table.Cell>
                                </Table.Row>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <NoOcurrence />
                  )}
                </div>

                <div className="mt-10">
                  <TitleStripe className="mb-5">Prejuízos</TitleStripe>
                  {data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO?.PREJUIZO
                    ?.OPERACOES?.length > 0 ? (
                    <>
                      <div className="overflow-x-auto border border-gray-300 rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell
                              className=""
                              style={{ background: "#76B9FB" }}
                            >
                              Prejuízo
                            </Table.HeadCell>
                            <Table.HeadCell
                              className="text-center "
                              style={{ background: "#76B9FB" }}
                            >
                              Valor
                            </Table.HeadCell>
                            <Table.HeadCell
                              className="text-center "
                              style={{ background: "#76B9FB" }}
                            >
                              Percentual
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                              <Table.Cell className="font-medium text-black dark:text-white font-semibold py-1">
                                Prejuízo
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold py-1 text-red-500">
                                {formatToReal(
                                  data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO?.PREJUIZO?.VALOR?.replace(
                                    ",",
                                    "."
                                  )
                                )}
                              </Table.Cell>
                              <Table.Cell className="text-center font-semibold py-1 text-black">
                                {
                                  data?.CREDCADASTRAL?.RELATORIO_SCR
                                    ?.CONSOLIDADO?.PREJUIZO?.PERCENTUAL
                                }
                                %
                              </Table.Cell>
                            </Table.Row>
                            {data?.CREDCADASTRAL?.RELATORIO_SCR?.CONSOLIDADO?.PREJUIZO?.OPERACOES?.map(
                              (prejuizo, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="py-1 font-medium text-black dark:text-white">
                                    {prejuizo.DESCRICAO}
                                  </Table.Cell>
                                  <Table.Cell className="text-center py-1 text-black">
                                    {formatToReal(
                                      prejuizo.VALOR?.replace(",", ".")
                                    )}
                                  </Table.Cell>
                                  <Table.Cell className="text-center py-1 text-black">
                                    {prejuizo.PERCENTUAL}%
                                  </Table.Cell>
                                </Table.Row>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <NoOcurrence />
                  )}
                </div>
              </div>
              <div>
                <TitleStripe className="mb-5">Operações</TitleStripe>
                {data?.CREDCADASTRAL?.RELATORIO_SCR?.OPERACOES?.length > 0 ? (
                  <>
                    <div className="overflow-x-auto border border-gray-300 rounded-lg">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell
                            className=""
                            style={{ background: "#76B9FB" }}
                          >
                            Modalidade
                          </Table.HeadCell>
                          <Table.HeadCell
                            className="text-center "
                            style={{ background: "#76B9FB" }}
                          >
                            Valor
                          </Table.HeadCell>
                          <Table.HeadCell
                            className="text-center "
                            style={{ background: "#76B9FB" }}
                          >
                            Percentual
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {data?.CREDCADASTRAL?.RELATORIO_SCR?.OPERACOES?.map(
                            (operacoes, index) => (
                              <Table.Row
                                key={index}
                                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              >
                                <Table.Cell className="font-medium py-1 text-black dark:text-white">
                                  {operacoes.MODALIDADE?.DESCRICAO}
                                </Table.Cell>
                                <Table.Cell className="text-center py-1 text-black">
                                  {formatToReal(
                                    operacoes.TOTAL?.replace(",", ".")
                                  )}
                                </Table.Cell>
                                <Table.Cell className="text-center py-1 text-black">
                                  {operacoes.PERCENTUAL}%
                                </Table.Cell>
                              </Table.Row>
                            )
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Card className={`bg-green-200 mt-4`}>
                    <div className="flex gap-2 items-center text-gray-600 font-semibold">
                      <div>
                        <FaRegCheckCircle fontSize={20} />
                      </div>
                      <div>Nada consta</div>
                    </div>
                  </Card>
                )}
              </div>

              <FooterQuery hasHeader={hasHeaderPDF} />
            </Card>
          ) : (
            <QueryLoading />
          )}
        </div>
      )}
    </>
  );
};

export default ScrBacenScoreQuery;
