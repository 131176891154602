import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Table,
  TextInput,
} from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaRegCheckCircle, FaSearch } from "react-icons/fa";
import { IoAlertCircleOutline } from "react-icons/io5";
import {
  formatToReal,
  getImageURLScore,
  isValidCNPJ,
  isValidCPF,
} from "../../utils/utils";
import ReactApexChart from "react-apexcharts";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { maskCNPJ, maskCPF } from "../../utils/masks";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import usePDF from "../../hooks/usePDF";
import TitleStripe from "../common/TitleStripe";
import CardQueryDescription from "../common/CardQueryDescription";
import moment from "moment";
import QueryInput from "../inputs/QueryInput";
import { FiAlertOctagon } from "react-icons/fi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import SliderToPreview from "../common/SliderToPreview";
import QueryLoading from "../common/QueryLoading";
import FooterQuery from "./FooterQuery";

const AvalieQuery = ({ query, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [type, setType] = useState("F");
  const [error, setError] = useState("");
  const [errorData, setErrorData] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    data
  );

  const fetchInfo = async (type) => {
    return await axios(
      `${QUERIES.GET_AVALIE}/${info.replace(/\D/g, "")}?type=${type}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;
    let typeConsult = "F";
    setType("F");

    if (unmaskedValue.length <= 11) {
      if (!isValidCPF(unmaskedValue)) {
        setError("CPF inválido!");
        isValid = false;
      }
    } else {
      if (!isValidCNPJ(unmaskedValue)) {
        setError("CNPJ inválido!");
        isValid = false;
      }
      setType("J");
      typeConsult = "J";
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setErrorData("");
        const { data: dados } = await fetchInfo(typeConsult);
        setData(dados.data);
      } catch (error) {
        setErrorData(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setData(null);
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>

      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Dados Cadastrais",
          "Score de risco",
          "Pendências financeiras",
          "Restricoes Financeiras ",
          "Cheques sem fundos",
          "Últimas consultas",
          "Protestos nacionais",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col md:flex-row gap-2 bg-white p-2 mt-5 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <QueryInput
            info={info}
            setInfo={setInfo}
            isLoading={isLoading}
            error={error}
            type="CPF/CNPJ"
          />
          {!errorData && data && (
            <Button
              className={`w-fit shadow-lg secondary ${
                data && !isLoading ? "block" : "hidden"
              }`}
              isProcessing={pdfURL === ""}
              disabled={pdfURL === ""}
              onClick={() => handleClickDownloadPDF()}
            >
              Salvar como PDF
            </Button>
          )}
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorData && (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorData}</span>
        </Alert>
      )}
      {!errorData && (
        <div
          className={`overflow-x-scroll ${
            data || isLoading ? "block" : "hidden"
          }`}
          ref={componentRef}
        >
          {!isLoading ? (
            <Card className="card-pdf relative overflow-hidden">
              <HeaderQuery title={query?.name} />
              <div className="title-syncx">Quadro de avisos</div>
              <div className="container-alerts four-columns">
                {+data?.spc?.pendenciaFinanceira?.resumo?.quantidadeTotal ===
                0 ? (
                  <Alert
                    color="success"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <IoMdCheckmarkCircleOutline
                      fontSize={30}
                      className="mx-auto mb-1"
                    />
                    <div className="text-center">Pendências financeiras</div>
                    <div className="text-center font-semibold">Nada consta</div>
                  </Alert>
                ) : (
                  <Alert
                    color="failure"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <FiAlertOctagon fontSize={30} className="mx-auto mb-1" />
                    <div className="text-center">Pendências financeiras</div>
                    <div className="text-center font-semibold">
                      {formatToReal(
                        +data?.spc?.pendenciaFinanceira?.resumo?.valorTotal
                      )}
                    </div>
                  </Alert>
                )}

                {+data?.spc?.spc?.resumo?.quantidadeTotal === 0 ? (
                  <Alert
                    color="success"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <IoMdCheckmarkCircleOutline
                      fontSize={30}
                      className="mx-auto mb-1"
                    />
                    <div className="text-center">Restrições</div>
                    <div className="text-center font-semibold">Nada consta</div>
                  </Alert>
                ) : (
                  <Alert
                    color="failure"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <FiAlertOctagon fontSize={30} className="mx-auto mb-1" />
                    <div className="text-center">Restrições</div>
                    <div className="text-center font-semibold">
                      {data?.spc?.spc?.resumo?.quantidadeTotal} ocorrências
                    </div>
                  </Alert>
                )}

                {+data?.spc?.ccf?.resumo?.quantidadeTotal === 0 ? (
                  <Alert
                    color="success"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <IoMdCheckmarkCircleOutline
                      fontSize={30}
                      className="mx-auto mb-1"
                    />
                    <div className="text-center">Cheques sem fundo</div>
                    <div className="text-center font-semibold">Nada consta</div>
                  </Alert>
                ) : (
                  <Alert
                    color="failure"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <FiAlertOctagon fontSize={30} className="mx-auto mb-1" />
                    <div className="text-center">Cheques sem fundo</div>
                    <div className="text-center font-semibold">
                      {data?.spc?.ccf?.resumo?.quantidadeTotal} ocorrências
                    </div>
                  </Alert>
                )}

                {!data?.protestos?.retorno?.protestos?.length > 0 ? (
                  <Alert
                    color="success"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <IoMdCheckmarkCircleOutline
                      fontSize={30}
                      className="mx-auto mb-1"
                    />
                    <div className="text-center">Títulos protestados</div>
                    <div className="text-center font-semibold">Nada consta</div>
                  </Alert>
                ) : (
                  <Alert
                    color="failure"
                    withBorderAccent
                    className="flex justify-center items-center flex-col"
                  >
                    <FiAlertOctagon fontSize={30} className="mx-auto mb-1" />
                    <div>Títulos protestados</div>
                    <div className="text-center font-semibold">
                      {data?.protestos?.retorno?.protestos?.length} ocorrências
                    </div>
                  </Alert>
                )}
              </div>
              {type === "F" ? (
                <>
                  {" "}
                  <TitleStripe>Informações da pessoa:</TitleStripe>
                  <div className="flex gap-20">
                    <div className="flex-1">
                      <div className="mb-3">
                        <span className="font-semibold">Nome completo: </span>
                        {data?.spc?.consumidor?.consumidorPessoaFisica?.nome}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Título de eleitor:{" "}
                        </span>
                        {data?.spc?.consumidor?.consumidorPessoaFisica
                          ?.numeroTituloEleitor || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">E-mail: </span>
                        {data?.spc?.consumidor?.consumidorPessoaFisica?.email ||
                          "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Data de nascimento:{" "}
                        </span>
                        {moment(
                          data?.spc?.consumidor?.consumidorPessoaFisica
                            ?.dataNascimento
                        ).format("DD/MM/yyyy") || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">RG: </span>
                        {data?.spc?.consumidor?.consumidorPessoaFisica
                          ?.numeroRg || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Data expedição RG:{" "}
                        </span>
                        {data?.spc?.consumidor?.consumidorPessoaFisica
                          ?.dataExpedicaoRg
                          ? moment(
                              data?.spc?.consumidor?.consumidorPessoaFisica
                                ?.dataExpedicaoRg
                            ).format("DD/MM/yyyy")
                          : "Indisponível"}
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="mb-3">
                        <span className="font-semibold">Sexo: </span>
                        {data?.spc?.consumidor?.consumidorPessoaFisica?.sexo ||
                          "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Nome da mãe: </span>
                        {data?.spc?.consumidor?.consumidorPessoaFisica
                          ?.nomeMae || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">CPF: </span>
                        {data?.spc?.consumidor?.consumidorPessoaFisica?.cpf
                          ?.numero || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Origem CPF: </span>
                        {data?.spc?.consumidor?.consumidorPessoaFisica?.cpf
                          ?.regiaoOrigem || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Estado Civil: </span>
                        {data?.spc?.consumidor?.consumidorPessoaFisica
                          ?.estadoCivil || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Nacionalidade: </span>
                        {data?.spc?.consumidor?.consumidorPessoaFisica
                          ?.nacionalidade || "Indisponível"}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <TitleStripe>Informações da empresa:</TitleStripe>
                  <div className="flex gap-20">
                    <div className="flex-1">
                      <div className="mb-3">
                        <span className="font-semibold">Nome comercial: </span>
                        {
                          data?.spc?.consumidor?.consumidorPessoaJuridica
                            ?.nomeComercial
                        }
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Razão Social: </span>
                        {data?.spc?.consumidor?.consumidorPessoaJuridica
                          ?.razaoSocial || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">E-mail: </span>
                        {data?.spc?.consumidor?.consumidorPessoaJuridica
                          ?.email || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Data de fundação:{" "}
                        </span>
                        {data?.spc?.consumidor?.consumidorPessoaJuridica
                          ?.dataFundacao
                          ? moment(
                              data?.spc?.consumidor?.consumidorPessoaJuridica
                                ?.dataFundacao
                            ).format("DD/MM/yyyy")
                          : "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">CNPJ: </span>
                        {data?.spc?.consumidor?.consumidorPessoaJuridica?.cnpj
                          ?.numero || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Situação CNPJ: </span>
                        {data?.spc?.consumidor?.consumidorPessoaJuridica
                          ?.situacaoCnpj?.descricaoSituacao || "Indisponível"}
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="mb-3">
                        <span className="font-semibold">Endereço: </span>
                        {data?.spc?.consumidor?.consumidorPessoaJuridica
                          ?.endereco?.logradouro || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Bairro: </span>
                        {data?.spc?.consumidor?.consumidorPessoaJuridica
                          ?.endereco?.bairro || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Cidade: </span>
                        {data?.spc?.consumidor?.consumidorPessoaJuridica
                          ?.endereco?.cidade?.nome || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">UF: </span>
                        {data?.spc?.consumidor?.consumidorPessoaJuridica
                          ?.endereco?.cidade?.estado?.siglaUf || "Indisponível"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">CEP: </span>
                        {data?.spc?.consumidor?.consumidorPessoaJuridica
                          ?.endereco?.cep || "Indisponível"}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {data?.spc?.scoreCadastroPositivo && (
                <>
                  {" "}
                  <div>
                    <TitleStripe>Score de risco</TitleStripe>
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="w-3/5 p-4">
                      <ReactApexChart
                        options={{
                          chart: {
                            type: "radialBar",
                            offsetY: -20,
                            sparkline: {
                              enabled: true,
                            },
                          },
                          plotOptions: {
                            radialBar: {
                              startAngle: -90,
                              endAngle: 90,
                              track: {
                                background: "#e7e7e7",
                                strokeWidth: "97%",
                                margin: 5, // margin is in pixels
                                dropShadow: {
                                  enabled: true,
                                  top: 2,
                                  left: 0,
                                  color: "#999",
                                  opacity: 1,
                                  blur: 2,
                                },
                              },
                              dataLabels: {
                                name: {
                                  show: false,
                                },
                                value: {
                                  offsetY: -2,
                                  fontSize: "22px",
                                  formatter: function (val) {
                                    return val * 10;
                                  },
                                },
                              },
                            },
                          },
                          grid: {
                            padding: {
                              top: -10,
                            },
                          },
                          fill: {
                            type: "solid",
                            gradient: {
                              shade: "light",
                              shadeIntensity: 0.4,
                              inverseColors: false,
                              opacityFrom: 1,
                              opacityTo: 1,
                              stops: [0, 50, 53, 91],
                            },
                            colors: (() => {
                              const score =
                                +data?.spc?.scoreCadastroPositivo
                                  ?.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista[0]
                                  ?.score;
                              if (score >= 800) {
                                return "#01B000";
                              } else if (score >= 600) {
                                return "#8DED01";
                              } else if (score >= 400) {
                                return "#FDF700";
                              } else if (score >= 200) {
                                return "#FFBC00";
                              } else {
                                return "#c7001b";
                              }
                            })(),
                          },
                        }}
                        series={[
                          +data?.spc?.scoreCadastroPositivo
                            ?.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista[0]
                            ?.score / 10,
                        ]}
                        type="radialBar"
                      />
                    </div>
                    <div className="w-full">
                      <div className="mb-2">
                        <Alert className="w-fit mb-2">
                          Probabilidade de inadimplência ={" "}
                          {
                            data?.spc?.scoreCadastroPositivo
                              ?.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista[0]
                              ?.probabilidadeInadimplencia
                          }
                        </Alert>{" "}
                      </div>
                    </div>
                  </div>
                  <Alert color="warning">
                    <span className="font-semibold">Importante:</span> a decisão
                    de aprovação ou não do crédito é de exclusiva
                    responsabilidade do concedente. as informações prestadas
                    pelo provedor tem como objevo subsidiar essas decisões e, em
                    hipótese alguma devem ser ulizadas como justificativa, pelo
                    concedente do crédito, para a tomada da referida decisão.
                  </Alert>
                </>
              )}

              <div>
                <TitleStripe className="mb-5">Score de risco</TitleStripe>
                <div className="flex items-center">
                  <img
                    className="flex-1 max-w-60"
                    src={getImageURLScore(
                      +data?.spc?.spcScore12Meses
                        ?.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista[0]
                        ?.score
                    )}
                  />
                  <div className="flex-1">
                    <div className="shadow-md rounded p-2 w-fit flex flex-col bg-gray-100">
                      <div className="flex items-center gap-2 mb-3">
                        <div className="text-xl">O score é: </div>
                        <div className="text-2xl rounded bg-blue-200 w-fit p-2 shadow">
                          {
                            data?.spc?.spcScore12Meses
                              ?.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista[0]
                              ?.score
                          }
                        </div>
                      </div>
                      <div className="font-semibold">
                        Probabilidade de Inadimplência:{" "}
                        {
                          data?.spc?.spcScore12Meses
                            ?.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista[0]
                            ?.probabilidade
                        }
                        %
                      </div>
                      {data?.spc?.spcScore12Meses
                        ?.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista[0]
                        ?.mesagemInterpretativaScore && (
                        <div>
                          {
                            data?.spc?.spcScore12Meses
                              ?.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista[0]
                              ?.mesagemInterpretativaScore
                          }
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <TitleStripe className="mb-5">
                  Pendências financeiras
                </TitleStripe>

                {+data?.spc?.pendenciaFinanceira?.resumo?.quantidadeTotal >
                0 ? (
                  <>
                    <div className="flex gap-6">
                      <div className="flex gap-2 items-center">
                        <span className="font-semibold text-red-700">
                          Quantidade:{" "}
                        </span>
                        <Badge color="failure" className="w-fit">
                          {
                            data?.spc?.pendenciaFinanceira?.resumo
                              ?.quantidadeTotal
                          }
                        </Badge>
                      </div>
                      <div className="flex gap-2 items-center">
                        <span className="font-semibold text-red-700">
                          Valor Total:{" "}
                        </span>
                        <Badge color="failure" className="w-fit">
                          R${" "}
                          {data?.spc?.pendenciaFinanceira?.resumo?.valorTotal}
                        </Badge>
                      </div>
                    </div>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="text-center bg-red-300">
                            Data
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Informante
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Cidade
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Contrato
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-red-300">
                            Valor
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {data?.spc?.pendenciaFinanceira?.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista?.map(
                            (debito, index) => (
                              <Table.Row
                                key={index}
                                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              >
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {moment(debito.dataOcorrencia).format(
                                    "DD/MM/yyyy"
                                  )}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {debito.origem}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {debito.cidade?.nome}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {debito.contrato}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {debito.moeda?.simbolo}
                                  {debito.valorPendencia}
                                </Table.Cell>
                              </Table.Row>
                            )
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Card className={`bg-green-200 mt-4`}>
                    <div className="flex gap-2 items-center text-gray-600 font-semibold">
                      <div>
                        <IoAlertCircleOutline fontSize={20} />
                      </div>
                      <div>Nada consta</div>
                    </div>
                  </Card>
                )}

                <div>
                  <TitleStripe className="mb-5">Restrições</TitleStripe>

                  {+data?.spc?.spc?.resumo?.quantidadeTotal > 0 ? (
                    <>
                      <div className="flex gap-6">
                        <div className="flex gap-2 items-center">
                          <span className="font-semibold text-red-700">
                            Quantidade:{" "}
                          </span>
                          <Badge color="failure" className="w-fit">
                            {data?.spc?.spc?.resumo?.quantidadeTotal}
                          </Badge>
                        </div>
                        <div className="flex gap-2 items-center">
                          <span className="font-semibold text-red-700">
                            Valor Total:{" "}
                          </span>
                          <Badge color="failure" className="w-fit">
                            R$ {data?.spc?.spc?.resumo?.valorTotal}
                          </Badge>
                        </div>
                      </div>
                      <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell className="text-center bg-red-300">
                              Entidade
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Associado
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Data inclusão
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Valor
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            {data?.spc?.spc?.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista?.map(
                              (spc, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                    {spc.nomeEntidade}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {spc.nomeAssociado}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {moment(spc.dataInclusao).format(
                                      "DD/MM/yyyy"
                                    )}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    R$ {spc.valor}
                                  </Table.Cell>
                                </Table.Row>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <Card className={`bg-green-200`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <IoAlertCircleOutline fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">
                    Títulos protestados
                  </TitleStripe>

                  {data?.protestos?.retorno?.protestos?.length > 0 ? (
                    <>
                      <div className="flex gap-6">
                        <div className="flex gap-2 items-center">
                          <span className="font-semibold text-red-700">
                            Quantidade:{" "}
                          </span>
                          <Badge color="failure" className="w-fit">
                            {data?.protestos?.retorno?.protestos?.length}
                          </Badge>
                        </div>
                      </div>
                      <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                        <Table striped>
                          <Table.Head>
                            <Table.HeadCell className="text-center bg-red-300">
                              Cartório
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Cidade
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Qtd.
                            </Table.HeadCell>
                            <Table.HeadCell className="text-center bg-red-300">
                              Telefone
                            </Table.HeadCell>
                          </Table.Head>

                          <Table.Body className="divide-y">
                            {data?.protestos?.retorno?.protestos?.map(
                              (protesto, index) => (
                                <Table.Row
                                  key={index}
                                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                >
                                  <Table.Cell className="text-center">
                                    {protesto.cartorio}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {protesto.cidade}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {protesto.quantidadeprotestos}
                                  </Table.Cell>
                                  <Table.Cell className="text-center">
                                    {protesto.telefone}
                                  </Table.Cell>
                                </Table.Row>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <Card className={`bg-green-200 mt-4`}>
                      <div className="flex gap-2 items-center text-gray-600 font-semibold">
                        <div>
                          <IoAlertCircleOutline fontSize={20} />
                        </div>
                        <div>Nada consta</div>
                      </div>
                    </Card>
                  )}
                </div>
              </div>

              <div>
                <TitleStripe className="mb-5">Últimas consultas</TitleStripe>

                {data?.spc?.consultaRealizada
                  ?.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista?.length >
                0 ? (
                  <>
                    <div className="overflow-x-auto shadow-lg rounded-lg mt-3">
                      <Table striped>
                        <Table.Head>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Data
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Associado
                          </Table.HeadCell>
                          <Table.HeadCell className="text-center bg-gray-300">
                            Entidade
                          </Table.HeadCell>
                        </Table.Head>

                        <Table.Body className="divide-y">
                          {data?.spc?.consultaRealizada?.detalheGrafiaPjOrDetalheSpcOrDetalheChequeLojista?.map(
                            (consult, index) => (
                              <Table.Row
                                key={index}
                                className="bg-white dark:border-gray-700 dark:bg-gray-800"
                              >
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white text-center">
                                  {moment(consult.dataConsulta).format(
                                    "DD/MM/yyyy"
                                  )}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {consult.nomeAssociado}
                                </Table.Cell>
                                <Table.Cell className="text-center">
                                  {consult.nomeEntidadeOrigem}
                                </Table.Cell>
                              </Table.Row>
                            )
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Card className={`bg-green-200 mt-4`}>
                    <div className="flex gap-2 items-center text-gray-600 font-semibold">
                      <div>
                        <IoAlertCircleOutline fontSize={20} />
                      </div>
                      <div>Nada consta</div>
                    </div>
                  </Card>
                )}

                <FooterQuery hasHeader={true} />
              </div>
            </Card>
          ) : (
            <QueryLoading />
          )}
        </div>
      )}
    </>
  );
};

export default AvalieQuery;
