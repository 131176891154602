import moment from "moment";
import React, { useState } from "react";
import FilterToolbar from "../inputs/FilterToolbar";
import { Badge, Button, Pagination, Table } from "flowbite-react";
import { FaArrowsLeftRight } from "react-icons/fa6";
import axios from "../../config/axiosInstance";
import { useQuery, useQueryClient } from "react-query";
import { ADMIN, CATEGORIES } from "../../config/apiConfig";
import { TbLockSearch } from "react-icons/tb";
import Drawer from "../Drawer";
import CompanyQueriesForm from "../forms/CompanyQueriesForm";
import { IoMdAdd, IoMdSearch } from "react-icons/io";
import { Link } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import CompanyEditForm from "../forms/CompanyEditForm";
import EmptyTable from "./EmptyTable";
import TableLoading from "../TableLoading";
import { Tooltip } from "react-tooltip";

const AdminRepresentativesTable = () => {
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const onPageChange = (page) => setCurrentPage(page);
  const [searchName, setSearchName] = useState("");
  const [nameParam, setNameParam] = useState("");
  const [isDrawerPermissionQueries, setIsDrawerPermissionQueries] =
    useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isDrawerEditRepresentative, setIsDrawerEditRepresentative] =
    useState(false);

  const {
    isLoadingListRepresentatives,
    error: errorListRepresentatives,
    data: listRepresentatives,
  } = useQuery(["listRepresentatives", currentPage, nameParam], async () => {
    const { data } = await axios.get(
      `${ADMIN.GET_REPRESENTATIVES}?page=${currentPage}&search=${nameParam}`
    );

    setTotalPages(data.data.totalPages || 1);
    setTotalCount(data.data.totalCount || 0);

    return data.data.data;
  });

  const {
    isLoadingCategories,
    error: errorCategories,
    data: categories,
  } = useQuery(["categories", currentPage], async () => {
    const { data } = await axios.get(
      `${CATEGORIES.GET_ALL}?page=${currentPage}`
    );

    return data.data.data;
  });

  const handleSearchName = (e) => {
    e.preventDefault();
    setNameParam(searchName);
  };

  const handleEditRepresentative = (company) => {
    setSelectedCompany(company);
    setIsDrawerEditRepresentative((prevState) => !prevState);
  };

  const handleEditPermissionQueries = (company) => {
    setSelectedCompany(company);
    setIsDrawerPermissionQueries((prevState) => !prevState);
  };

  return (
    <>
      <div className="flex justify-end mb-3 mt-2">
        <Link
          to="/admin/new-representative"
          className="bg-primary ml-auto md:ml-0 mb-5 md:mb-0 fixed md:relative bottom-4 md:bottom-0 right-6 md:right-0 z-10 md:z-0 rounded-full md:rounded-md shadow-md md:shadow-md h-14 md:h-auto w-14 md:w-auto flex md:block justify-center items-center px-4 py-2 text-white"
          variant="contained"
        >
          <IoMdAdd className="block md:hidden text-2xl" />

          <span className="hidden md:block">Adicionar Representante</span>
        </Link>
      </div>
      <form
        className="flex gap-1 mt-5 w-full pl-3 pr-2 py-2 md:py-1 rounded-md shadow-sm bg-gray-300"
        onSubmit={handleSearchName}
      >
        <input
          type="text"
          placeholder="Busque por nome, telefone ou e-mail do representante..."
          className="border-0 py-1 text-sm mt-1 focus:outline-none focus:ring-0 focus:border-gray-300 px-0 w-full bg-gray-300 placeholder:text-gray-700"
          onChange={(e) => setSearchName(e.target.value)}
          value={searchName}
        />
        <Button
          size="sm"
          className="flex items-center bg-secondary w-20"
          type="submit"
        >
          <IoMdSearch />
        </Button>
      </form>

      {listRepresentatives?.length > 0 || isLoadingListRepresentatives ? (
        <>
          <small
            className="gap-2 items-center mt-5 mb-1 flex md:hidden justify-center"
            style={{ fontSize: 10 }}
          >
            <span>
              Deslize para o lado para visualizar todas as informações
            </span>{" "}
            <FaArrowsLeftRight />
          </small>
          <div className="overflow-x-auto shadow-md rounded-lg mt-0 md:mt-5">
            <Table striped>
              <Table.Head>
                <Table.HeadCell className="text-center bg-gray-300">
                  Representante
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Tipo
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Telefone
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  E-mail
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Ações
                </Table.HeadCell>
              </Table.Head>

              <Table.Body className="divide-y">
                {!isLoadingListRepresentatives ? (
                  <>
                    {listRepresentatives?.length > 0 && (
                      <>
                        {listRepresentatives.map((representative, index) => (
                          <Table.Row
                            key={index}
                            className="bg-white dark:border-gray-700 dark:bg-gray-800"
                          >
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                              {representative.typeCustomer === "CPF"
                                ? representative.name
                                : representative.socialReason}
                            </Table.Cell>
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                              {representative.typeCustomer === "CPF"
                                ? "Física"
                                : "Jurídica"}
                            </Table.Cell>
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                              {representative.phoneNumber}
                            </Table.Cell>
                            <Table.Cell className="text-center py-2">
                              {representative.email}
                            </Table.Cell>
                            <Table.Cell className="text-center py-2">
                              <div className="flex gap-2 justify-center items-center flex-wrap">
                                <Tooltip
                                  id="tooltip-edit"
                                  content="Editar permissões"
                                />
                                <TbLockSearch
                                  data-tooltip-id="tooltip-edit"
                                  onClick={() =>
                                    handleEditPermissionQueries(representative)
                                  }
                                  fontSize="16"
                                  className="text-gray-500 cursor-pointer"
                                />
                                <Tooltip
                                  id="tooltip-edit-representative"
                                  content="Editar Representante"
                                />
                                <FaRegEdit
                                  data-tooltip-id="tooltip-edit-representative"
                                  fontSize="16"
                                  className="text-gray-500 cursor-pointer"
                                  onClick={() =>
                                    handleEditRepresentative(representative)
                                  }
                                />
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  <TableLoading cols={5} />
                )}
              </Table.Body>
            </Table>
          </div>

          {totalCount > 0 && (
            <div className="mt-3 text-center text-sm">
              Foram encontrados {totalCount} registros no total
            </div>
          )}

          {totalPages > 1 && (
            <div className="flex overflow-x-auto justify-center mt-3">
              <Pagination
                layout="pagination"
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
                previousLabel=""
                nextLabel=""
                showIcons
              />
            </div>
          )}
        </>
      ) : (
        <EmptyTable icon="person_off">
          Nenhum representante cadastrado
        </EmptyTable>
      )}
      <Drawer
        title={`Permissões de consultas`}
        onClose={setIsDrawerPermissionQueries}
        isOpen={isDrawerPermissionQueries}
        className={"w-full md:w-4/6 lg:w-2/6"}
      >
        <CompanyQueriesForm
          company={selectedCompany}
          categories={categories}
          onClose={setIsDrawerPermissionQueries}
        />
      </Drawer>
      <Drawer
        title={`Editar representante`}
        onClose={setIsDrawerEditRepresentative}
        isOpen={isDrawerEditRepresentative}
        className={"w-full md:w-4/6 lg:w-2/6"}
      >
        <CompanyEditForm
          company={selectedCompany}
          onClose={setIsDrawerEditRepresentative}
          fetchCompanies={() =>
            queryClient.invalidateQueries(["listRepresentatives", currentPage])
          }
        />
      </Drawer>
    </>
  );
};

export default AdminRepresentativesTable;
