import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Spinner,
  Table,
} from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import axios from "../../../config/axiosInstance";
import { QUERIES_VEICULAR } from "../../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "../HeaderQuery";
import usePDF from "../../../hooks/usePDF";
import TitleStripe from "../../common/TitleStripe";
import CardQueryDescription from "../../common/CardQueryDescription";
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
  IoMdOptions,
} from "react-icons/io";
import SliderToPreview from "../../common/SliderToPreview";
import Loader from "../../common/Loader";
import FooterQuery from "../FooterQuery";
import { IoAlertCircleOutline } from "react-icons/io5";
import QueryVehicleInput from "../../inputs/QueryVehicleInput";

const FichaTecnicaQuery = ({
  query,
  selectedPrice,
  dataFromLocalStorage = null,
  hasHeaderPDF = "true",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    data
  );

  useEffect(() => {
    if (dataFromLocalStorage) {
      setData(dataFromLocalStorage?.dadosVeicular);
      setDocument(dataFromLocalStorage?.resumoRetorno?.document);
    }
  }, []);

  const fetchInfo = async (raw) => {
    return await axios(`${QUERIES_VEICULAR.GET_VEICULAR}/${query?.id}/${info}`);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);
    let isValid = true;

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados?.data?.dadosVeiculo || null);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setData(null);
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Informações técnicas",
          "Medidas",
          "Medição Combustível",
          "Equipamentos",
        ]}
        price={selectedPrice}
        imageBanner="/assets/bg_banner_car.jpg"
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <QueryVehicleInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="PLACA"
            />
            <div>
              {!errorQuery && data && (
                <Button
                  className={`w-fit shadow-lg secondary ${
                    data && !isLoading ? "block" : "hidden"
                  }`}
                  isProcessing={pdfURL === ""}
                  disabled={pdfURL === ""}
                  onClick={() => handleClickDownloadPDF()}
                >
                  Salvar como PDF
                </Button>
              )}
            </div>
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`overflow-x-scroll ${
              data || isLoading ? "block" : "hidden"
            }`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="relative overflow-hidden p-5 card-pdf">
                  <HeaderQuery title={query?.name} type="vehicle" />

                  <div>
                    <TitleStripe>Ficha Técnica </TitleStripe>
                    <div className="mt-4">
                      <div className="title-vehicle">Informações técnicas</div>
                      <div className="flex gap-12 mt-3 rounded bg-gray-100 p-2 mb-3">
                        <div className="text-sm min-w-72">
                          <div className="mb-3">
                            <span className="font-semibold">Câmbio: </span>
                            {data?.fichaTecnica?.cambio || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Direção: </span>
                            {data?.fichaTecnica?.direcao || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Motor: </span>
                            {data?.fichaTecnica?.motor || "Indisponível"}
                          </div>
                          <div>
                            <span className="font-semibold">
                              Quantidade Passageiros:{" "}
                            </span>
                            {data?.fichaTecnica?.quantidadePassageiros ||
                              "Indisponível"}
                          </div>
                        </div>
                        <div className="text-sm">
                          <div className="mb-3">
                            <span className="font-semibold">
                              Quantidade Portas:{" "}
                            </span>
                            {data?.fichaTecnica?.quantidadePortas ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Suspensão Dianteira:{" "}
                            </span>
                            {data?.fichaTecnica?.suspensaoDianteira ||
                              "Indisponível"}
                          </div>
                          <div>
                            <span className="font-semibold">
                              Suspensão Traseira:{" "}
                            </span>
                            {data?.fichaTecnica?.suspensaoTraseira ||
                              "Indisponível"}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="title-vehicle">Medidas</div>
                      <div className="flex gap-12 mt-3 rounded bg-gray-100 p-2 mb-3">
                        <div className="text-sm min-w-72">
                          <div className="mb-3">
                            <span className="font-semibold">Altura: </span>
                            {data?.fichaTecnica?.medidas?.altura ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Comprimento: </span>
                            {data?.fichaTecnica?.medidas?.comprimento ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Largura: </span>
                            {data?.fichaTecnica?.medidas?.largura ||
                              "Indisponível"}
                          </div>
                          <div>
                            <span className="font-semibold">Entre Eixos: </span>
                            {data?.fichaTecnica.medidas?.entreEixos ||
                              "Indisponível"}
                          </div>
                        </div>
                        <div className="text-sm">
                          <div className="mb-3">
                            <span className="font-semibold">Peso: </span>
                            {data?.fichaTecnica?.medidas?.peso ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Porta Malas: </span>
                            {data?.fichaTecnica.medidas?.portaMalas ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Tanque: </span>
                            {data?.fichaTecnica?.medidas?.tanque ||
                              "Indisponível"}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="title-vehicle mb-3">
                        Medição Combustível
                      </div>
                      {data?.fichaTecnica?.medicaoCombustivel?.length > 0 ? (
                        <div className="overflow-hidden border border-gray-300 rounded-lg">
                          <Table striped className="text-sm">
                            <Table.Head>
                              <Table.HeadCell className="text-center bg-gray-300 p-2">
                                Combustível
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300 p-2">
                                Potência
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300 p-2">
                                Clindr.
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300 p-2">
                                Vel. Max.
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300 p-2">
                                Torque
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300 p-2">
                                Cons. Cidade
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300 p-2">
                                Cons. Estrada
                              </Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                              {data?.fichaTecnica?.medicaoCombustivel?.map(
                                (medicao, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                                      {medicao.combustivel}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                                      {medicao.potencia}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                                      {medicao.cilindradas}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                                      {medicao.velocidadeMaxima}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                                      {medicao.torque}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                                      {medicao.consumoCidade}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center p-2">
                                      {medicao.consumoEstrada}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </Table.Body>
                          </Table>
                        </div>
                      ) : (
                        <Card className={`bg-orange-200 mt-4`}>
                          <div className="flex gap-2 items-center font-semibold text-gray-700">
                            <div>
                              <IoAlertCircleOutline fontSize={20} />
                            </div>
                            <div>Sem informação da medição</div>
                          </div>
                        </Card>
                      )}
                    </div>

                    <div className="title-vehicle mt-4">Equipamentos</div>
                    {data?.fichaTecnica?.equipamentos?.length !== 0 ? (
                      <>
                        <div className="container-alerts four-columns mt-4">
                          {data?.fichaTecnica?.equipamentos?.length > 0 &&
                            data?.fichaTecnica?.equipamentos?.map(
                              (equipamento, index) => (
                                <div
                                  className={`bg-gray-100 rounded px-2 py-3 flex items-center text-sm justify-center gap-2 text-center font-semibold capitalize border border-gray-300 ${
                                    equipamento?.disponibilidade?.toUpperCase() ===
                                    "DISPONIVEL"
                                      ? "text-green-500"
                                      : equipamento?.disponibilidade?.toUpperCase() ===
                                        "OPCIONAL"
                                      ? "text-blue-500"
                                      : "text-red-500"
                                  }`}
                                  key={index}
                                  style={{ fontSize: "11px" }}
                                >
                                  {equipamento?.disponibilidade?.toUpperCase() ===
                                  "DISPONIVEL" ? (
                                    <IoMdCheckmarkCircleOutline
                                      className="text-green-500"
                                      fontSize={17}
                                    />
                                  ) : (
                                    <>
                                      {equipamento?.disponibilidade?.toUpperCase() ===
                                      "OPCIONAL" ? (
                                        <IoMdOptions
                                          className="text-blue-500"
                                          fontSize={17}
                                        />
                                      ) : (
                                        <IoMdCloseCircleOutline
                                          className="text-red-500"
                                          fontSize={17}
                                        />
                                      )}
                                    </>
                                  )}
                                  {equipamento.descricao?.toLowerCase()}
                                </div>
                              )
                            )}
                        </div>
                      </>
                    ) : (
                      <div className={`bg-orange-200 mt-4 p-2 rounded-lg`}>
                        <div className="flex gap-2 items-center font-semibold text-gray-700">
                          <div>
                            <IoAlertCircleOutline fontSize={20} />
                          </div>
                          <div>Sem informação dos equipamentos</div>
                        </div>
                      </div>
                    )}
                  </div>
                  <FooterQuery hasHeader={hasHeaderPDF} />
                </Card>
              </>
            ) : (
              <Loader
                words={[
                  "Informações técnicas",
                  "Medidas",
                  "Medição Combustível",
                  "Equipamentos",
                ]}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default FichaTecnicaQuery;
