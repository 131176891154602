import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Spinner,
  Table,
} from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import axios from "../../../config/axiosInstance";
import { QUERIES_VEICULAR } from "../../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "../HeaderQuery";
import usePDF from "../../../hooks/usePDF";
import TitleStripe from "../../common/TitleStripe";
import CardQueryDescription from "../../common/CardQueryDescription";
import SliderToPreview from "../../common/SliderToPreview";
import { formatToReal } from "../../../utils/utils";
import Loader from "../../common/Loader";
import FooterQuery from "../FooterQuery";
import NoOcurrence from "../../common/query/NoOcurrence";
import QueryVehicleInput from "../../inputs/QueryVehicleInput";

const PrecificacaoQuery = ({
  query,
  selectedPrice,
  dataFromLocalStorage = null,
  hasHeaderPDF = "true",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    data
  );

  useEffect(() => {
    if (dataFromLocalStorage) {
      setData(dataFromLocalStorage?.dadosVeicular);
      setDocument(dataFromLocalStorage?.resumoRetorno?.document);
    }
  }, []);

  const fetchInfo = async (raw) => {
    return await axios(`${QUERIES_VEICULAR.GET_VEICULAR}/${query?.id}/${info}`);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);
    let isValid = true;

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados?.data?.dadosVeiculo || null);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setData(null);
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={["Precificador"]}
        price={selectedPrice}
        imageBanner="/assets/bg_banner_car.jpg"
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <QueryVehicleInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="PLACA"
            />
            <div>
              {!errorQuery && data && (
                <Button
                  className={`w-fit shadow-lg secondary ${
                    data && !isLoading ? "block" : "hidden"
                  }`}
                  isProcessing={pdfURL === ""}
                  disabled={pdfURL === ""}
                  onClick={() => handleClickDownloadPDF()}
                >
                  Salvar como PDF
                </Button>
              )}
            </div>
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`overflow-x-scroll ${
              data || isLoading ? "block" : "hidden"
            }`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="relative overflow-hidden p-5 card-pdf">
                  <HeaderQuery title={query?.name} type="vehicle" />

                  {data?.precificador?.statusRetorno?.codigo === "1" && (
                    <div>
                      <TitleStripe>Precificador</TitleStripe>
                      {data?.precificador?.ocorrencias?.length === 0 ? (
                        <NoOcurrence />
                      ) : (
                        <div className="overflow-hidden rounded-lg border border-gray-300 mt-3">
                          <Table striped className="w-full text-xs">
                            <Table.Head>
                              <Table.HeadCell className="bg-gray-300 py-1 px-1">
                                Fabr./Modelo
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300 py-1 px-1">
                                Ano
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300 py-1 px-1">
                                Informante
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300 py-1 px-1">
                                Código
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300 py-1 px-1">
                                Preço
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300 py-1 px-1">
                                Preço Médio
                              </Table.HeadCell>
                              <Table.HeadCell className="text-center bg-gray-300 py-1 px-1">
                                Vigência
                              </Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                              {data?.precificador?.ocorrencias?.map(
                                (preco, index) => (
                                  <Table.Row
                                    key={index}
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                  >
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white py-1 px-1">
                                      {preco?.fabricanteModelo || "-"}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-1 px-1">
                                      {preco?.anoModelo || "-"}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-1 px-1">
                                      {preco?.informante || "-"}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-1 px-1">
                                      {preco?.codigo || "-"}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-1 px-1">
                                      <Badge className="w-fit mx-auto">
                                        {formatToReal(
                                          preco?.preco?.replace(",", ".")
                                        ) || "-"}
                                      </Badge>
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-1 px-1">
                                      {preco?.precoMedio || "-"}
                                    </Table.Cell>
                                    <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-1 px-1">
                                      {preco?.vigencia || "-"}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )}
                            </Table.Body>
                          </Table>
                        </div>
                      )}
                    </div>
                  )}

                  <FooterQuery hasHeader={hasHeaderPDF} />
                </Card>
              </>
            ) : (
              <Loader words={["Precificador"]} />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default PrecificacaoQuery;
