import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Label,
  Pagination,
  Table,
} from "flowbite-react";
import { FaArrowsLeftRight } from "react-icons/fa6";
import axios from "../../config/axiosInstance";
import { useQuery, useQueryClient } from "react-query";
import { ADMIN } from "../../config/apiConfig";
import { formatToReal } from "../../utils/utils";
import AutoCompleteInput from "../inputs/Autocomplete";
import EmptyTable from "./EmptyTable";
import { BsFillBarChartFill } from "react-icons/bs";
import DrawerLoading from "../DrawerLoading";
import TableLoading from "../TableLoading";
import { FaRegEdit, FaUsers } from "react-icons/fa";
import UserForm from "../forms/UserForm";
import Drawer from "../Drawer";
import { Tooltip } from "react-tooltip";
import { IoMdSearch } from "react-icons/io";

const AdminUsersTable = () => {
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalPriceSum, setTotalPriceSum] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchName, setSearchName] = useState("");
  const [nameParam, setNameParam] = useState("");
  const onPageChange = (page) => setCurrentPage(page);
  const [selectedCustomer, setSelectedCustomer] = useState({
    label: "Todos os clientes",
    value: 0,
  });
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isDrawerEditUser, setIsDrawerEditUser] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const {
    isLoading: isLoadingCustomers,
    error: errorListCustomers,
    data: listCustomers,
  } = useQuery(["listCustomers"], async () => {
    try {
      const { data } = await axios.get(`${ADMIN.GET_CUSTOMERS}`);

      if (!data?.data?.data) {
        throw new Error("Erro ao buscar clientes");
      }

      let customers = data.data.data.map((customer) => ({
        label:
          customer.typeCustomer === "CPF"
            ? customer.name
            : customer.socialReason,
        value: customer.id,
      }));

      customers = [{ label: "Todos os clientes", value: 0 }, ...customers];

      return customers;
    } catch (error) {
      throw new Error(`Erro ao buscar clientes: ${error.message}`);
    }
  });

  const {
    isLoading: isLoadingListUsers,
    error: errorListUsers,
    data: listUsers,
  } = useQuery(
    [
      "listUsers",
      currentPage,
      selectedStatuses,
      startDate,
      endDate,
      selectedCustomer,
      nameParam,
    ],
    async () => {
      const { data } = await axios.get(
        `${ADMIN.GET_USERS}?page=${currentPage}&status=${selectedStatuses}&companyId=${selectedCustomer?.value}&startDate=${startDate}&endDate=${endDate}&search=${nameParam}`
      );

      setTotalPages(data.data.totalPages || 1);
      setTotalCount(data.data.totalCount || 0);

      return data.data.data;
    }
  );

  const handleSearchName = (e) => {
    e.preventDefault();
    setNameParam(searchName);
  };

  const handleCheckboxChange = (status, setState) => {
    setState((prev) =>
      prev.includes(status)
        ? prev.filter((item) => item !== status)
        : [...prev, status]
    );
  };

  // const handleSearchName = (e) => {
  //   e.preventDefault();
  //   setNameParam(searchName);
  // };

  const handleEditCustomer = (user) => {
    setSelectedUser(user);
    setIsDrawerEditUser((prevState) => !prevState);
  };

  return (
    <div className="flex md:flex-row flex-col gap-4">
      <div className="w-full md:w-80 bg-white rounded-md shadow-md p-4 relative">
        {isLoadingCustomers && <DrawerLoading />}
        <div className="title-syncx">Filtro avançado</div>
        <div className="mt-6">
          <AutoCompleteInput
            options={listCustomers}
            label="Cliente"
            onChange={(customer) => setSelectedCustomer(customer)}
            value={selectedCustomer?.value}
            isLoading={isLoadingCustomers}
          />
          {errorListCustomers && (
            <Alert color="failure">Ocorreu um erro ao buscar clientes</Alert>
          )}
        </div>

        <div className="font-semibold mt-4 text-sm mb-2">Criado em</div>
        <div className="flex items-center gap-1">
          <input
            type="date"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
              if (moment(e.target.value).isAfter(moment(endDate))) {
                setEndDate(e.target.value);
              }
            }}
            className="w-full block py-3 md:py-2 px-2 rounded-md text-xs text-gray-600 bg-gray-100 border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          />
          <div className="text-xs">Até</div>
          <input
            type="date"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
            min={startDate}
            className="w-full block py-3 md:py-2 px-2 rounded-md text-xs text-gray-600 bg-gray-100 border-0 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
          />
        </div>
        <div className="mt-4 text-sm">
          <div className="font-semibold">Status</div>
          <ul className="mt-2 flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <Checkbox
                id="ativo"
                checked={selectedStatuses.includes(1)}
                onChange={() => handleCheckboxChange(1, setSelectedStatuses)}
              />
              <Label htmlFor="ativo" className="flex">
                Ativo
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                id="Bloqueado"
                checked={selectedStatuses.includes(0)} // 0 = Falha
                onChange={() => handleCheckboxChange(0, setSelectedStatuses)}
              />
              <Label htmlFor="Bloqueado" className="flex">
                Bloqueado
              </Label>
            </div>
          </ul>
        </div>
      </div>
      <div className="flex-1">
        <div className="title-syncx mt-3 mb-4">Resultado da busca</div>
        <div className="overflow-x-scroll">
          <div className="flex gap-3 pb-3" style={{ minWidth: "500px" }}>
            <Card
              className="w-full bg-gray-700 relative"
              style={{ minWidth: "150px" }}
            >
              <div className="text-sm md:text-md lg:text-lg text-white">
                Total de usuários:
              </div>
              <div className="text-2xl text-white font-semibold flex gap-2 items-center">
                {!isLoadingListUsers ? (
                  `${totalCount}`
                ) : (
                  <div role="status" className="max-w-sm animate-pulse inline">
                    <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                  </div>
                )}
              </div>
              <BsFillBarChartFill className="absolute bottom-0 right-2 text-white text-9xl opacity-10" />
            </Card>
            <Card className="w-full bg-green-700 relative overflow-hidden">
              <div className="text-sm md:text-md lg:text-lg text-white">
                Total de usuários ativos:
              </div>
              <div className="text-2xl text-white font-semibold">
                {!isLoadingListUsers ? (
                  `${totalCount}`
                ) : (
                  <div role="status" className="max-w-sm animate-pulse inline">
                    <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                  </div>
                )}
              </div>
              <FaUsers className="absolute -bottom-3 right-2 text-white text-9xl opacity-10" />
            </Card>
          </div>
        </div>

        <form
          className="flex gap-1 mt-5 w-full pl-3 pr-2 py-2 md:py-1 rounded-md shadow-sm bg-gray-300"
          onSubmit={handleSearchName}
        >
          <input
            type="text"
            placeholder="Busque por nome do usuário..."
            className="border-0 py-1 text-sm mt-1 focus:outline-none focus:ring-0 focus:border-gray-300 px-0 w-full bg-gray-300 placeholder:text-gray-700"
            onChange={(e) => setSearchName(e.target.value)}
            value={searchName}
          />
          <Button
            size="sm"
            className="flex items-center bg-secondary w-20"
            type="submit"
          >
            <IoMdSearch />
          </Button>
        </form>

        <small
          className="gap-2 items-center mt-5 mb-1 flex md:hidden justify-center"
          style={{ fontSize: 10 }}
        >
          <span>Deslize para o lado para visualizar todas as informações</span>{" "}
          <FaArrowsLeftRight />
        </small>
        {listUsers?.length > 0 || isLoadingListUsers ? (
          <div className="overflow-x-auto shadow-md rounded-lg mt-0 md:mt-5">
            <Table striped style={{ fontSize: "13px" }}>
              <Table.Head>
                <Table.HeadCell className="text-center bg-gray-300">
                  Código de acesso
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Usuário
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  E-mail
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Cliente
                </Table.HeadCell>
                <Table.HeadCell className="text-center bg-gray-300">
                  Ações
                </Table.HeadCell>
              </Table.Head>

              <Table.Body className="divide-y">
                {!isLoadingListUsers ? (
                  <>
                    {listUsers?.length > 0 && (
                      <>
                        {listUsers.map((user, index) => (
                          <Table.Row
                            key={index}
                            className="bg-white dark:border-gray-700 dark:bg-gray-800"
                          >
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                              {user.accessCode || "-"}
                            </Table.Cell>
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                              {user.name}
                            </Table.Cell>
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                              {user.email}
                            </Table.Cell>
                            <Table.Cell className="font-medium text-gray-900 dark:text-white text-center py-2">
                              {user?.Company?.typeCustomer === "CPF"
                                ? user?.Company?.name
                                : user?.Company?.socialReason}
                            </Table.Cell>
                            <Table.Cell className="text-center py-2">
                              <div className="flex gap-2 justify-center items-center flex-wrap">
                                <Tooltip
                                  id="tooltip-edit-user"
                                  content="Editar Usuário"
                                />
                                <FaRegEdit
                                  data-tooltip-id="tooltip-edit-user"
                                  fontSize="18"
                                  onClick={() => handleEditCustomer(user)}
                                  className="text-gray-500 cursor-pointer"
                                />
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  <TableLoading cols={5} />
                )}
              </Table.Body>
            </Table>
          </div>
        ) : (
          <EmptyTable icon="search_off">
            Nenhum registro encontrado com esses filtros
          </EmptyTable>
        )}

        {totalPages > 1 && (
          <div className="flex overflow-x-auto justify-center mt-3">
            <Pagination
              layout="pagination"
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={onPageChange}
              previousLabel=""
              nextLabel=""
              showIcons
            />
          </div>
        )}
      </div>

      <Drawer
        title={`Editar cliente`}
        onClose={setIsDrawerEditUser}
        isOpen={isDrawerEditUser}
        className={"w-full md:w-4/6 lg:w-2/6"}
      >
        <UserForm
          user={selectedUser}
          onClose={setIsDrawerEditUser}
          isOpen={isDrawerEditUser}
          type={"EDIT"}
          fetchUsers={() => {
            queryClient.invalidateQueries(["listUsers"]);
          }}
        />
      </Drawer>
    </div>
  );
};

export default AdminUsersTable;
