import { Alert, Badge, Button, Card } from "flowbite-react";
import React, { useEffect, useRef, useState } from "react";
import axios from "../../../config/axiosInstance";
import { QUERIES_VEICULAR } from "../../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "../HeaderQuery";
import usePDF from "../../../hooks/usePDF";
import TitleStripe from "../../common/TitleStripe";
import CardQueryDescription from "../../common/CardQueryDescription";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import SliderToPreview from "../../common/SliderToPreview";
import Loader from "../../common/Loader";
import FooterQuery from "../FooterQuery";
import moment from "moment";
import { MdBlock } from "react-icons/md";
import QueryVehicleInput from "../../inputs/QueryVehicleInput";

const NacionalProvedorUnicoQuery = ({
  query,
  selectedPrice,
  dataFromLocalStorage = null,
  hasHeaderPDF = "true",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [errorQuery, setErrorQuery] = useState("");
  const [data, setData] = useState(null);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    data
  );

  useEffect(() => {
    if (dataFromLocalStorage) {
      setData(dataFromLocalStorage?.dadosVeicular);
      setDocument(dataFromLocalStorage?.resumoRetorno?.document);
    }
  }, []);

  const fetchInfo = async (raw) => {
    return await axios(`${QUERIES_VEICULAR.GET_VEICULAR}/${query?.id}/${info}`);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);
    let isValid = true;

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setError("");
        setErrorQuery("");
        const { data: dados } = await fetchInfo();
        setData(dados?.data?.dadosVeiculo || null);
      } catch (error) {
        setErrorQuery(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setData(null);
    }
  };

  return (
    <>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={["Informações do veículo"]}
        price={selectedPrice}
        imageBanner="/assets/bg_banner_car.jpg"
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <QueryVehicleInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="PLACA/CHASSI"
            />
            <div>
              {!errorQuery && data && (
                <Button
                  className={`w-fit shadow-lg secondary ${
                    data && !isLoading ? "block" : "hidden"
                  }`}
                  isProcessing={pdfURL === ""}
                  disabled={pdfURL === ""}
                  onClick={() => handleClickDownloadPDF()}
                >
                  Salvar como PDF
                </Button>
              )}
            </div>
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorQuery ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorQuery}</span>
        </Alert>
      ) : (
        <>
          <div
            className={`overflow-x-scroll ${
              data || isLoading ? "block" : "hidden"
            }`}
            ref={componentRef}
          >
            {!isLoading ? (
              <>
                <Card className="relative overflow-hidden p-5 card-pdf">
                  <HeaderQuery title={query?.name} type="vehicle" />

                  <div>
                    <div className="w-20 mt-4">
                      <img
                        src={`data:image/png;base64, ${data?.binNacional?.logoFabricante?.imagemBase64}`}
                        className="w-full"
                      />
                    </div>
                    <TitleStripe>
                      <div className="flex gap-2 items-center">
                        Informações do veículo | Base Nacional
                        <img className="w-5" src="/assets/base_nacional.png" />
                      </div>
                    </TitleStripe>
                    <div className="flex gap-10 mt-4">
                      <div className="text-sm flex-1">
                        <div className="p-2 rounded bg-gray-100 grid grid-cols-2">
                          <div className="mb-3">
                            <span className="font-semibold">
                              Marca/Modelo:{" "}
                            </span>
                            {data?.binNacional?.marcaModelo || "Sem info."}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Ano Fabr./Modelo:{" "}
                            </span>
                            {`${data?.binNacional?.anoFabricacao}/${data?.binNacional?.anoModelo}` ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tempo de circulação:{" "}
                            </span>
                            {`${
                              moment().format("YYYY") -
                              +data?.binNacional?.anoFabricacao
                            } anos` || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Categoria: </span>
                            {data?.binNacional?.CATEGORIA_VEICULO ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo de veículo:{" "}
                            </span>
                            {data?.binNacional?.TIPO_VEICULO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Placa: </span>
                            {document || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">RENAVAM: </span>
                            {data?.binNacional?.RENAVAM || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">chassi: </span>
                            {data?.binNacional?.chassi || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Situação: </span>
                            {data?.binNacional?.SITUACAO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Câmbio: </span>
                            {data?.binNacional?.CAIXA_CAMBIO || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Capacidade Carga:{" "}
                            </span>
                            {data?.binNacional?.CAPACIDADE_CARGA ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Cilindrada: </span>
                            {data?.binNacional?.CILINDRADA || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">CMT: </span>
                            {data?.binNacional?.CMT || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Combustível: </span>
                            {data?.binNacional?.combustivel || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Cor: </span>
                            {data?.binNacional?.corVeiculo || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Emplacamento:{" "}
                            </span>
                            {data?.binNacional?.dataEmplacamento ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Espécie: </span>
                            {data?.binNacional?.especieVeiculo ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Município: </span>
                            {data?.binNacional?.municipio || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">uf: </span>
                            {data?.binNacional?.uf || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Nº Carroceria:{" "}
                            </span>
                            {data?.binNacional?.numeroCarroceria ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Nº DI: </span>
                            {data?.binNacional?.numeroDI || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data registro DI:{" "}
                            </span>
                            {data?.binNacional?.dataRegistroDI ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Nº Eixo Auxiliar:{" "}
                            </span>
                            {data?.binNacional?.numeroEixoAuxiliar ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Nº Eixo Traseiro:{" "}
                            </span>
                            {data?.binNacional?.numeroEixoTrazeiro ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Qtd. Eixos: </span>
                            {data?.binNacional?.numeroEixos || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Nº Motor: </span>
                            {data?.binNacional?.numeroMotor || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">PBT: </span>
                            {data?.binNacional?.PBT || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Potência: </span>
                            {data?.binNacional?.potenciaVeiculo ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Procedência: </span>
                            {data?.binNacional?.procedencia || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Qtd. Passageiros:{" "}
                            </span>
                            {data?.binNacional?.quantidadePassageiros ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Situação do chassi:{" "}
                            </span>
                            {data?.binNacional?.situacaoChassi ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo de Carroceria:{" "}
                            </span>
                            {data?.binNacional?.tipoCarroceria ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo Doc. Faturado:{" "}
                            </span>
                            {data?.binNacional?.tipoDocumentoFaturado ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo Doc. Importador:{" "}
                            </span>
                            {data?.binNacional?.tipoDocumentoImportador ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Nº Importador:{" "}
                            </span>
                            {data?.binNacional?.numeroIdentificacaoImportador ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Tipo Op. Importação:{" "}
                            </span>
                            {data?.binNacional?.tipoOperacaoImportacaoVeiculo ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Montagem: </span>
                            {data?.binNacional?.tipoMontagem || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">Nº Faturado: </span>
                            {data?.binNacional?.numeroIdentificacaoFaturado ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              uf Faturamento:{" "}
                            </span>
                            {data?.binNacional?.ufFaturamento || "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data Limite Rest. Tributária:{" "}
                            </span>
                            {data?.binNacional
                              ?.DATA_LIMITE_RESTRICAO_TRIBUTARIA ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data Atualização:{" "}
                            </span>
                            {data?.binNacional?.dataAtualizacao ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Código Órgão SRF:{" "}
                            </span>
                            {data?.binNacional?.codigoOrgaoSRF ||
                              "Indisponível"}
                          </div>
                          <div className="mb-3">
                            <span className="font-semibold">
                              Data Emissão Último CRV:{" "}
                            </span>
                            {data?.binNacional?.dataEmissaoUltimoCRV ||
                              "Indisponível"}
                          </div>
                        </div>
                        <div className="mt-4 mb-4">
                          <div className="title-vehicle">
                            Restrições | Nacional
                          </div>
                          {data?.binNacional?.restricoes
                            ?.existeRestricaoRouboFurto === "0" &&
                          data?.binNacional?.restricoes?.veiculoBaixado ===
                            "0" &&
                          data?.binNacional?.restricoes
                            ?.existeRestricaoRenajud === "0" &&
                          data?.binNacional?.restricoes
                            ?.existeRestricaoGeral === "0" ? (
                            <div className="flex gap-2 items-center font-semibold mt-3">
                              <IoMdCheckmarkCircleOutline color="green" />
                              <div>Não constam restrições</div>
                            </div>
                          ) : (
                            <div className="flex flex-col gap-2 mt-3">
                              {data?.binNacional?.restricoes
                                ?.existeRestricaoRouboFurto === "1" && (
                                <div className="flex gap-2 items-center font-semibold">
                                  <MdBlock color="red" />
                                  <div>Restrição: Roubo e furto</div>
                                </div>
                              )}

                              {data?.binNacional?.restricoes
                                ?.existeRestricaoRenajud === "1" && (
                                <div className="flex gap-2 items-center font-semibold">
                                  <MdBlock color="red" />
                                  <div>Restrição: renajud</div>
                                </div>
                              )}

                              {data?.binNacional?.restricoes
                                ?.existeRestricaoGeral === "1" && (
                                <div className="flex gap-2 items-center font-semibold">
                                  <MdBlock color="red" />
                                  <div>Restrição: Geral</div>
                                </div>
                              )}

                              {data?.binNacional?.restricoes?.veiculoBaixado ===
                                "1" && (
                                <div className="flex gap-2 items-center font-semibold">
                                  <MdBlock color="red" />
                                  <div>Restrição: Veículo Baixado</div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="title-vehicle">
                            Proprietário | Nacional
                          </div>
                          <div className="mt-3">
                            <span className="font-semibold">Nome:</span>{" "}
                            {data?.binNacional?.proprietario?.nome ||
                              "Indisponível"}
                          </div>
                          <div className="mt-2">
                            <span className="font-semibold">Documento:</span>{" "}
                            {data?.binNacional?.proprietario?.documento ||
                              "Indisponível"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <FooterQuery hasHeader={hasHeaderPDF} />
                </Card>
              </>
            ) : (
              <Loader words={["Informações do veículo"]} />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default NacionalProvedorUnicoQuery;
