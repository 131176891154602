import { Badge, Table } from "flowbite-react";
import { formatToReal } from "../../../utils/utils";

const ProtestosDefineLimite = ({
  protestos,
  quantidadeTotal,
  primeiroValor,
  valorTotal,
}) => {
  return (
    <>
      <div className="flex gap-6">
        {quantidadeTotal && (
          <div className="flex items-center gap-2">
            <span className="font-semibold text-red-700">Quantidade: </span>
            <Badge color="failure">{quantidadeTotal}</Badge>
          </div>
        )}

        {primeiroValor && (
          <div className="flex items-center gap-2">
            <span className="font-semibold text-red-700">Primeiro valor: </span>
            <Badge color="failure">{formatToReal(primeiroValor)}</Badge>
          </div>
        )}

        {valorTotal && (
          <div className="flex items-center gap-2">
            <span className="font-semibold text-red-700">Valor Total: </span>
            <Badge color="failure">{formatToReal(valorTotal)}</Badge>
          </div>
        )}
      </div>
      <div className="overflow-x-auto border-gray-100 border rounded-lg mt-3">
        <Table striped>
          <Table.Head>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Data
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Valor
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Cartório
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Cidade
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Estado
            </Table.HeadCell>
            <Table.HeadCell
              className="text-center text-black"
              style={{ background: "#76B9FB" }}
            >
              Vencimento
            </Table.HeadCell>
          </Table.Head>
          {protestos?.length > 0 && (
            <Table.Body className="divide-y">
              {protestos?.map((protesto, index) => (
                <Table.Row
                  key={index}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                >
                  <Table.Cell className="font-medium text-black  dark:text-white text-center py-1">
                    {protesto?.data || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center text-black py-1">
                    {formatToReal(protesto?.valor) || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center text-black  py-1">
                    {protesto?.cartorio || "-"}º cartório
                  </Table.Cell>
                  <Table.Cell className="text-center text-black py-1">
                    {protesto?.cidade || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center text-black py-1">
                    {protesto?.estado || "-"}
                  </Table.Cell>
                  <Table.Cell className="text-center text-black py-1">
                    {protesto?.vencimento || "-"}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </div>
    </>
  );
};

export default ProtestosDefineLimite;
