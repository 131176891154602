import React from "react";
import { useApplication } from "../../contexts/ApplicationContext";

const FooterQuery = ({ hasHeader = "true" }) => {
  const { user } = useApplication();
  return (
    <>
      {hasHeader === "true" && (
        <>
          {user?.Company?.showLogo && (
            <>
              <div className="mb-20"></div>
              <img
                className="absolute bottom-0 w-full left-0"
                src="/assets/banner_footer.png"
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default FooterQuery;
