import InputMask from "react-input-mask";

const PhoneInput = ({
  label,
  required,
  error,
  name,
  value,
  type = "text",
  register,
  ...props
}) => {
  return (
    <>
      <div className="w-full relative">
        <InputMask
          type={type}
          id={name}
          name={name}
          className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder={""}
          {...register(name, { required })}
          value={value}
          mask="(99) 99999-9999"
          {...props}
          maskChar={""}
          alwaysShowMask={false}
        />
        <label
          htmlFor={name}
          className="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
        >
          {label}
        </label>
      </div>
      {error && <small className="text-red-400">{error}</small>}
    </>
  );
};

export default PhoneInput;
