const TitleStripe = ({ children, className }) => {
  return (
    <div
      className={`px-2 py-1 bg-blue-100 font-semibold mt-5 text-xl ${className}`}
      style={{ fontVariant: "small-caps" }}
    >
      {children}
    </div>
  );
};

export default TitleStripe;
