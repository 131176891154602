import CompletaPlusCNPJQuery from "../queries/CompletaPlusCNPJQuery";
import PositivoPlusCNPJQuery from "../queries/PositivoPlusCNPJQuery";
import PositivoPlusCPFQuery from "../queries/PositivoPlusCPFQuery";
import SerasaPremiumCPFQuery from "../queries/SerasaPremiumCPFQuery";
import SerasaPremiumCNPJQuery from "../queries/SerasaPremiumCNPJQuery";
import CompletaPlusCNPJForm from "../forms/CompletaPlusCNPJForm";
import LeilaoPositivoQuery from "../queries/autos/LeilaoPositivoQuery";
import SerasaBasicoQuery from "../queries/SerasaBasicoQuery";
import ScrBacenScoreQuery from "../queries/ScrBacenScoreQuery";
import CompletaVeicularQuery from "../queries/CompletaVeicularQuery";
import CreditoPremiumCPFQuery from "../queries/CreditoPremiumCPFQuery";
import CreditoPremiumCNPJQuery from "../queries/CreditoPremiumCNPJQuery";
import AcertaCompletoCPFQuery from "../queries/AcertaCompletoCPFQuery";
import DefineLimiteQuery from "../queries/DefineLimiteQuery";
import DefineLimitePositivoQuery from "../queries/DefineLimitePositivoQuery";
import AcessoPrimeCNPJQuery from "../queries/AcessoPrimeCNPJQuery";
import AcessoPrimeCPFQuery from "../queries/AcessoPrimeCPFQuery";

export const componentsMap = {
  PositivoPlusCPFQuery,
  PositivoPlusCNPJQuery,
  CompletaPlusCNPJQuery,
  CompletaPlusCNPJForm,
  SerasaPremiumCPFQuery,
  SerasaPremiumCNPJQuery,
  LeilaoPositivoQuery,
  SerasaBasicoQuery,
  ScrBacenScoreQuery,
  CompletaVeicularQuery,
  CreditoPremiumCPFQuery,
  CreditoPremiumCNPJQuery,
  AcertaCompletoCPFQuery,
  DefineLimiteQuery,
  DefineLimitePositivoQuery,
  AcessoPrimeCNPJQuery,
  AcessoPrimeCPFQuery,
};
