import { Button, Label, TextInput } from "flowbite-react";
import {
  maskChassi,
  maskCNPJ,
  maskCPF,
  maskLicensePlate,
} from "../../utils/masks";
import { CgSpinner } from "react-icons/cg";
import { FaCheck, FaSearch } from "react-icons/fa";
import StateSelectInput from "./StateSelectInput";

const QueryVehicleInput = ({
  info,
  setInfo,
  isLoading,
  error,
  type = "PLACA",
  hasState = false,
  setState,
  stateValue,
}) => {
  const handleChange = (event) => {
    let valor = event.target.value;

    if (type === "PLACA") {
      valor = maskLicensePlate(valor);
    } else if (type === "CHASSI") {
      valor = maskChassi(valor);
    } else {
      if (valor?.length <= 7) {
        valor = maskLicensePlate(valor);
      } else {
        valor = maskChassi(valor);
      }
    }

    setInfo(valor);
  };

  return (
    <div className="flex items-center gap-2">
      <div className="relative">
        <div className={`relative`}>
          <input
            id="info"
            type="text"
            className={`block px-2.5 pb-2.5 pt-4 w-full text-xs text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 peer`}
            placeholder={""}
            value={info}
            maxLength={type === "PLACA" ? "8" : "18"}
            onChange={handleChange}
            required
          />
          <label
            htmlFor="info"
            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-3 scale-75 top-1 z-1 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
          >
            {type}
          </label>
        </div>
        {(info.length === 7 || info.length === 17) && (
          <FaCheck className="absolute right-2 top-4 text-green-500" />
        )}
      </div>
      {hasState && (
        <StateSelectInput stateValue={stateValue} setState={setState} />
      )}

      <Button
        className=" shadow-lg primary flex items-center justify-center"
        type="submit"
        isProcessing={isLoading}
        processingSpinner={<CgSpinner className="h-5 w-5 animate-spin" />}
        disabled={
          !(info.length === 7 || info.length === 17) ||
          hasState &&
          stateValue === ""
        }
        style={{ height: "43px" }}
      >
        <FaSearch />
      </Button>
      {error && <small className="text-red-600">{type} inválido!</small>}
    </div>
  );
};

export default QueryVehicleInput;
