import {
  Alert,
  Badge,
  Button,
  Card,
  Label,
  Spinner,
  Table,
  TextInput,
  Timeline,
} from "flowbite-react";
import React, { useRef, useState } from "react";
import {
  formatDateToDDMM,
  formatDateToYY,
  formatToReal,
  getImageURLScore,
  isValidCNPJ,
  maskCEP,
} from "../../utils/utils";
import axios from "../../config/axiosInstance";
import { QUERIES } from "../../config/apiConfig";
import { HiInformationCircle } from "react-icons/hi";
import HeaderQuery from "./HeaderQuery";
import { IoMdBusiness, IoMdCheckmarkCircleOutline } from "react-icons/io";
import usePDF from "../../hooks/usePDF";
import TitleStripe from "../common/TitleStripe";
import CardQueryDescription from "../common/CardQueryDescription";
import QueryInput from "../inputs/QueryInput";
import { FiAlertOctagon } from "react-icons/fi";
import SliderToPreview from "../common/SliderToPreview";
import QueryLoading from "../common/QueryLoading";
import { RiArrowDownSFill } from "react-icons/ri";
import NoOcurrence from "../common/query/NoOcurrence";
import Protestos from "../common/query/Protestos";
import ChequesSemFundos from "../common/query/ChequesSemFundos";
import ChequesSustados from "../common/query/ChequesSustados";
import PendenciasFinanceiras from "../common/query/PendenciasFinanceiras";
import ProtestosDirCartorio from "../common/query/ProtestosDirCartorio";
import AcoesCiveis from "../common/query/AcoesCiveis";
import FalenciaRecuperacaoJudicial from "../common/query/FalenciaRecuperacaoJudicial";
import QuadroSocietario from "../common/query/QuadroSocietario";
import HistoricoConsultas from "../common/query/HistoricoConsultas";
import AvisoCard from "../common/query/AvisoCard";
import FooterQuery from "./FooterQuery";
import ProtestosDefineLimite from "../common/query/ProtestosDefineLimite";

const DefineLimitePositivoQuery = ({ query, selectedPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [document, setDocument] = useState("");
  const [error, setError] = useState("");
  const [errorData, setErrorData] = useState("");
  const [data, setData] = useState(null);
  const [infoProtestos, setInfoProtestos] = useState(null);
  const [isDoneRequest, setIsDoneRequest] = useState(false);
  const [isLoadingProtestos, setIsLoadingProtestos] = useState(false);
  const componentRef = useRef();
  const { handleClickDownloadPDF, pdfURL, setPdfURL } = usePDF(
    componentRef,
    info,
    query?.id,
    isDoneRequest
  );
  const fetchDefineLimite = async (raw) => {
    return await axios(
      `${QUERIES.GET_DEFINE_LIMITE_POSITIVO}/${info.replace(/\D/g, "")}`
    );
  };

  const fetchInfoProtestos = async (raw) => {
    return await axios(
      `${QUERIES.GET_PROTESTO_NACIONAL}/${info.replace(/\D/g, "")}`
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDocument(info);

    const unmaskedValue = info.replace(/\D/g, "");
    let isValid = true;

    if (!isValidCNPJ(unmaskedValue)) {
      setError("CNPJ inválido!");
      isValid = false;
    }

    if (isValid) {
      try {
        setPdfURL("");
        setData(null);
        setIsLoading(true);
        setInfoProtestos(null);
        setIsLoadingProtestos(true);
        setIsDoneRequest(false);
        setError("");
        setErrorData("");

        // Fetch principal
        const { data: dados } = await fetchDefineLimite();
        setData(dados.data["defineLimitePositivo"]);
        setIsLoading(false);

        // Fetch secundário com tratamento de erro separado
        try {
          const protestos = await fetchInfoProtestos();
          setInfoProtestos(protestos.data.data);
        } catch (protestosError) {
          console.error("Erro ao buscar protestos:", protestosError);
          setInfoProtestos([]); // Define um valor padrão caso ocorra erro
        } finally {
          setIsLoadingProtestos(false);
        }
      } catch (error) {
        setErrorData(
          typeof error?.response?.data?.message !== "object"
            ? error?.response?.data?.message
            : "Algum erro ocorreu com sua consulta"
        );
        setIsLoading(false);
      } finally {
        setIsDoneRequest(true);
      }
    } else {
      setData(null);
    }
  };

  const calcMarkNegotiation = () => {
    switch (data?.decisao?.descricao?.toLowerCase()) {
      case "negociação não recomendada":
        return "14%";
      case "negociação em análise":
        return "48%";
      case "negociação recomendada":
        return "81%";
      default:
        return false;
    }
  };

  return (
    <div>
      <div className="text-lg font-semibold mb-3 title-syncx">
        {query?.name}
      </div>
      <CardQueryDescription
        title="Informações da consulta"
        infos={[
          "Dados Cadastrais",
          "Score",
          "Faturamento presumido",
          "Falência/Recuperação",
          "Ações cíveis",
          "Negativações",
          "Protestos",
          "Cheques sem fundos",
          "Cheques sustados",
        ]}
        price={selectedPrice}
      >
        <form
          className="flex flex-col gap-2 bg-white p-2 mt-2 rounded-lg shadow-md w-fit"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col md:flex-row gap-2 items-start md:items-center">
            <QueryInput
              info={info}
              setInfo={setInfo}
              isLoading={isLoading}
              error={error}
              type="CNPJ"
            />
            {!errorData && data && (
              <Button
                className={`w-full md:w-fit items-center shadow-lg secondary ${
                  data && !isLoading ? "flex" : "hidden"
                }`}
                isProcessing={pdfURL === ""}
                disabled={pdfURL === ""}
                onClick={() => handleClickDownloadPDF()}
              >
                Salvar como PDF
              </Button>
            )}
          </div>
        </form>
      </CardQueryDescription>

      <SliderToPreview />

      {errorData ? (
        <Alert color="failure" icon={HiInformationCircle}>
          <span className="font-medium">{errorData}</span>
        </Alert>
      ) : (
        <div
          className={`overflow-x-scroll ${
            data || isLoading ? "block" : "hidden"
          }`}
          ref={componentRef}
        >
          {!isLoading ? (
            <>
              <Card className="card-pdf relative overflow-hidden p-5">
                <HeaderQuery title={query?.name} />
                <TitleStripe>Dados Cadastrais</TitleStripe>
                <div className="mt-2">
                  <div className="flex gap-16">
                    <div className="text-sm flex-1">
                      <div className="mb-3 flex-1">
                        <span className="font-semibold">Razão Social: </span>
                        {data?.identificacao_completo?.razao_social ||
                          "Sem info."}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Nome Fantasia: </span>
                        {data?.identificacao_completo?.nome_fantasia || "-"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">
                          Natureza Jurídica:{" "}
                        </span>
                        {data?.identificacao_completo?.natureza_juridica || "-"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">CNPJ/MF: </span>
                        {document}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">I.E.: </span>
                        {data?.identificacao_completo?.inscricao_estadual ||
                          "-"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Segmento: </span>
                        {data?.identificacao_completo?.segmento || "-"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">CNAE Principal: </span>
                        {data?.identificacao_completo?.ramo_atividade_primario
                          ?.atividade || "-"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Fundação: </span>
                        {data?.identificacao_completo?.data_fundacao || "-"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Capital Social: </span>
                        {formatToReal(
                          data?.identificacao_completo?.capital_atual
                        ) || "-"}
                      </div>
                    </div>
                    <div className="text-sm flex-1">
                      <div className="mb-3 flex gap-2 items-center">
                        <span className="font-semibold">Situação: </span>
                        <div
                          className={
                            data?.identificacao_completo?.situacao_cnpj ===
                            "Ativo"
                              ? "text-black"
                              : "text-red-500"
                          }
                        >
                          {data?.identificacao_completo?.situacao_cnpj}
                        </div>
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Endereço: </span>
                        {data?.localizacao_completo?.matriz?.endereco_matriz ||
                          "-"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Bairro: </span>
                        {data?.localizacao_completo?.matriz?.bairro_matriz ||
                          "-"}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">Cidade/UF: </span>
                        {data?.localizacao_completo?.matriz?.cidade_matriz}/
                        {data?.localizacao_completo?.matriz?.uf_matriz}
                      </div>
                      <div className="mb-3">
                        <span className="font-semibold">CEP: </span>
                        {maskCEP(
                          data?.localizacao_completo?.matriz?.cep_matriz
                        ) || "-"}
                      </div>
                      <div className="mb-3 mt-12">
                        <span className="font-semibold">
                          Faturamento Anual:{" "}
                        </span>
                        <span className="text-blue-700 font-semibold">
                          {data?.faturamento_presumido?.faturamento_anual ||
                            "-"}
                        </span>
                      </div>
                      <div>{data?.faturamento_presumido?.mensagem}</div>
                    </div>
                  </div>
                </div>
                <TitleStripe>Quadro de Avisos</TitleStripe>
                <div className="container-alerts four-columns">
                  {/* <AvisoCard
                    title="Quadro Societário"
                    hasRestrictions={!data?.cheques_sem_fundo?.mensagem}
                    messageError={`${data?.cheques_sem_fundo?.total_cheques_sem_fundos} 
                        ocorrências`}
                  />

                  <AvisoCard
                    title="Empresas Ligadas"
                    hasRestrictions={!data?.cheques_sem_fundo?.mensagem}
                    messageError={`${data?.cheques_sem_fundo?.total_cheques_sem_fundos} 
                        ocorrências`}
                  /> */}

                  <AvisoCard
                    title="Cheques sem Fundos"
                    hasRestrictions={!data?.cheques_sem_fundo?.mensagem}
                    messageError={`${data?.cheques_sem_fundo?.total_cheques_sem_fundos} 
                        ocorrências`}
                  />

                  <AvisoCard
                    title="Cheques Sustados"
                    hasRestrictions={!data?.cheques_sustados?.mensagem}
                    messageError={`${data?.cheques_sustados?.total} ocorrências`}
                  />

                  <AvisoCard
                    title="Protestos"
                    hasRestrictions={!data?.protestos?.mensagem}
                    messageError={`${data?.protestos?.total_protestos} ocorrências`}
                  />

                  {isLoadingProtestos ? (
                    <div className="flex relative flex-col bg-gray-500 rounded-md font-semibold pt-1 pb-6 px-2 text-white shadow-md">
                      <div className="text-left text-sm">
                        Protestos dir. cartório
                      </div>
                      <div className="text-center font-semibold w-fit text-green-800 px-2 rounded-md mx-auto mt-4">
                        <Spinner aria-label="Large spinner example" size="md" />
                      </div>
                    </div>
                  ) : (
                    <>
                      <AvisoCard
                        title="Protestos dir. Cartório"
                        hasRestrictions={infoProtestos?.length > 0}
                        messageError={`${infoProtestos?.length}
                          ocorrências`}
                      />
                    </>
                  )}

                  <AvisoCard
                    title="Negativações"
                    hasRestrictions={!data?.pendencias_restricoes?.mensagem}
                    messageError={`${data?.pendencias_restricoes?.total_pendencias}
                    ocorrências`}
                  />

                  <AvisoCard
                    title="Ações Cíveis"
                    hasRestrictions={!data?.acoes_civeis?.mensagem}
                    messageError={`${data?.acoes_civeis?.total_acoes} ocorrências`}
                  />

                  <AvisoCard
                    title="Falência/Recuperação"
                    hasRestrictions={
                      !data?.falencias_recuperacao_judicial?.mensagem
                    }
                    messageError={`${data?.falencias_recuperacao_judicial?.quantidade_total} ocorrências`}
                  />
                </div>

                <TitleStripe>Avaliação Preliminar de Crédito</TitleStripe>
                <div className="mt-6 mb-6">
                  <span
                    className="font-semibold text-lg"
                    style={{ fontVariant: "small-caps" }}
                  >
                    Limite de Crédito:
                  </span>{" "}
                  <span className="text-red-500 font-semibold">
                    {data?.limite_credito?.texto}
                  </span>
                </div>
                <div
                  className="font-semibold text-lg"
                  style={{ fontVariant: "small-caps" }}
                >
                  Negociação
                </div>
                {calcMarkNegotiation() ? (
                  <>
                    {" "}
                    <div className="relative mt-5 mb-6">
                      <div className="flex overflow-hidden rounded-lg w-full shadow-md relative">
                        <div
                          className="bg-red-500 p-3 flex-1 text-center text-sm font-semibold text-red-900 outline outline-green-200"
                          style={{
                            background:
                              "linear-gradient(90deg, rgba(255,26,26,1) 0%, rgba(255,238,64,1) 100%)",
                          }}
                        >
                          Não recomendada
                        </div>
                        <div
                          className="bg-yellow-300 p-3 flex-1 text-center text-sm font-semibold text-yellow-900"
                          style={{
                            background:
                              "linear-gradient(90deg, rgba(255,236,64,1) 0%, rgba(255,236,64,1) 68%, rgba(190,221,95,1) 88%, rgba(48,212,113,1) 100%)",
                          }}
                        >
                          Em análise
                        </div>
                        <div
                          className="bg-green-400 p-3 flex-1 text-center text-sm font-semibold text-green-900"
                          style={{
                            background:
                              "linear-gradient(90deg, rgba(50,213,114,1) 0%, rgba(44,175,95,1) 100%)",
                          }}
                        >
                          Recomendada
                        </div>
                      </div>
                      <div
                        className="absolute -top-10"
                        style={{
                          left: `calc(${calcMarkNegotiation()})`,
                        }}
                      >
                        <RiArrowDownSFill
                          fontSize={58}
                          className="text-gray-500"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div>{data?.decisao?.descricao}</div>
                )}

                <div>
                  <div
                    className="font-semibold text-lg"
                    style={{ fontVariant: "small-caps" }}
                  >
                    Score de Risco
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <div className="w-3/5 p-4">
                    <img src={getImageURLScore(+data?.score?.score_pj.score)} />
                    <div className="text-center font-semibold text-xl">
                      {+data?.score?.score_pj.score}
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="mb-2">
                      <div className="mb-2 font-semibold">
                        Probabilidade de Inadimplência ={" "}
                        {data?.score?.score_pj?.probabilidade}%
                      </div>{" "}
                      {data?.score?.score_pj?.texto}
                    </div>
                  </div>
                </div>
                <div className="border border-gray-300 p-2 text-sm">
                  <span className="font-semibold">Importante:</span> a decisão
                  de aprovação ou não do crédito é de exclusiva responsabilidade
                  do concedente. as informações prestadas pelo provedor tem como
                  objevo subsidiar essas decisões e, em hipótese alguma devem
                  ser ulizadas como justificativa, pelo concedente do crédito,
                  para a tomada da referida decisão.
                </div>

                <div>
                  <TitleStripe className="mb-5">Quadro Societário</TitleStripe>
                  {data && !data?.socios?.mensagem ? (
                    <QuadroSocietario
                      socios={
                        Array.isArray(data?.socios?.socio)
                          ? data?.socios?.socio
                          : [...[], data?.socios?.socio]
                      }
                    />
                  ) : (
                    <NoOcurrence />
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">
                    {" "}
                    Participações dos Sócios e Administradores em Outras
                    Empresas
                  </TitleStripe>
                  {!data?.participacoes_socios_adm_outras_empresas?.mensagem ? (
                    <>
                      {Array.isArray(
                        data?.participacoes_socios_adm_outras_empresas?.socio
                      ) ? (
                        <>
                          {data?.participacoes_socios_adm_outras_empresas?.socio?.map(
                            (socioP, index) => (
                              <div key={index} className="mt-10">
                                <div className="font-semibold mb-1 text-blue-600 text-center underline">
                                  {socioP.nome_socio}
                                </div>

                                <div className="overflow-x-auto mt-3 mb-5 rounded-lg border border-gray-300">
                                  <Table striped>
                                    <Table.Head className="text-black">
                                      <Table.HeadCell
                                        className="text-center py-2 px-1"
                                        style={{ background: "#76b9fc" }}
                                      >
                                        Nº
                                      </Table.HeadCell>
                                      <Table.HeadCell
                                        className="py-2"
                                        style={{ background: "#76b9fc" }}
                                      >
                                        Denominação Social
                                      </Table.HeadCell>
                                      <Table.HeadCell
                                        className="text-center py-2 px-1"
                                        style={{ background: "#76b9fc" }}
                                      >
                                        Data <br /> Entrada
                                      </Table.HeadCell>
                                      <Table.HeadCell
                                        className="text-center py-2 px-1"
                                        style={{ background: "#76b9fc" }}
                                      >
                                        Part. (%)
                                      </Table.HeadCell>
                                      <Table.HeadCell
                                        className="text-center py-2 px-1"
                                        style={{ background: "#76b9fc" }}
                                      >
                                        Situação
                                      </Table.HeadCell>
                                      <Table.HeadCell
                                        className="py-2"
                                        style={{ background: "#76b9fc" }}
                                      >
                                        Pendências
                                      </Table.HeadCell>
                                    </Table.Head>

                                    <Table.Body className="divide-y">
                                      {Array.isArray(
                                        socioP?.socios_participantes
                                      ) ? (
                                        <>
                                          {socioP?.socios_participantes?.map(
                                            (socio, index) => (
                                              <Table.Row
                                                key={index}
                                                className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black"
                                              >
                                                <Table.Cell className="font-medium dark:text-white text-center py-1 px-1">
                                                  {index + 1}
                                                </Table.Cell>
                                                <Table.Cell className=" py-1 w-96">
                                                  <div>
                                                    {socio.razao_social}
                                                  </div>
                                                  <div
                                                    style={{ fontSize: "12px" }}
                                                  >
                                                    CNPJ: {socio.cnpj}
                                                  </div>
                                                </Table.Cell>
                                                <Table.Cell className="text-center py-1 px-1">
                                                  {socio.entrada}
                                                </Table.Cell>
                                                <Table.Cell className="text-center py-1 px-1 px-1">
                                                  {socio.participacao}
                                                </Table.Cell>
                                                <Table.Cell className="text-center py-1 w-24">
                                                  {socio.situacao_cnpj}
                                                </Table.Cell>
                                                <Table.Cell className="py-1 text-2xs text-black">
                                                  {socio?.indicador_debito?.toUpperCase() ===
                                                  "SIM" ? (
                                                    <>
                                                      {socio
                                                        ?.restricoes_documento
                                                        ?.cheques_sem_fundo
                                                        ?.quantidade !==
                                                        "0" && (
                                                        <div className="uppercase">
                                                          Cheques Sem Fundos:{" "}
                                                          <span className="text-red-500 font-semibold">
                                                            {
                                                              socio
                                                                ?.restricoes_documento
                                                                ?.cheques_sem_fundo
                                                                ?.quantidade
                                                            }{" "}
                                                            ocorrências
                                                          </span>
                                                        </div>
                                                      )}

                                                      {socio
                                                        ?.restricoes_documento
                                                        ?.protestos
                                                        ?.quantidade !==
                                                        "0" && (
                                                        <div className="uppercase">
                                                          Protestos:{" "}
                                                          <span className="text-red-500 font-semibold">
                                                            {formatToReal(
                                                              socio?.restricoes_documento?.protestos?.valor?.replace(
                                                                ",",
                                                                "."
                                                              )
                                                            )}
                                                          </span>
                                                        </div>
                                                      )}

                                                      {socio
                                                        ?.restricoes_documento
                                                        ?.pendencias_restricoes
                                                        ?.quantidade !==
                                                        "0" && (
                                                        <div className="uppercase">
                                                          Pendências:{" "}
                                                          <span className="text-red-500 font-semibold">
                                                            {formatToReal(
                                                              socio?.restricoes_documento?.pendencias_restricoes?.valor?.replace(
                                                                ",",
                                                                "."
                                                              )
                                                            )}
                                                          </span>
                                                        </div>
                                                      )}

                                                      {socio
                                                        ?.restricoes_documento
                                                        ?.acoes_judiciais
                                                        ?.quantidade !==
                                                        "0" && (
                                                        <div className="uppercase">
                                                          Ações cíveis:{" "}
                                                          <span className="text-red-500 font-semibold">
                                                            {formatToReal(
                                                              socio?.restricoes_documento?.acoes_judiciais?.valor?.replace(
                                                                ",",
                                                                "."
                                                              )
                                                            )}
                                                          </span>
                                                        </div>
                                                      )}

                                                      {socio
                                                        ?.restricoes_documento
                                                        ?.falencias_recuperacao_judicial
                                                        ?.quantidade !==
                                                        "0" && (
                                                        <div className="uppercase">
                                                          Rec.
                                                          Judicial/Falências:{" "}
                                                          <span className="text-red-500 font-semibold">
                                                            {formatToReal(
                                                              socio?.restricoes_documento?.falencias_recuperacao_judicial?.valor?.replace(
                                                                ",",
                                                                "."
                                                              )
                                                            )}
                                                          </span>
                                                        </div>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <div className="text-blue-500">
                                                      Não há débitos
                                                    </div>
                                                  )}
                                                </Table.Cell>
                                              </Table.Row>
                                            )
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black">
                                            <Table.Cell className="font-medium dark:text-white text-center py-1 px-1">
                                              {1}
                                            </Table.Cell>
                                            <Table.Cell className=" py-1 w-96">
                                              <div>
                                                {
                                                  socioP?.socios_participantes
                                                    ?.razao_social
                                                }
                                              </div>
                                              <div style={{ fontSize: "12px" }}>
                                                CNPJ:{" "}
                                                {
                                                  socioP?.socios_participantes
                                                    ?.cnpj
                                                }
                                              </div>
                                            </Table.Cell>
                                            <Table.Cell className="text-center py-1 px-1">
                                              {
                                                socioP?.socios_participantes
                                                  ?.entrada
                                              }
                                            </Table.Cell>
                                            <Table.Cell className="text-center py-1 px-1 px-1">
                                              {
                                                socioP?.socios_participantes
                                                  ?.participacao
                                              }
                                            </Table.Cell>
                                            <Table.Cell className="text-center py-1 w-24">
                                              {
                                                socioP?.socios_participantes
                                                  ?.situacao_cnpj
                                              }
                                            </Table.Cell>
                                            <Table.Cell className="py-1 text-2xs text-black">
                                              {socioP?.socios_participantes?.indicador_debito?.toUpperCase() ===
                                              "SIM" ? (
                                                <>
                                                  {socioP?.socios_participantes
                                                    ?.restricoes_documento
                                                    ?.cheques_sem_fundo
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Cheques Sem Fundos:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {
                                                          socioP
                                                            ?.socios_participantes
                                                            ?.restricoes_documento
                                                            ?.cheques_sem_fundo
                                                            ?.quantidade
                                                        }{" "}
                                                        ocorrências
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socioP?.socios_participantes
                                                    ?.restricoes_documento
                                                    ?.protestos?.quantidade !==
                                                    "0" && (
                                                    <div className="uppercase">
                                                      Protestos:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socioP?.socios_participantes?.restricoes_documento?.protestos?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socioP?.socios_participantes
                                                    ?.restricoes_documento
                                                    ?.pendencias_restricoes
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Pendências:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socioP?.socios_participantes?.restricoes_documento?.pendencias_restricoes?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socioP?.socios_participantes
                                                    ?.restricoes_documento
                                                    ?.acoes_judiciais
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Ações cíveis:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socioP?.socios_participantes?.restricoes_documento?.acoes_judiciais?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socioP?.socios_participantes
                                                    ?.restricoes_documento
                                                    ?.falencias_recuperacao_judicial
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Rec. Judicial/Falências:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socioP?.socios_participantes?.restricoes_documento?.falencias_recuperacao_judicial?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}
                                                </>
                                              ) : (
                                                <div className="text-blue-500">
                                                  Não há débitos
                                                </div>
                                              )}
                                            </Table.Cell>
                                          </Table.Row>
                                        </>
                                      )}
                                    </Table.Body>
                                  </Table>
                                </div>
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          <div className="mt-10">
                            <div className="font-semibold mb-1 text-blue-600 text-center underline">
                              {
                                data?.participacoes_socios_adm_outras_empresas
                                  ?.socio.nome_socio
                              }
                            </div>

                            <div className="overflow-x-auto mt-3 mb-5 rounded-lg border border-gray-300">
                              <Table striped>
                                <Table.Head className="text-black">
                                  <Table.HeadCell
                                    className="text-center py-2 px-1"
                                    style={{ background: "#76b9fc" }}
                                  >
                                    Nº
                                  </Table.HeadCell>
                                  <Table.HeadCell
                                    className="py-2"
                                    style={{ background: "#76b9fc" }}
                                  >
                                    Denominação Social
                                  </Table.HeadCell>
                                  <Table.HeadCell
                                    className="text-center py-2 px-1"
                                    style={{ background: "#76b9fc" }}
                                  >
                                    Data <br /> Entrada
                                  </Table.HeadCell>
                                  <Table.HeadCell
                                    className="text-center py-2 px-1"
                                    style={{ background: "#76b9fc" }}
                                  >
                                    Part. (%)
                                  </Table.HeadCell>
                                  <Table.HeadCell
                                    className="text-center py-2 px-1"
                                    style={{ background: "#76b9fc" }}
                                  >
                                    Situação
                                  </Table.HeadCell>
                                  <Table.HeadCell
                                    className="py-2"
                                    style={{ background: "#76b9fc" }}
                                  >
                                    Pendências
                                  </Table.HeadCell>
                                </Table.Head>

                                <Table.Body className="divide-y">
                                  {Array.isArray(
                                    data
                                      ?.participacoes_socios_adm_outras_empresas
                                      ?.socio?.socios_participantes
                                  ) ? (
                                    <>
                                      {data?.participacoes_socios_adm_outras_empresas?.socio?.socios_participantes?.map(
                                        (socio, index) => (
                                          <Table.Row
                                            key={index}
                                            className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black"
                                          >
                                            <Table.Cell className="font-medium dark:text-white text-center py-1 px-1">
                                              {index + 1}
                                            </Table.Cell>
                                            <Table.Cell className=" py-1 w-96">
                                              <div>{socio.razao_social}</div>
                                              <div style={{ fontSize: "12px" }}>
                                                CNPJ: {socio.cnpj}
                                              </div>
                                            </Table.Cell>
                                            <Table.Cell className="text-center py-1 px-1">
                                              {socio.entrada}
                                            </Table.Cell>
                                            <Table.Cell className="text-center py-1 px-1 px-1">
                                              {socio.participacao}
                                            </Table.Cell>
                                            <Table.Cell className="text-center py-1 w-24">
                                              {socio.situacao_cnpj}
                                            </Table.Cell>
                                            <Table.Cell className="py-1 text-2xs text-black">
                                              {socio?.indicador_debito?.toUpperCase() ===
                                              "SIM" ? (
                                                <>
                                                  {socio?.restricoes_documento
                                                    ?.cheques_sem_fundo
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Cheques Sem Fundos:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {
                                                          socio
                                                            ?.restricoes_documento
                                                            ?.cheques_sem_fundo
                                                            ?.quantidade
                                                        }{" "}
                                                        ocorrências
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socio?.restricoes_documento
                                                    ?.protestos?.quantidade !==
                                                    "0" && (
                                                    <div className="uppercase">
                                                      Protestos:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socio?.restricoes_documento?.protestos?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socio?.restricoes_documento
                                                    ?.pendencias_restricoes
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Pendências:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socio?.restricoes_documento?.pendencias_restricoes?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socio?.restricoes_documento
                                                    ?.acoes_judiciais
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Ações cíveis:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socio?.restricoes_documento?.acoes_judiciais?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}

                                                  {socio?.restricoes_documento
                                                    ?.falencias_recuperacao_judicial
                                                    ?.quantidade !== "0" && (
                                                    <div className="uppercase">
                                                      Rec. Judicial/Falências:{" "}
                                                      <span className="text-red-500 font-semibold">
                                                        {formatToReal(
                                                          socio?.restricoes_documento?.falencias_recuperacao_judicial?.valor?.replace(
                                                            ",",
                                                            "."
                                                          )
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}
                                                </>
                                              ) : (
                                                <div className="text-blue-500">
                                                  Não há débitos
                                                </div>
                                              )}
                                            </Table.Cell>
                                          </Table.Row>
                                        )
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800 text-black">
                                        <Table.Cell className="font-medium dark:text-white text-center py-1 px-1">
                                          {1}
                                        </Table.Cell>
                                        <Table.Cell className=" py-1 w-96">
                                          <div>
                                            {
                                              data
                                                ?.participacoes_socios_adm_outras_empresas
                                                ?.socio?.socios_participantes
                                                ?.razao_social
                                            }
                                          </div>
                                          <div style={{ fontSize: "10px" }}>
                                            CNPJ:{" "}
                                            {
                                              data
                                                ?.participacoes_socios_adm_outras_empresas
                                                ?.socio?.socios_participantes
                                                ?.cnpj
                                            }
                                          </div>
                                        </Table.Cell>
                                        <Table.Cell className="text-center py-1 px-1">
                                          {
                                            data
                                              ?.participacoes_socios_adm_outras_empresas
                                              ?.socio?.socios_participantes
                                              ?.entrada
                                          }
                                        </Table.Cell>
                                        <Table.Cell className="text-center py-1 px-1 px-1">
                                          {
                                            data
                                              ?.participacoes_socios_adm_outras_empresas
                                              ?.socio?.socios_participantes
                                              ?.participacao
                                          }
                                        </Table.Cell>
                                        <Table.Cell className="text-center py-1 w-24">
                                          {
                                            data
                                              ?.participacoes_socios_adm_outras_empresas
                                              ?.socio?.socios_participantes
                                              ?.situacao_cnpj
                                          }
                                        </Table.Cell>
                                        <Table.Cell className="py-1 text-2xs text-black">
                                          {data?.participacoes_socios_adm_outras_empresas?.socio?.socios_participantes.indicador_debito?.toUpperCase() ===
                                          "SIM" ? (
                                            <>
                                              {data
                                                ?.participacoes_socios_adm_outras_empresas
                                                ?.socio?.socios_participantes
                                                .restricoes_documento
                                                ?.cheques_sem_fundo
                                                ?.quantidade !== "0" && (
                                                <div className="uppercase">
                                                  Cheques Sem Fundos:{" "}
                                                  <span className="text-red-500 font-semibold">
                                                    {
                                                      data
                                                        ?.participacoes_socios_adm_outras_empresas
                                                        ?.socio
                                                        ?.socios_participantes
                                                        .restricoes_documento
                                                        ?.cheques_sem_fundo
                                                        ?.quantidade
                                                    }
                                                  </span>
                                                </div>
                                              )}

                                              {data
                                                ?.participacoes_socios_adm_outras_empresas
                                                ?.socio?.socios_participantes
                                                .restricoes_documento?.protestos
                                                ?.quantidade !== "0" && (
                                                <div className="uppercase">
                                                  Protestos:{" "}
                                                  <span className="text-red-500 font-semibold">
                                                    {formatToReal(
                                                      data?.participacoes_socios_adm_outras_empresas?.socio?.socios_participantes.restricoes_documento?.protestos?.valor?.replace(
                                                        ",",
                                                        "."
                                                      )
                                                    )}
                                                  </span>
                                                </div>
                                              )}

                                              {data
                                                ?.participacoes_socios_adm_outras_empresas
                                                ?.socio?.socios_participantes
                                                .restricoes_documento
                                                ?.pendencias_restricoes
                                                ?.quantidade !== "0" && (
                                                <div className="uppercase">
                                                  Pendências:{" "}
                                                  <span className="text-red-500 font-semibold">
                                                    {formatToReal(
                                                      data?.participacoes_socios_adm_outras_empresas?.socio?.socios_participantes.restricoes_documento?.pendencias_restricoes?.valor?.replace(
                                                        ",",
                                                        "."
                                                      )
                                                    )}
                                                  </span>
                                                </div>
                                              )}

                                              {data
                                                ?.participacoes_socios_adm_outras_empresas
                                                ?.socio?.socios_participantes
                                                .restricoes_documento
                                                ?.acoes_judiciais
                                                ?.quantidade !== "0" && (
                                                <div className="uppercase">
                                                  Ações cíveis:{" "}
                                                  <span className="text-red-500 font-semibold">
                                                    {formatToReal(
                                                      data?.participacoes_socios_adm_outras_empresas?.socio?.socios_participantes.restricoes_documento?.acoes_judiciais?.valor?.replace(
                                                        ",",
                                                        "."
                                                      )
                                                    )}
                                                  </span>
                                                </div>
                                              )}

                                              {data
                                                ?.participacoes_socios_adm_outras_empresas
                                                ?.socio?.socios_participantes
                                                .restricoes_documento
                                                ?.falencias_recuperacao_judicial
                                                ?.quantidade !== "0" && (
                                                <div className="uppercase">
                                                  Rec. Judicial/Falências:{" "}
                                                  <span className="text-red-500 font-semibold">
                                                    {formatToReal(
                                                      data?.participacoes_socios_adm_outras_empresas?.socio?.socios_participantes.restricoes_documento?.falencias_recuperacao_judicial?.valor?.replace(
                                                        ",",
                                                        "."
                                                      )
                                                    )}
                                                  </span>
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            <div className="text-blue-500">
                                              Não há débitos
                                            </div>
                                          )}
                                        </Table.Cell>
                                      </Table.Row>
                                    </>
                                  )}
                                </Table.Body>
                              </Table>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <NoOcurrence />
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">Cheques Sem Fundos</TitleStripe>
                  {!!data?.cheques_sem_fundo?.total_cheques_sem_fundos ? (
                    <>
                      <ChequesSemFundos
                        quantidadeTotal={
                          data?.cheques_sem_fundo?.total_cheques_sem_fundos
                        }
                        totalNomes={
                          data?.cheques_sem_fundo?.total_nomes_relacionados
                        }
                        chequesSemFundos={
                          Array.isArray(
                            data?.cheques_sem_fundo?.ultimas_ocorrencias
                              ?.cheque_sem_fundo
                          )
                            ? data?.cheques_sem_fundo?.ultimas_ocorrencias
                                ?.cheque_sem_fundo
                            : [
                                ...[],
                                data?.cheques_sem_fundo?.ultimas_ocorrencias
                                  ?.cheque_sem_fundo,
                              ]
                        }
                      />
                    </>
                  ) : (
                    <NoOcurrence />
                  )}
                </div>
                <div>
                  <TitleStripe className="mb-5">Cheques Sustados:</TitleStripe>
                  {data?.cheques_sustados?.ultimas_ocorrencias?.cheques_sustado
                    ?.length > 0 ? (
                    <>
                      <ChequesSustados
                        periodoInicial={data?.cheques_sustados?.periodo_inicial}
                        periodoFinal={data?.cheques_sustados?.periodo_final}
                        quantidadeTotal={data?.cheques_sustados?.total}
                        chequesSustados={
                          Array.isArray(
                            data?.cheques_sustados?.ultimas_ocorrencias
                              ?.cheques_sustado
                          )
                            ? data?.cheques_sustados?.ultimas_ocorrencias
                                ?.cheques_sustado
                            : [
                                ...[],
                                data?.cheques_sustados?.ultimas_ocorrencias
                                  ?.cheques_sustado,
                              ]
                        }
                      />
                    </>
                  ) : (
                    <NoOcurrence />
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">Protestos</TitleStripe>
                  {!!data?.protestos?.total_protestos ? (
                    <ProtestosDefineLimite
                      quantidadeTotal={data?.protestos?.total_protestos}
                      valorTotal={data?.protestos?.valor_total}
                      primeiroValor={data?.protestos?.valor_primeiro}
                      protestos={
                        Array.isArray(
                          data?.protestos?.ultimas_ocorrencias?.protesto
                        )
                          ? data?.protestos?.ultimas_ocorrencias?.protesto
                          : [
                              ...[],
                              data?.protestos?.ultimas_ocorrencias?.protesto,
                            ]
                      }
                    />
                  ) : (
                    <NoOcurrence />
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">
                    Protestos direto do cartório
                  </TitleStripe>
                  {!isLoadingProtestos ? (
                    <>
                      {infoProtestos?.length > 0 ? (
                        <ProtestosDirCartorio
                          quantidadeTotal={infoProtestos?.length}
                          protestosDirCartorio={infoProtestos}
                        />
                      ) : (
                        <NoOcurrence />
                      )}
                    </>
                  ) : (
                    <div role="status" className="w-full animate-pulse">
                      <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>{" "}
                    </div>
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">Negativações</TitleStripe>
                  {data?.pendencias_restricoes?.ultimas_ocorrencias
                    ?.pendencia_restricao ? (
                    <>
                      <PendenciasFinanceiras
                        quantidadeTotal={
                          data?.pendencias_restricoes?.total_pendencias
                        }
                        dataMaiorPendencia={
                          data?.pendencias_restricoes?.data_maior
                        }
                        valorTotal={data?.pendencias_restricoes?.valor}
                        pendenciasFinanceiras={
                          Array.isArray(
                            data?.pendencias_restricoes.ultimas_ocorrencias
                              ?.pendencia_restricao
                          )
                            ? data?.pendencias_restricoes.ultimas_ocorrencias
                                ?.pendencia_restricao
                            : [
                                ...[],
                                data?.pendencias_restricoes.ultimas_ocorrencias
                                  ?.pendencia_restricao,
                              ]
                        }
                      />
                    </>
                  ) : (
                    <NoOcurrence />
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">Ações Cíveis</TitleStripe>
                  {!!data?.acoes_civeis?.total_acoes ? (
                    <>
                      <AcoesCiveis
                        quantidadeTotal={data?.acoes_civeis?.total_acoes}
                        primeiroValor={data?.acoes_civeis?.valor_primeiro}
                        valorTotal={data?.acoes_civeis?.valor_total}
                        acoesCiveis={
                          Array.isArray(
                            data?.acoes_civeis?.ultimas_ocorrencias?.acao_civel
                          )
                            ? data?.acoes_civeis?.ultimas_ocorrencias
                                ?.acao_civel
                            : [
                                ...[],
                                data?.acoes_civeis?.ultimas_ocorrencias
                                  ?.acao_civel,
                              ]
                        }
                      />
                    </>
                  ) : (
                    <NoOcurrence />
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">
                    Falências/Recuperações Judiciais
                  </TitleStripe>
                  {data && !data?.falencias_recuperacao_judicial?.mensagem ? (
                    <FalenciaRecuperacaoJudicial
                      falenciaRecuperacaoJudicial={
                        Array.isArray(
                          data?.falencias_recuperacao_judicial
                            .ultimas_ocorrencias?.falencia_recuperacao_judicial
                        )
                          ? data?.falencias_recuperacao_judicial
                              .ultimas_ocorrencias
                              ?.falencia_recuperacao_judicial
                          : [
                              ...[],
                              data?.falencias_recuperacao_judicial
                                .ultimas_ocorrencias
                                ?.falencia_recuperacao_judicial,
                            ]
                      }
                    />
                  ) : (
                    <NoOcurrence />
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">Crédito Obtido</TitleStripe>
                  {data?.credito_obtido ? (
                    <>
                      <div className="mt-4 font-semibold">
                        Pontuação:{" "}
                        <Badge className="w-fit inline" color="gray">
                          {data?.credito_obtido?.pontuacao}
                        </Badge>
                      </div>
                      <div className="mt-2 w-full rounded-lg border border-gray-300">
                        <Table>
                          <Table.Head>
                            {data?.credito_obtido?.lista_indicador_comportamento?.item_indicador?.map(
                              (credito, index) => (
                                <Table.HeadCell
                                  key={index}
                                  className="text-center text-black text-xs px-1"
                                  style={{ background: "#76B9FB" }}
                                >
                                  {formatDateToYY(credito.mes_ano)}
                                </Table.HeadCell>
                              )
                            )}
                          </Table.Head>
                          <Table.Body className="divide-y">
                            {data?.credito_obtido?.lista_indicador_comportamento?.item_indicador?.map(
                              (credito, index) => (
                                <Table.Cell
                                  key={index}
                                  className="text-center text-black text-xs px-1"
                                >
                                  {formatDateToYY(credito.pontuacao_mensal)}
                                </Table.Cell>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <NoOcurrence />
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">Pagamento Pontual</TitleStripe>
                  {data?.pagamento_pontual ? (
                    <>
                      <div className="mt-4 font-semibold">
                        Pontuação:{" "}
                        <Badge className="w-fit inline" color="gray">
                          {data?.pagamento_pontual?.pontuacao}
                        </Badge>
                      </div>
                      <div className="border border-gray-300 rounded-lg mt-2">
                        <Table>
                          <Table.Head>
                            {data?.pagamento_pontual?.lista_indicador_comportamento?.item_indicador?.map(
                              (credito, index) => (
                                <Table.HeadCell
                                  key={index}
                                  className="text-center text-black text-xs px-1"
                                  style={{ background: "#76B9FB" }}
                                >
                                  {formatDateToYY(credito.mes_ano)}
                                </Table.HeadCell>
                              )
                            )}
                          </Table.Head>
                          <Table.Body className="divide-y">
                            {data?.pagamento_pontual?.lista_indicador_comportamento?.item_indicador?.map(
                              (credito, index) => (
                                <Table.Cell
                                  key={index}
                                  className="text-center text-black text-xs px-1"
                                >
                                  {formatDateToYY(credito.pontuacao_mensal)}
                                </Table.Cell>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <NoOcurrence />
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">Pagamento Atrasado</TitleStripe>
                  {data?.pagamento_atrasado ? (
                    <>
                      {" "}
                      <div className="mt-2 w-full rounded-lg border border-gray-300">
                        <Table>
                          <Table.Head>
                            {data?.pagamento_atrasado?.atraso_medio_dias?.lista_indicador_comportamento?.item_indicador?.map(
                              (credito, index) => (
                                <Table.HeadCell
                                  key={index}
                                  className="text-center text-black text-xs px-1"
                                  style={{ background: "#76B9FB" }}
                                >
                                  {formatDateToYY(credito.mes_ano)}
                                </Table.HeadCell>
                              )
                            )}
                          </Table.Head>
                          <Table.Body className="divide-y">
                            {data?.pagamento_atrasado?.atraso_medio_dias?.lista_indicador_comportamento?.item_indicador?.map(
                              (credito, index) => (
                                <Table.Cell
                                  key={index}
                                  className="text-center text-black text-xs px-1"
                                >
                                  {formatDateToYY(credito.pontuacao_mensal)}
                                </Table.Cell>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <NoOcurrence />
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">Compromissos</TitleStripe>
                  {data?.compromissos ? (
                    <>
                      <div className="mt-4 font-semibold">
                        Pontuação:{" "}
                        <Badge className="w-fit inline" color="gray">
                          {data?.compromissos?.pontuacao}
                        </Badge>
                      </div>
                      <div className="border border-gray-300 rounded-lg mt-2">
                        <Table>
                          <Table.Head>
                            {data?.compromissos?.lista_indicador_comportamento?.item_indicador?.map(
                              (credito, index) => (
                                <Table.HeadCell
                                  key={index}
                                  className="text-center text-black text-xs px-1"
                                  style={{ background: "#76B9FB" }}
                                >
                                  {formatDateToYY(credito.mes_ano)}
                                </Table.HeadCell>
                              )
                            )}
                          </Table.Head>
                          <Table.Body className="divide-y">
                            {data?.compromissos?.lista_indicador_comportamento?.item_indicador?.map(
                              (credito, index) => (
                                <Table.Cell
                                  key={index}
                                  className="text-center text-black text-xs px-1"
                                >
                                  {formatDateToYY(credito.pontuacao_mensal)}
                                </Table.Cell>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <NoOcurrence />
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">
                    Comprometimento Futuro
                  </TitleStripe>
                  {data?.comprometimento_futuro ? (
                    <>
                      <div className="font-semibold mt-2">Compromissos</div>

                      <div className="border border-gray-300 rounded-lg mt-2">
                        <Table>
                          <Table.Head>
                            {data?.comprometimento_futuro?.lista_compromissos?.compromisso?.map(
                              (credito, index) => (
                                <Table.HeadCell
                                  key={index}
                                  className="text-center text-black text-xs px-1"
                                  style={{ background: "#76B9FB" }}
                                >
                                  {formatDateToYY(credito.periodo)}
                                </Table.HeadCell>
                              )
                            )}
                          </Table.Head>
                          <Table.Body className="divide-y">
                            {data?.comprometimento_futuro?.lista_compromissos?.compromisso?.map(
                              (credito, index) => (
                                <Table.Cell
                                  key={index}
                                  className="text-center text-black text-xs px-1"
                                >
                                  {formatDateToYY(credito.pontuacao)}
                                </Table.Cell>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </div>

                      <div className="font-semibold mt-5">Crédito</div>
                      <div className="border border-gray-300 rounded-lg mt-2">
                        <Table>
                          <Table.Head>
                            {data?.comprometimento_futuro?.lista_credito?.credito?.map(
                              (credito, index) => (
                                <Table.HeadCell
                                  key={index}
                                  className="text-center text-black text-xs px-1"
                                  style={{ background: "#76B9FB" }}
                                >
                                  {formatDateToYY(credito.periodo)}
                                </Table.HeadCell>
                              )
                            )}
                          </Table.Head>
                          <Table.Body className="divide-y">
                            {data?.comprometimento_futuro?.lista_credito?.credito?.map(
                              (credito, index) => (
                                <Table.Cell
                                  key={index}
                                  className="text-center text-black text-xs px-1"
                                >
                                  {formatDateToYY(credito.pontuacao)}
                                </Table.Cell>
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </div>
                    </>
                  ) : (
                    <NoOcurrence />
                  )}
                </div>

                <div>
                  <TitleStripe className="mb-5">
                    Histórico de Consultas
                  </TitleStripe>

                  {data?.consultas?.ultimas_consultas?.consulta?.length > 0 ? (
                    <>
                      <HistoricoConsultas
                        quantidadeTotal={data?.consultas?.quantidade_total}
                        periodoInicial={data?.consultas?.periodo_inicial}
                        periodoFinal={data?.consultas?.periodo_final}
                        historicoConsultas={
                          Array.isArray(
                            data?.consultas?.ultimas_consultas?.consulta
                          )
                            ? data?.consultas?.ultimas_consultas?.consulta
                            : [
                                ...[],
                                data?.consultas?.ultimas_consultas?.consulta,
                              ]
                        }
                      />
                    </>
                  ) : (
                    <NoOcurrence />
                  )}

                  <div className="border border-gray-300 rounded-lg mt-3">
                    <Table striped>
                      <Table.Head>
                        {data?.consultas?.consultas_mes?.map(
                          (consulta, index) => (
                            <Table.HeadCell
                              key={index}
                              className="text-center text-black px-1"
                              style={{ background: "#76B9FB" }}
                            >
                              {formatDateToDDMM(consulta.mes_ano)}
                            </Table.HeadCell>
                          )
                        )}
                      </Table.Head>

                      <Table.Body className="divide-y">
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                          {data?.consultas?.consultas_mes?.map(
                            (consult, index) => (
                              <Table.Cell
                                className="font-medium text-gray-900 dark:text-white text-center text-black py-1 px-1"
                                key={index}
                              >
                                {consult.quantidade}
                              </Table.Cell>
                            )
                          )}
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </div>
                <FooterQuery hasHeader={true} />
              </Card>
            </>
          ) : (
            <QueryLoading />
          )}
        </div>
      )}
    </div>
  );
};

export default DefineLimitePositivoQuery;
